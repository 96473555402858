import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../../Common/Footer/Footer";
import {
  individualResetAction,
  languages,
  reCaptchaApi,
  registerApi,
} from "../../../../Redux/Action";
import { useFormik } from "formik";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Header } from "../../../Common/Header/Header";
import './IndividualRegister.css';

export const IndividualRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  //Language Response

  const { langSwitcRes } = useParams();

  const formik = useFormik({
    initialValues: {
      first: "",
      last: "",
      email: "",
      password: "",
      confirm: "",
      itemName: "general",
      acceptTerms: false,
    },

    validate: (values) => {
      const errors = {};

      //First name

      if (!values.first) {
        errors.first = t("firstname-validation");
      } else if (!/^[a-zA-Z ]*$/.test(values.first)) {
        errors.first = t("first_name_validation");
      }

      //last name

      if (!values.last) {
        errors.last = t("lastname-validation");
      } else if (!/^[a-zA-Z ]*$/.test(values.last)) {
        errors.last = t("last_name_validation");
      }

      //password

      if (!values.password) {
        errors.password = t("password-validation");
      } else if (values.password.length < 6) {
        errors.password = t("password_format_validation");
      }

      //confirm password

      if (!values.confirm) {
        errors.confirm = t("confirm-password-validation");
      } else if (values.confirm.length < 6) {
        errors.confirm = t("confirm_password_validation");
      } else if (values.password !== values.confirm) {
        errors.confirm = t("password_validation");
      }

      //email

      if (!values.email) {
        errors.email = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("email_format_validation");
      }

      //Terms and conditions

      if (!values.acceptTerms) {
        errors.acceptTerms = t("terms-validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(registerApi(values, { resetForm }, langSwitcRes, navigate));
    },
  });

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.individualData
  );

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setDisplayError(true);

      setTimeout(() => {
        dispatch(individualResetAction());
        setDisplayError(false);
      }, 8000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <div
        className="alert alert-success d-flex align-items-center p-3 mt-4"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
        ></i>
        <div className="register-error-success">{successMsg}</div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div
        className="alert alert-danger d-flex align-items-center p-3 mt-4"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
        ></i>
        <div className="register-error-message">{errorInfo}</div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(languages());
  }, [dispatch]);

  //Recaptcha

  const captchaKey = "6LfkTwYlAAAAAM3qvFGXAnHO5Iwdf7QAvNOWD_iX";

  function onChange(value) {
    dispatch(reCaptchaApi(value, captchaKey));
  }

  return (
    <>
      <Helmet>
        <title>KARACTERMANIA - Individual Registration</title>
      </Helmet>
      <Header pathData="individualRegister" />
      <div className="register__section">
        <div className="individual-register">
          <div className="card p-4">
            <div className="card-body">
              <div className="product-main-title text-center mb-2">
                {t("register_as_an_individual_person")}
              </div>
              <p className="text-center">
                {t("create_your_personal_profile_purchase")}
              </p>
            </div>

            {/* Form */}

            <Formik>
              <form onSubmit={formik.handleSubmit}>
                {/* Required Field */}
                <div class="required-field">{t("required-fields")}</div>

                {/* Success & error msgs */}

                {successMsg && displayError && <SuccessAlert />}
                {errorInfo && displayError && <FailureAlert />}

                {/*first */}

                <div className="mt-3">
                  <input
                    className="form-control"
                    title={t("first-name")}
                    type="text"
                    name="first"
                    placeholder={t("first-name")}
                    onChange={formik.handleChange}
                    value={formik.values.first}
                    autoComplete="off"
                    id="first"
                  />
                  <label htmlFor="first" class="visually-hidden">
                    First Name <span className="text-danger">*</span>
                  </label>
                </div>
                <p className="register-error">
                  {formik.errors.first &&
                    formik.touched.first &&
                    formik.errors.first}
                </p>

                {/* last */}

                <div className="mt-3">
                  <input
                    className="form-control"
                    title={t("last-name")}
                    type="text"
                    name="last"
                    placeholder={t("last-name")}
                    onChange={formik.handleChange}
                    value={formik.values.last}
                    autoComplete="off"
                    id="last"
                  />
                  <label htmlFor="last" class="visually-hidden">
                    Last Name <span className="text-danger">*</span>
                  </label>
                </div>
                <p className="register-error">
                  {formik.errors.last &&
                    formik.touched.last &&
                    formik.errors.last}
                </p>

                {/* Email */}

                <div className="mt-3">
                  <input
                    className="form-control"
                    title={t("email-address")}
                    type="text"
                    name="email"
                    placeholder={t("email-address")}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    autoComplete="off"
                    id="email"
                  />
                  <label htmlFor="email" class="visually-hidden">
                    Email Address <span className="text-danger">*</span>
                  </label>
                </div>
                <p className="register-error">
                  {formik.errors.email &&
                    formik.touched.email &&
                    formik.errors.email}
                </p>

                {/* Password */}

                <div className="mt-3">
                  <input
                    className="form-control"
                    title={t("password")}
                    type="password"
                    name="password"
                    placeholder={t("password")}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    autoComplete="off"
                    id="password"
                  />
                  <label htmlFor="password" class="visually-hidden">
                    Password <span className="text-danger">*</span>
                  </label>
                </div>
                <p className="register-error">
                  {formik.errors.password &&
                    formik.touched.password &&
                    formik.errors.password}
                </p>

                {/*Confirm Password */}

                <div className="mt-3">
                  <input
                    className="form-control"
                    type="password"
                    title={t("confirm-password")}
                    name="confirm"
                    placeholder={t("confirm-password")}
                    onChange={formik.handleChange}
                    value={formik.values.confirm}
                    autoComplete="off"
                    id="confirm"
                  />
                  <label htmlFor="confirm" class="visually-hidden">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                </div>
                <p className="register-error">
                  {formik.errors.confirm &&
                    formik.touched.confirm &&
                    formik.errors.confirm}
                </p>

                {/* Check */}
                <div class="form-check  mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="acceptTerms"
                    onChange={formik.handleChange}
                    value={formik.values.acceptTerms}
                    checked={formik.values.acceptTerms}
                    id="acceptTerms"
                  />
                  <label class="form-check-label" for="gridCheck">
                    <a
                      href={`/${langSwitcRes}/legal-notice`}
                      target="_blank"
                      className="terms-and-condition"
                    >
                      {t("terms-conditions")}
                      <span className="text-danger">*</span>
                    </a>
                  </label>
                </div>

                <p className="register-error">
                  {formik.errors.acceptTerms &&
                    formik.touched.acceptTerms &&
                    formik.errors.acceptTerms}
                </p>

                <ReCAPTCHA
                  sitekey="6LfkTwYlAAAAAIQkFzMJbDHPPwW5lfi904elwdPL"
                  onChange={onChange}
                />

                <center>
                  <button
                    type="submit"
                    class="blackbutton mb-4 mt-4"
                    title={t("create-account")}
                  >
                    {t("create-account")}
                  </button>
                </center>
              </form>
            </Formik>

            {/* Success & error msgs */}

            {/* {successMsg && displayError && <SuccessAlert />}
            {errorInfo && displayError && <FailureAlert />} */}
          </div>
        </div>
      </div>
      {res?.status === 200 ? "" : <BatmanPin />}

      <Footer />
    </>
  );
};
