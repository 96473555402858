import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import "../../../../index.css";

export const CategoryConditions = () => {
  const categoryPageRes = useSelector(
    (state) => state?.categoryData?.content_2
  );

  const categoryConRes = useSelector((state) => state?.categoryData?.content_1);

  //Language Switcher

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  return (
    <>
      <div className="ProDGSpecial-container">
        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="ProDGSpecial-content">
              {langSwitcRes &&
                categoryConRes
                  ?.filter((e) => e?.language === langSwitcRes)
                  ?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div
                          className="heading text-uppercase"
                          style={{ color: "#0da5e8" }}
                        >
                          {e?.tittle}
                        </div>
                        <div
                          className="body"
                          dangerouslySetInnerHTML={{ __html: e?.content }}
                        ></div>
                      </React.Fragment>
                    );
                  })}
              {/* <button className="blackbutton">I NEED INFORMATION</button> */}
              <p>&nbsp;</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="ProDGSpecial-content">
              {langSwitcRes &&
                categoryPageRes
                  ?.filter((e) => e?.language === langSwitcRes)
                  ?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div
                          className="heading text-uppercase"
                          style={{ color: "#0da5e8" }}
                        >
                          {e?.tittle}
                        </div>
                        <div
                          className="body"
                          dangerouslySetInnerHTML={{ __html: e?.content }}
                        ></div>
                      </React.Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
