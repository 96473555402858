import React, { useEffect, useState } from "react";
import { Header } from "../../../Common/Header/Header";
import { Footer } from "../../../Common/Footer/Footer";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswords } from "../../../../Redux/Action";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import './ResetPassword.css';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { langSwitcRes } = useParams();
  const { t } = useTranslation();
  //Reset password token

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const forgotToken = params.get("token_name");

  //Reset Password Response

  const resetResponse = useSelector((state) => state?.resetPassData);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },

    validate: (values) => {
      const errors = {};

      //password

      if (!values.password) {
        errors.password = t("password-validation");
      } else if (values.password.length < 6) {
        errors.password = t("password_format_validation");
      }

      //confirm password

      if (!values.confirm) {
        errors.confirm = t("confirm-password-validation");
      } else if (values.confirm.length < 6) {
        errors.confirm = t("confirm_password_validation");
      } else if (values.password !== values.confirm) {
        errors.confirm = t("password_validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(
        resetPasswords(
          values,
          { resetForm },
          forgotToken,
          navigate,
          langSwitcRes
        )
      );
    },
  });

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    if (forgotToken == "") {
      setIsAlertVisible(true);

      setTimeout(() => {
        navigate(`/${langSwitcRes}`);
        setIsAlertVisible(false);
      }, 8000);
    }
  }, [forgotToken]);

  return (
    <>
      <Header pathData="resetpassword" />
      <div className="resetpassword__section">
        <div className="resetpassword__block">
          {forgotToken == "" ? (
            <>
              <div className="invalidToken">
                {forgotToken == "" && isAlertVisible && (
                  <>
                    <div
                      className="alert alert-danger d-flex align-items-center p-0 mt-3"
                      role="alert"
                    >
                      <i
                        className="fa fa-exclamation-circle me-2 ms-3 text-danger"
                        aria-hidden="true"
                        style={{ fontSize: "17px" }}
                      ></i>
                      <div className="mt-3">
                        <p style={{ fontSize: "13px" }}>
                          Sorry! Invalid password reset token
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
            {/* resetCenter */}
              <div className="card">
                <h1>{t("ResetPassword")}</h1>
                <p className="para">{t("Please_enter_newpassword")}:</p>
                {resetResponse?.status === 200 ? (
                  <div
                    className="alert alert-success d-flex align-items-center p-0 mt-3"
                    role="alert"
                  >
                    <i
                      className="fa fa-check text-success fw-bold me-2 ms-3"
                      aria-hidden="true"
                      style={{ fontSize: "17px" }}
                    ></i>
                    <div className="mt-3">
                      <p style={{ fontSize: "13px" }}>
                        {resetResponse?.data?.message}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {resetResponse?.status === 201 ? (
                  <div
                    className="alert alert-danger d-flex align-items-center p-0 mt-3"
                    role="alert"
                  >
                    <i
                      className="fa fa-exclamation-circle me-2 ms-3 text-danger"
                      aria-hidden="true"
                      style={{ fontSize: "17px" }}
                    ></i>
                    <div className="mt-3">
                      <p style={{ fontSize: "13px" }}>
                        {resetResponse?.data?.message}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Formik>
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      {/* Password */}

                      <div className="form-group">
                        <label htmlFor="password" className="visually-hidden">
                          Password <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          placeholder={t("new-password")}
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          id="password"
                        />
                      </div>
                      <p className="register-error">
                        {formik.errors.password &&
                          formik.touched.password &&
                          formik.errors.password}
                      </p>

                      {/*Confirm Password */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="password"
                          name="confirm"
                          placeholder={t("confirm-new-password")}
                          onChange={formik.handleChange}
                          value={formik.values.confirm}
                          id="confirm"
                        />
                        <label htmlFor="confirm" className="visually-hidden">
                          Confirm new password{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.confirm &&
                          formik.touched.confirm &&
                          formik.errors.confirm}
                      </p>
                      <center>
                        <button className="blackbutton mt-4">{t("Update")}</button>
                      </center>
                    </div>
                  </form>
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <BatmanPin />
    </>

  );
};
