import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { myInVoiceAction } from "../../../Redux/Action";

export const MyInvoices = () => {
  const [invoiceSearch, setInvoiceSearch] = useState("");

  const dispatch = useDispatch();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const loginResId = res?.data?.userid;

  //Language Switcher
  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(myInVoiceAction(loginResId));
  }, [dispatch, loginResId]);

  //myinvoice Response

  const myInVoiceRes = useSelector((state) => state?.myInVoiceData);

  const invoiceFilter = myInVoiceRes?.filter((value) =>
    value?.invoice_number?.toString()?.includes(invoiceSearch)
  );

  //AccounntInfo Response

  const accountInfoRes = useSelector((state) => state?.accountInfoData);

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 card p-2 mb-3">
        {langSwitcRes &&
          accountInfoRes?.my_invoice_content
            ?.filter((e) => e?.language === langSwitcRes)
            ?.map((e, i) => {
              return <React.Fragment key={i}>{e?.tittle}</React.Fragment>;
            })}
      </div>
      <form>
        <div class="search-cont">
          <div class="search">
            <input
              type="text"
              class="search-input"
              placeholder={t("search_invoice")}
              onChange={(e) => setInvoiceSearch(e.target.value)}
              value={invoiceSearch}
              name="searchInvoice"
              id="searchInvoice"
            />
            <Link to="" class="search-icon">
              <i class="fa fa-search"></i>
            </Link>
          </div>
        </div>
      </form>
      <div className="table-responsive table-invoice">
        <table class="table table-striped table-bordered " id="dest">
          <thead>
            <tr>
              <th className="align-top">{t("invoice")}</th>
              <th className="align-top">{t("invoice_date")}</th>
              <th className="align-top">{t("download")}</th>
              <th className="align-top">{t("downloaded_date")}</th>
              <th className="align-top">{t("status")}</th>
              <th className="align-top">{t("amount")}</th>
            </tr>
          </thead>

          {invoiceFilter.length > 0 ? (
            myInVoiceRes
              ?.filter((value) => {
                return invoiceSearch === ""
                  ? value
                  : value?.invoice_number?.toString()?.includes(invoiceSearch);
              })
              ?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <tbody>
                      <tr>
                        <td>{e?.invoice_number}</td>
                        <td>{e?.invoice_date}</td>
                        <td>
                          {" "}
                          <a href={e?.filename} download={e?.filename}>
                            {t("download")} PDF
                          </a>
                        </td>
                        <td>{e?.downloaded_date}</td>
                        <td>Inprogress </td>
                        <td>{e?.total_invoice}</td>
                      </tr>
                    </tbody>
                  </React.Fragment>
                );
              })
          ) : (
            <tbody>
              <tr>
                <td valign="top" colspan="6" className="text-center">
                  {t("no_data_available")}                
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};
