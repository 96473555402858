import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BasicBreadcrumbs() {
  const { page_title, title } = useParams();

  const { langSwitcRes } = useParams();

  const navigate = useNavigate();

  const catagoryBlockRes = useSelector(
    (state) => state?.productListingData?.Category_block
  );

  const parentNameRes = useSelector(
    (state) => state?.productListingData?.ParentName
  );

  const productTypeData = useSelector(
    (state) => state?.productListingData?.product_type_list
  );

  const brandTypeData = useSelector(
    (state) => state?.productListingData?.brand_type_list
  );

  return (
    <div className="container">
      <div className="breadcrumb-cont">
        <div role="presentation" onClick={handleClick}>
          {catagoryBlockRes &&
          brandTypeData?.length === 0 &&
          productTypeData?.length === 0 ? (
            <>
              {/* megamenu */}

              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="/en"
                  onClick={() => navigate("/")}
                >
                  KARACTERMANIA
                </Link>
                <Typography color="text.primary">
                  {catagoryBlockRes?.page_title}
                </Typography>
              </Breadcrumbs>
            </>
          ) : brandTypeData?.length > 0 &&
            productTypeData?.length === 0 &&
            parentNameRes == null ? (
            <>
              {/* homepage product type */}

              {brandTypeData?.map((e, i) => {
                return (
                  <>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        color="inherit"
                        href="/en"
                        onClick={() => navigate("/")}
                      >
                        KARACTERMANIA
                      </Link>
                      <Typography color="text.primary">
                        {e?.productname?.toUpperCase()}
                      </Typography>
                    </Breadcrumbs>
                  </>
                );
              })}
            </>
          ) : productTypeData?.length > 0 &&
            brandTypeData?.length === 0 &&
            parentNameRes == null ? (
            <>
              {/* homepage brand type */}

              {productTypeData?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        color="inherit"
                        href="/en"
                        onClick={() => navigate("/")}
                      >
                        KARACTERMANIA
                      </Link>
                      <Typography color="text.primary">
                        {e?.brandname?.toUpperCase()}
                      </Typography>
                    </Breadcrumbs>
                  </React.Fragment>
                );
              })}
            </>
          ) : brandTypeData?.length > 0 && productTypeData?.length === 0 ? (
            brandTypeData?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <>
                    {/* megamenu brand type */}

                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        underline="hover"
                        color="inherit"
                        href="/en"
                        onClick={() => navigate("/")}
                      >
                        KARACTERMANIA
                      </Link>
                      <Link
                        underline="hover"
                        color="inherit"
                        href={`/${langSwitcRes}/${parentNameRes?.slug_name}`}
                        to={`/${langSwitcRes}/${parentNameRes?.slug_name}`}
                        onClick={() =>
                          navigate(
                            `/${langSwitcRes}/${parentNameRes?.slug_name}`
                          )
                        }
                      >
                        {parentNameRes?.name}
                      </Link>
                      <Typography color="text.primary">
                        {e?.productname?.toUpperCase()}
                      </Typography>
                    </Breadcrumbs>
                  </>
                </React.Fragment>
              );
            })
          ) : productTypeData?.length > 0 && brandTypeData?.length === 0 ? (
            productTypeData?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  {/* megamu product type */}

                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="/en"
                      onClick={() => navigate("/")}
                    >
                      KARACTERMANIA
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      href={`/${langSwitcRes}/${parentNameRes?.slug_name}`}
                      to={`/${langSwitcRes}/${parentNameRes?.slug_name}`}
                      onClick={() =>
                        navigate(`/${langSwitcRes}/${parentNameRes?.slug_name}`)
                      }
                    >
                      {parentNameRes?.name}
                    </Link>
                    <Typography color="text.primary">
                      {e?.brandname?.toUpperCase()}
                    </Typography>
                  </Breadcrumbs>
                </React.Fragment>
              );
            })
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
