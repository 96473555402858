import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginResetAction } from "../../../../Redux/Action";

export const LoginResponse = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const [loginSuccess, setLoginSuccess] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginApprove, setLoginApprove] = useState("");

  useEffect(() => {
    if (res?.status === 200) {
      setLoginSuccess(res?.data?.message);
    } else if (res?.status === 201) {
      setLoginError(res?.data?.message);
    } else {
      setLoginApprove(res?.data?.message);
    }
  }, [res]);

  const [loginDisplayError, setLoginDisplayEror] = useState(false);

  useEffect(() => {
    if (loginApprove || loginError || loginSuccess) {
      setLoginDisplayEror(true);

      setTimeout(() => {
        (loginError || loginApprove) && dispatch(loginResetAction());
        setLoginApprove("");
        setLoginSuccess("");
        setLoginError("");
        setLoginDisplayEror(false);
      }, 3000);
    }
  }, [loginApprove, loginError, loginSuccess]);

  const LoginSuccessAlert = () => {
    return (
      <div
        className="alert alert-success d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>{t("login_successfully")}</p>
        </div>
      </div>
    );
  };

  const LoginFailureAlert = () => {
    return (
      <div
        className="alert alert-danger d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>
            {t("incorrect_username_or_password")}
          </p>
        </div>
      </div>
    );
  };

  const LoginApproveAlert = () => {
    return (
      <div
        className="alert alert-danger d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>
            {t("this_account_is_not_approved")}
          </p>
        </div>
      </div>
    );
  };
  return (
    <>
      {/* success or error msg */}

      {loginSuccess && loginDisplayError && <LoginSuccessAlert />}
      {loginError && loginDisplayError && <LoginFailureAlert />}
      {loginApprove && loginDisplayError && <LoginApproveAlert />}
    </>
  );
};
