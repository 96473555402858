import React from "react";
import { useSelector } from "react-redux";
import "../../../../index.css";

export const RegisterAndDiscover = () => {
  //Homepage content Response

  const homepageRes = useSelector(
    (state) => state?.HomePageData?.homepage_advantages_block
  );

  const homepageRes1 = useSelector(
    (state) => state?.HomePageData?.homepage_b2b_identifier_block
  );

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  return (
    <>
      <div className="randd-cont">
        <div className="container randd-width">
          <div className="row">
            {langSwitcRes &&
              homepageRes
                ?.filter((e) => e?.language === langSwitcRes)
                ?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e?.content,
                          }}
                        ></p>
                      </div>
                    </React.Fragment>
                  );
                })}

            {langSwitcRes &&
              homepageRes1
                ?.filter((e) => e?.language === langSwitcRes)
                ?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="dos-cont mt-4">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.content,
                            }}
                          ></p>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};
