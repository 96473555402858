import React from 'react';
import './Placeholder.css';

export default function Placeholder() {
  return (
    <div className="placeholder">
      <img
          src={"/images/logo192.png"}
          alt="Placeholder"
        />
    </div>
  );
}
