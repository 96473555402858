import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { launchAction } from "../../../Redux/Action";

export const Launch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(launchAction());
  }, [dispatch]);

  const launchRes = useSelector((state) => state?.launchData);

  return (
    <>
      {launchRes?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="launch-heading">{e?.title}</div>
                <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
