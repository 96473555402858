import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchGrid from "./SearchGrid";
import SearchList from "./SearchList";
import PuffLoader from "react-spinners/PuffLoader";
import { searchAction } from "../../../../Redux/Action";
import ReactPaginate from "react-paginate";
import { useElementOnScreen } from "../../../Common/Infinite scroll/Scroll";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const SearchPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  //Grid & list views

  const [showGrid, setShowGrid] = useState(true);
  const [showList, setShowList] = useState(false);

  //loader

  const [pageLoading, setPageLoading] = useState(false);

  //search input value

  const { srhInputValue, langSwitcRes } = useParams();

  //login response

  const res = useSelector((state) => state?.loginData);

  useEffect(() => {
    dispatch(searchAction(srhInputValue, langSwitcRes, res, navigate));
  }, [srhInputValue, langSwitcRes, res]);

  useEffect(() => {
    setPageLoading(true);
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  }, []);

  //Language Switcher

  const showGridHandler = () => {
    setShowGrid(true);
    setShowList(false);
  };

  const showListHandler = () => {
    setShowGrid(false);
    setShowList(true);
  };

  const totalSearchCount = useSelector(
    (state) => state?.searchData?.Total_items
  );

  const data = useSelector((state) => state?.searchData?.product_list);

  //Pagination

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {pageLoading ? (
        <>
          <center className="page-body-container">
            <h4>Please Wait...</h4>

            <PuffLoader
              color={"#0da5e8 "}
              pageLoading={pageLoading}
              size={70}
            />
          </center>
        </>
      ) : (
        <>
          <div className="page-body-container search__grid__container">
            <div className="container">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="row search__grid__section">
                  <div className="row">
                    {data?.Total_items == 0 ? (
                      <>
                        {" "}
                        <center>
                          <label className="col-form-label">
                            Total Items:{" "}
                            <span id="total-count">{totalSearchCount}</span>
                          </label>
                        </center>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="product__page__count">
                          <div className="total-product">
                            <div className="col-auto">
                              <label className="col-form-label">
                                Total Items:{" "}
                                <span id="total-count">{totalSearchCount}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="grid__list__view">
                      <div className="row g-3 align-items-center float-end">
                        <div className="col-auto">
                          <div className="view-mode float-end">
                            {data?.length > 0 ? (
                              <>
                                {" "}
                                {showGrid ? (
                                  <>
                                    <Link to="#">
                                      <span
                                        className="grid active"
                                        title="Grid"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-grid"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                        </svg>
                                      </span>
                                    </Link>
                                    <Link to="#" onClick={showListHandler}>
                                      <span className="list" title="List">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-list-ul"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                          />
                                        </svg>
                                      </span>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="#" onClick={showGridHandler}>
                                      <span className="grid" title="Grid">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-grid"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                        </svg>
                                      </span>
                                    </Link>
                                    <Link to="#">
                                      <span
                                        className="list active"
                                        title="List"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-list-ul"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                          />
                                        </svg>
                                      </span>
                                    </Link>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row search__grid__section">
                  <div className="pagination-cont">
                    {/* Pagination design */}

                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="page-num"
                      previousLinkClassName="page-num"
                      nextLinkClassName="page-num"
                      disabledClassName="pagination__link--disabled"
                      activeLinkClassName="active"
                    />
                  </div>

                  {/* Grid and List views */}

                  {showGrid ? (
                    <SearchGrid
                      srhInputValue={srhInputValue}
                      currentItems={currentItems}
                      data={data}
                      setCurrentItems={setCurrentItems}
                    />
                  ) : (
                    <SearchList
                      srhInputValue={srhInputValue}
                      currentItems={currentItems}
                      data={data}
                      setCurrentItems={setCurrentItems}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
