import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../../index.css";
import { HiShoppingCart } from "react-icons/hi";
import BasicBreadcrumbs from "./BasicBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartApi,
  cartBtn,
  itemDelete,
  productDetailsAction,
  qtyDecrement,
  qtyDecrementRetailer,
  qtyIncrement,
  qtyIncrementRetailer,
} from "../../../../../Redux/Action";
import { useParams } from "react-router";
import { baseUrlColor, baseUrlProduct } from "../../../../../config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const ProductDetailedInfo = () => {
  const [imageData, setImageData] = useState([]);

  const dispatch = useDispatch();

  //Language Switcher

  // const langSwitcRes = useSelector((state) => state.langSwitchData);

  const { langSwitcRes } = useParams();

  const { t } = useTranslation();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  const loginRes = res;

  //Slug Name

  const { slug } = useParams();

  //slugSku

  const slugSku = slug?.split("-")?.pop();

  useEffect(() => {
    dispatch(productDetailsAction(loginRes, slug, slugSku, langSwitcRes));
  }, [loginRes, langSwitcRes, slug, dispatch]);

  //Product details Response

  const productDetRes = useSelector((state) => state?.productDetailsData);

  //Login Response

  const resGeneral = res?.data?.group;

  const userID = res?.data?.userid;

  const cartQty = useSelector((state) => state?.cartData?.cart_data);

  const cartId = cartQty?.map((e) => e?.cart_id);

  const quantityRes = useSelector((state) => state?.quantity);

  const qtyRetailerRes = useSelector((state) => state?.quantityRetailer);

  //Input value

  const inputValue = (sku) => {
    return (
      <div className="cartwrap-center float-end">
        <input
          type="text"
          id="qty_input"
          className="form-control bg-light form-control-sm item-count"
          value={quantityRes[sku] || 1}
          min="1"
          autoComplete="off"
          disabled
        />
      </div>
    );
  };

  //Retailer input value

  const inputValueB2B = (sku, min_qty_retailer) => {
    return (
      <div className="cartwrap-center float-end">
        <input
          type="text"
          id="qty_input"
          className="form-control bg-light form-control-sm item-count"
          value={qtyRetailerRes[sku] || min_qty_retailer}
          min={min_qty_retailer}
          autoComplete="off"
          disabled
        />
      </div>
    );
  };

  //Quantity Decrement

  const decFunction = (sku) => {
    return (
      <button
        className="btn btn-black-two btn-lg"
        id="minus-btn"
        onClick={(event) => {
          event.preventDefault();
          dispatch(qtyDecrement(sku));
        }}
      >
        <i className="fa fa-minus"></i>
      </button>
    );
  };

  //Quantity Decrement

  const decFunctionB2B = (sku, minQty) => {
    return (
      <button
        className="btn btn-black-two btn-lg"
        id="minus-btn"
        onClick={(event) => {
          event.preventDefault();
          dispatch(qtyDecrementRetailer(sku, minQty));
        }}
      >
        <i className="fa fa-minus"></i>
      </button>
    );
  };

  const beforeLoginPrice = (price_before, b2c_price) => {
    if (price_before === b2c_price) {
      return (
        <>
          <div className="pd-price-cont-amount align-items-center">
            <span className="reducedprice-three">
              {/* {e?.price_before}€ */}
            </span>
            {langSwitcRes === "es" ? (
              <>
                {Number(b2c_price).toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : langSwitcRes === "fr" ? (
              <>
                {Number(b2c_price).toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : (
              <> {Number(b2c_price).toFixed(2)}</>
            )}
            <span> €</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="pd-price-cont-amount align-items-center">
            {langSwitcRes === "es" ? (
              <>
                {Number(b2c_price).toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : langSwitcRes === "fr" ? (
              <>
                {Number(b2c_price).toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : (
              <> {Number(b2c_price).toFixed(2)}</>
            )}
            <span> €</span>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    const splitImages = productDetRes?.product_details
      ?.map((e) => {
        return e?.image;
      })
      ?.toString()
      ?.split(",");
    setImageData(splitImages);
  }, [productDetRes]);

  //After login price

  const afterLoginPrice = (price_before, price) => {
    if (price_before === price) {
      return (
        <>
          <div className="row align-items-center bg">
            <p className="customertype">Distributor price</p>
            <div className="reducedprice-two noprice">
              <span className="currency"></span>
            </div>
            <div className="price-two">
              {langSwitcRes === "es" ? (
                <>
                  {Number(price).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : langSwitcRes === "fr" ? (
                <>
                  {Number(price).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : (
                <> {Number(price).toFixed(2)}</>
              )}

              <span className="currency"> €</span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row align-items-center bg">
            <p className="customertype">Distributor price</p>
            <div className="reducedprice-two">
              {langSwitcRes === "es" ? (
                <>
                  {Number(price_before).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : langSwitcRes === "fr" ? (
                <>
                  {Number(price_before).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : (
                <> {Number(price_before).toFixed(2)}</>
              )}
              <span className="currency">€</span>
            </div>
            <div className="price-two">
              {langSwitcRes === "es" ? (
                <>
                  {Number(price).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : langSwitcRes === "fr" ? (
                <>
                  {Number(price).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : (
                <> {Number(price).toFixed(2)}</>
              )}
              <span className="currency"> €</span>
            </div>
          </div>
        </>
      );
    }
  };

  const [isAlertVisible, setIsAlertVisible] = useState(0);

  useEffect(() => {
    if (isAlertVisible) {
      setTimeout(() => {
        setIsAlertVisible(0);
      }, 3000);
    }
  }, [isAlertVisible]);

  const handleButtonClick = (pData) => {
    dispatch(cartBtn(pData));
    setIsAlertVisible(pData?.sku);
  };

  return (
    <>
      <div className="product-detail-cont">
        <BasicBreadcrumbs />
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <section id="default" className="padding-top0">
              <div className="row">
                <div className="large-12 column">
                  {productDetRes?.product_details?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Helmet>
                          <title>{e?.meta_title}</title>
                          <meta
                            name="description"
                            content={e?.meta_description}
                          />
                        </Helmet>
                        <div className="xzoom-container">
                          {imageData?.slice(0, 1)?.map((e, i) => {
                            return (
                              <React.Fragment key={i}>
                                <img
                                  alt="PDP"
                                  className="xzoom c-width"
                                  id="xzoom-default"
                                  src={`${baseUrlProduct}/${e}`}
                                  xoriginal={`${baseUrlProduct}/${e}`}
                                />
                              </React.Fragment>
                            );
                          })}

                          {productDetRes?.product_details?.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                <div className="product-detail-number">
                                  {item?.sku}
                                </div>
                              </React.Fragment>
                            );
                          })}

                          {productDetRes?.product_details?.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                {item?.sku === isAlertVisible && (
                                  <div class="success-msg">
                                    <div class="success-msg-home-pdt">
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Added
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}

                          <div className="xzoom-thumbs">
                            {imageData?.map((e, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <a href={`${baseUrlProduct}/${e}`}>
                                    <img
                                      className="xzoom-gallery"
                                      alt="PDP"
                                      src={`${baseUrlProduct}/${e}`}
                                      title="The description goes here 1"
                                      xpreview="/images/pdetail/01.jpg"
                                    />
                                  </a>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
            {productDetRes?.product_details?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="heading-cont">
                    <div className="heading">{e?.brand}</div>
                    <div className="description">{e?.name}</div>
                  </div>
                </React.Fragment>
              );
            })}

            <div className="product-tab-cont">
              <nav className="slantedtabs">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-features-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-features"
                    type="button"
                    role="tab"
                    aria-controls="nav-features"
                    aria-selected="true"
                  >
                    <span className="slanted-text">{t("features")}</span>
                  </button>

                  <button
                    className="nav-link"
                    id="nav-shipping-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-shipping"
                    type="button"
                    role="tab"
                    aria-controls="nav-shipping"
                    aria-selected="false"
                  >
                    <span className="slanted-text">{t("shipping")}</span>
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-features"
                  role="tabpanel"
                  aria-labelledby="nav-features-tab"
                  tabindex="0"
                >
                  {productDetRes?.product_details?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="details-cont">
                          <div className="heading">{t("data_sheet")}</div>
                          <div className="description">
                            <ul className="data-points">
                              {e?.pre_sale == 0 &&
                              resGeneral == "retailer" &&
                              e?.min_qty_retailer > e?.b2b_qty ? (
                                <>
                                  <li>
                                    <div className="item-error m-2">
                                      * PRE-ORDER
                                    </div>
                                  </li>
                                </>
                              ) : e?.pre_sale == 0 &&
                                resGeneral == "wholesaler" &&
                                e?.min_qty_wholesaler > e?.b2b_qty ? (
                                <>
                                  <li>
                                    <div className="item-error m-2">
                                      * PRE-ORDER
                                    </div>
                                  </li>
                                </>
                              ) : e?.pre_sale == 1 ? (
                                <>
                                  <li>
                                    <div className="item-error m-2">
                                      * PRE-ORDER
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li>
                                    <div className="item-success m-2">
                                      * IMMEDIATE SERVICE
                                    </div>
                                  </li>
                                </>
                              )}

                              <li>EAN: {e?.ean}</li>
                              <li>Product: {e?.product_type}</li>
                              <li>Color: {e?.color}</li>
                              <li>Format: {e?.format}</li>
                              <li>Brand: {e?.brand}</li>
                              <li>Measures: {e?.measures}</li>
                              <li>Weight: {e?.weight}Kg</li>
                            </ul>
                          </div>
                          <div className="heading">{t("description")}</div>
                          <div className="description">{e?.description}</div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-shipping"
                  role="tabpanel"
                  aria-labelledby="nav-shipping-tab"
                  tabindex="0"
                >
                  <div className="details-cont">
                    <div className="heading">{t("shipping_details")}</div>

                    {productDetRes?.shipping_info?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div
                            className="description"
                            dangerouslySetInnerHTML={{ __html: e?.content }}
                          ></div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div className="pd-price-cont">
              <div className="pd-price-cont-price align-items-center">
                PRICE
              </div>

              {productDetRes?.product_details?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>
                        {beforeLoginPrice(e?.price_before, e?.retailer_price)}
                      </>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        {beforeLoginPrice(e?.price_before, e?.wholesaler_price)}
                      </>
                    ) : (
                      <> {beforeLoginPrice(e?.price_before, e?.b2c_price)}</>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            <div className="pd-vat">* {t("vat_not_included")}</div>

            {productDetRes?.product_details?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="pd-divider-one"></div>
                  <div className="product-detail-wrap-two">
                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>{afterLoginPrice(e?.price_before, e?.retailer_price)}</>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        {afterLoginPrice(e?.price_before, e?.wholesaler_price)}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </React.Fragment>
              );
            })}

            {productDetRes?.product_details?.map((items, indexes) => {
              return (
                <React.Fragment key={indexes}>
                  {res?.status === 200 && resGeneral === "retailer" ? (
                    <>
                      <div className="cartwrap-two">
                        <div
                          className="cartwrap-left-five"
                          onClick={() => handleButtonClick(items)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  items,
                                  res,
                                  qtyRetailerRes[items?.sku] ||
                                    items?.min_qty_retailer,
                                  userID,
                                  cartId,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  items?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />

                            {items?.pre_sale == 0 &&
                            resGeneral == "retailer" &&
                            items?.min_qty_retailer > items?.b2b_qty ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : items?.pre_sale == 0 &&
                              resGeneral == "wholesaler" &&
                              items?.min_qty_wholesaler > items?.b2b_qty ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : items?.pre_sale == 1 ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : (
                              <>
                                <span className="pd-sk-atk">Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>

                        {/* quantityDecrement */}

                        <div className="cartwrap-right">
                          {decFunctionB2B(items?.sku, items?.min_qty_retailer)}
                        </div>

                        {/* Input value */}

                        {inputValueB2B(items?.sku, items?.min_qty_retailer)}

                        {/* quantityIncrement */}

                        <button
                          className="btn btn-black-two btn-lg  float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                items?.sku,
                                items?.min_qty_retailer,
                                items?.b2b_qty,
                                items?.pre_order_qty,
                                items?.pre_sale
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </>
                  ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                    <>
                      <div className="cartwrap-two">
                        <div
                          className="cartwrap-left-five"
                          onClick={() => handleButtonClick(items)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  items,
                                  res,
                                  qtyRetailerRes[items?.sku] ||
                                    items?.min_qty_wholesaler,
                                  userID,
                                  cartId,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  items?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            {items?.pre_sale == 0 &&
                            resGeneral == "retailer" &&
                            items?.min_qty_retailer > items?.b2b_qty ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : items?.pre_sale == 0 &&
                              resGeneral == "wholesaler" &&
                              items?.min_qty_wholesaler > items?.b2b_qty ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : items?.pre_sale == 1 ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : (
                              <>
                                <span className="pd-sk-atk">Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>

                        {/* quantityDecrement */}

                        <div className="cartwrap-right">
                          {decFunctionB2B(
                            items?.sku,
                            items?.min_qty_wholesaler
                          )}
                        </div>

                        {/* Input value */}

                        {inputValueB2B(items?.sku, items?.min_qty_wholesaler)}

                        {/* quantityIncrement */}

                        <button
                          className="btn btn-black-two btn-lg  float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                items?.sku,
                                items?.min_qty_wholesaler,
                                items?.b2b_qty,
                                items?.pre_order_qty,
                                items?.pre_sale
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="cartwrap-two">
                        <div
                          className="cartwrap-left-five"
                          onClick={() => handleButtonClick(items)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  items,
                                  res,
                                  quantityRes[items?.sku] || 1,
                                  userID,
                                  cartId,
                                  items?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            {items?.pre_sale == 0 &&
                            resGeneral == "retailer" &&
                            items?.min_qty_retailer > items?.b2b_qty ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : items?.pre_sale == 0 &&
                              resGeneral == "wholesaler" &&
                              items?.min_qty_wholesaler > items?.b2b_qty ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : items?.pre_sale == 1 ? (
                              <>
                                <span className="pd-sk-atk">Pre-sale</span>
                              </>
                            ) : (
                              <>
                                <span className="pd-sk-atk">Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>

                        {/* quantityDecrement */}

                        <div className="cartwrap-right">
                          {decFunction(items?.sku)}
                        </div>

                        {/* Input value */}

                        {inputValue(items?.sku)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(qtyIncrement(items?.sku, items?.b2c_qty));
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </>
                  )}

                  {/* Add to cart */}
                </React.Fragment>
              );
            })}

            <div className="pd-divider-two"></div>
            {res?.status === 200 &&
            (res?.data?.group == "retailer" ||
              res?.data?.group == "wholesaler") ? (
              ""
            ) : (
              <div className="pd-usertype-cont">
                <div
                  className="row align-items-center pd-usertype"
                  title="Wholesaler"
                >
                  <div className="col-sm-9 mb-0">
                    <Link to={`/${langSwitcRes}/wholeregister`}>
                      {t("i_am_wholesaler")}
                    </Link>
                  </div>
                  <figure className="col-sm-3 col-md-3  mb-0">
                    <img
                      className="float-end"
                      src="/images/detailed/house.png"
                      alt="I AM WHOLESALER"
                    />
                  </figure>
                </div>

                <article
                  className="row align-items-center pd-usertype"
                  title="Retailer"
                >
                  <div className="col-sm-9 mb-0">
                    <Link to={`/${langSwitcRes}/shopregister`}>
                      {t("i_have_store")}{" "}
                    </Link>
                  </div>
                  <figure className="col-sm-3 col--3 mb-0">
                    <img
                      className="float-end"
                      src="/images/detailed/van.png"
                      alt="I HAVE A STORE"
                    />
                  </figure>
                </article>
              </div>
            )}

            <div className="pd-brand">{t("brand")}</div>
            <div>
              {productDetRes?.product_details?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <img
                      className="img-fluid"
                      src={`${baseUrlColor}/${e?.brand_image}`}
                      alt={e?.brand}
                    />
                    {/* {e?.title} */}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
