import React, { useState } from "react";
import "../../../index.css";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  cartApiAction,
  homePageCategory,
  languages,
  logos,
  menuAction,
  searchAction,
  signOut,
} from "../../../Redux/Action";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Formik } from "formik";
import { baseUrls } from "../../../config";
import { Brands } from "./Brands";
import { MenuLinks } from "./MenuLinks";
import { MiniCart } from "./MiniCart";

export const Header = (props) => {
  const [showGlobe, setshowGlobe] = useState(true);
  const [selectedLang, setSelectedLang] = useState("");
  const [menuHover, setMenuHover] = useState("");

  const { pathData } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const homepageRes = useSelector(
    (state) => state?.HomePageData?.mega_menu_block
  );

  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const logosData = useSelector((state) => state.logoData);

  //Language Response

  const langResponse = useSelector((state) => state?.langData);

  const { langSwitcRes } = useParams();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  const loginToken = res?.data?.token;

  //Logo Response

  useEffect(() => {
    dispatch(logos());
    dispatch(languages());
    dispatch(homePageCategory());
  }, [dispatch, langSwitcRes, res]);

  //Logout

  const logOut = (e) => {
    dispatch(signOut(loginToken, navigate, langSwitcRes));
  };

  const handleLinks = (linkName) => {
    navigate(`/${langSwitcRes}/${linkName}`);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  //Language

  const handleLanguage = (flag, code) => {
    window.sessionStorage.setItem("langSession", flag);
    window.sessionStorage.setItem("codeSession", code);
    setSelectedLang(flag, code);
    setshowGlobe(false);
    i18n.changeLanguage(code);
    window.localStorage.setItem("lng", code);
  };

  useEffect(() => {
    const langSession = window.sessionStorage.getItem("langSession");
    const codeSession = window.sessionStorage.getItem("codeSession");
    if (langSession) {
      setSelectedLang(langSession, codeSession);
      setshowGlobe(false);
    }
  }, []);

  //Search

  const formikk = useFormik({
    initialValues: {
      option: "",
    },

    validate: (values) => {
      const errors = {};

      if (!values.option) {
        errors.option = "Please fill in this field.";
      }

      return errors;
    },

    onSubmit: (values) => {
      dispatch(searchAction(values?.option, langSwitcRes, res, navigate));
    },
  });

  //Mega menu res

  const menuHoverRes = useSelector((state) => state?.menuData);

  return (
    <>
      <div className="fixed-top">
        <div className="karactermania-wrap">
          <div className="container-two">
            <div className="row justify-content-between">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <p className="mb-0 desc">
                  KARACTERMANIA - Manufacturer, designer, wholesale distributor
                  of licensed products.
                </p>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-end">
                <div className="right-navigation-links">
                  <div className="mb-0 d-flex ">
                    <ul class="icon-navigation">
                      <li>
                        <Link
                          to={`/${langSwitcRes}/aboutus`}
                          className="aboutus"
                          title={t("about-us")}
                        >
                          {t("about-us")}
                        </Link>
                      </li>

                      {res?.status === 200 ? (
                        <li>
                          <Link
                            to=""
                            className="d-flex align-items-center justify-content-center"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            role="button"
                            title="User"
                          >
                            <FaUser title="User" />
                          </Link>
                          <ul class="icon-dropdown">
                            <li>
                              <div className="user-sign-two">
                                <ul className="myaccount">
                                  <li>
                                    <Link to={`/${langSwitcRes}/myaccount`}>
                                      {t("my_account")}
                                    </Link>
                                  </li>
                                  <li onClick={logOut}>
                                    <button
                                      className="btn text-light p-0"
                                      onClick={logOut}
                                      title={t("logout")}
                                    >
                                      {t("logout")}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <li>
                          <Link
                            to={`/${langSwitcRes}/login`}
                            className="d-flex align-items-center justify-content-center"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            title="User"
                            onClick={() => navigate(`/${langSwitcRes}/login`)}
                          >
                            <FaUser />
                          </Link>
                        </li>
                      )}

                      {/* Minicart */}

                      <MiniCart />

                      <li className="flagContainer">
                        {/* Language */}

                        <Link
                          to=""
                          class="d-flex align-items-center justify-content-center"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          role="button"
                        >
                          {showGlobe && (
                            <span>
                              {langResponse
                                ?.filter((e) => e?.code === langSwitcRes)
                                ?.map((langFlag, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <img
                                        src={`${baseUrls}/${langFlag?.flag}`}
                                        style={{ width: "17px" }}
                                        className="me-2"
                                        alt="lang"
                                      />
                                    </React.Fragment>
                                  );
                                })}
                            </span>
                          )}

                          <span>
                            {selectedLang?.length > 0 &&
                              langResponse
                                ?.filter((e) => e?.code === langSwitcRes)
                                ?.map((langFlag, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <img
                                        src={`${baseUrls}/${langFlag?.flag}`}
                                        style={{ width: "17px" }}
                                        className="me-2"
                                        alt="lang"
                                      />
                                    </React.Fragment>
                                  );
                                })}
                          </span>
                        </Link>
                        <ul class="icon-dropdown">
                          <li>
                            <div className="action-form language-drop">
                              <ul className="flag-icon">
                                {langResponse?.map((e, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <li
                                        onClick={() => {
                                          handleLanguage(e?.flag, e?.code);
                                        }}
                                      >
                                        <span>
                                          <img
                                            src={`${baseUrls}/${e.flag}`}
                                            style={{ width: "17px" }}
                                            alt="lang"
                                          />
                                        </span>

                                        {pathData ? (
                                          <>
                                            <Link
                                              className="language"
                                              to={`/${e?.code}/${pathData}`}
                                            >
                                              {e?.label}
                                            </Link>
                                          </>
                                        ) : (
                                          <>
                                            <Link
                                              className="language"
                                              to={`/${e?.code}`}
                                            >
                                              {e?.label}
                                            </Link>
                                          </>
                                        )}
                                      </li>
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo,links,search */}

          <div>
            <nav
              className="navbar navbar-expand-lg"
              style={{ background: "black" }}
            >
              <div className="container-fluid">
                {logosData?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Link className="navbar-brand" to={`/${langSwitcRes}`}>
                        {/* logo */}
                        <img
                          alt="Karactermania"
                          src={`${baseUrls}/${e?.image}`}
                          className="headerLogo"
                          title="Karactermania"
                        />
                      </Link>
                    </React.Fragment>
                  );
                })}

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {homepageRes?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <li className="nav-item dropdown has-megamenu">
                            <Link
                              to={`/${langSwitcRes}/${e?.slug_name}`}
                              className="text-decoration-none nav-link dropdown-toggle"
                              onClick={() => handleLinks(e?.slug_name)}
                              onMouseEnter={() => {
                                setMenuHover(e?.slug_name);

                                if (
                                  !menuHoverRes[e?.slug_name] ||
                                  menuHoverRes[e?.slug_name]?.langSwitcRes !==
                                    langSwitcRes
                                ) {
                                  dispatch(
                                    menuAction(
                                      langSwitcRes,
                                      res,
                                      e?.id,
                                      e?.slug_name
                                    )
                                  );
                                }
                              }}
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {e?.page_title}
                            </Link>

                            {menuHover === "brands" ? (
                              <Brands pageTitle={menuHover} />
                            ) : (
                              <MenuLinks pageTitle={menuHover} />
                            )}
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                  <Formik>
                    <form onSubmit={formikk.handleSubmit}>
                      <div className="desktop-only">
                        <div className="d-flex custom-search" role="search">
                          <input
                            className="form-control me-2 cs "
                            type="search"
                            name="option"
                            onBlur={formikk.handleBlur}
                            id="option"
                            placeholder={t("search")}
                            onChange={formikk.handleChange}
                            autoComplete="off"
                            title="Please fill in this field."
                            required
                          />

                          <button
                            className="btn sb btn-secondary"
                            type="submit"
                            title={t("search")}
                          >
                            {t("search")}
                          </button>
                        </div>
                      </div>
                      {/* <p className="register-error">
                        {formikk.errors.option &&
                          formikk.touched.option &&
                          formikk.errors.option}
                      </p> */}
                    </form>
                  </Formik>
                </div>
              </div>
            </nav>
            <Formik>
              <form onSubmit={formikk.handleSubmit}>
                <div className="mobile-only">
                  <div className="d-flex custom-search">
                    <input
                      className="form-control me-2 cs "
                      type="search"
                      name="option"
                      onBlur={formikk.handleBlur}
                      id="option"
                      placeholder={t("search")}
                      onChange={formikk.handleChange}
                      // onKeyDown={productsDatas}
                      autoComplete="off"
                      title="Please fill in this field."
                      required
                    />

                    <button
                      className="btn sb btn-secondary"
                      type="submit"
                      title={t("search")}
                    >
                      {t("search")}
                    </button>
                  </div>
                </div>
              </form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
