import React from "react";
import { useSelector } from "react-redux";
import "../../../../index.css";
import { baseUrls } from "../../../../config";

export const BrandBanner = () => {
  const brandPageRes = useSelector(
    (state) => state?.brandPageData?.main_banner
  );

  return (
    <>
      <React.Fragment>
        <div
          id="carouselExampleControls"
          className="carousel slide homepageBanner"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {brandPageRes?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className={
                      i === 0 ? "carousel-item active" : "carousel-item"
                    }
                  >
                    <img
                      className="d-block w-100"
                      src={`${baseUrls}/${e?.image}`}
                      alt={e?.tittle}
                    />
                    {/* <div className="text-white p-3 lptcont1 lptbg1">
                      <a href="#warner">
                        <h1>WARNER</h1>
                        <p className="p1">PRODUCTOS</p>
                        <p className="p2">OFICIALES DE</p>
                        <p className="p3">WARNER</p>
                      </a>
                    </div> */}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </React.Fragment>
    </>
  );
};
