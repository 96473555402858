import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { baseUrlProduct } from "../../../config";
import { myOrderAction, myOrderDetailsAction } from "../../../Redux/Action";

export const MyOrders = () => {
  const [searchOrder, setSearchOrder] = useState("");
  const [searchOrderId, setSearchOrderId] = useState("");

  const dispatch = useDispatch();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const loginResId = res?.data?.userid;

  //Language Switcher
  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(myOrderAction(loginResId));
  }, [dispatch, loginResId]);

  //My orders Response

  const myOrdersRes = useSelector((state) => state?.myOrdersData);

  const orderFilter = myOrdersRes?.filter((value) =>
    value?.order_id?.toString()?.includes(searchOrder)
  );

  //myorders details Response

  const myOrdersDetailsRes = useSelector(
    (state) => state?.myOrdersDetailsData?.myorderdetails
  );

  const orderIdFilter = myOrdersDetailsRes?.filter((value) =>
    value?.sku?.toString()?.includes(searchOrderId)
  );

  //AccountInfo Response

  const accountInfoRes = useSelector((state) => state?.accountInfoData);

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 card p-2 mb-3">
        {langSwitcRes &&
          accountInfoRes?.my_order_content
            ?.filter((e) => e?.language === langSwitcRes)
            ?.map((e, i) => {
              return <React.Fragment key={i}>{e?.tittle}</React.Fragment>;
            })}
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <div className="row">
            <form>
              <div class="search-cont">
                <div class="search">
                  <input
                    type="text"
                    class="search-input"
                    placeholder={t("search-order")}
                    onChange={(e) => setSearchOrder(e.target.value)}
                    value={searchOrder}
                    name="search"
                    id="search"
                  />
                  <Link to="" class="search-icon">
                    <i class="fa fa-search"></i>
                  </Link>
                </div>
              </div>
            </form>
            <div className="scroll-one mt-2">
              <table class="table table-striped table-bordered " id="dest2">
                <thead>
                  <tr>
                    <th className="align-top">{t("order")}</th>
                    <th className="align-top">{t("order_date")}</th>
                    <th className="align-top">{t("order_amount")}</th>
                  </tr>
                </thead>

                {orderFilter.length > 0 ? (
                  myOrdersRes
                    ?.filter((value) => {
                      return searchOrder === ""
                        ? value
                        : value?.order_id?.toString()?.includes(searchOrder);
                    })
                    ?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tbody>
                            <tr
                              onClick={() =>
                                dispatch(myOrderDetailsAction(e?.order_id))
                              }
                            >
                              <td>{e?.order_id}</td>
                              <td>{e?.date_order}</td>
                              <td>{e?.order_total}</td>
                            </tr>
                          </tbody>
                        </React.Fragment>
                      );
                    })
                ) : (
                  <tbody>
                    <tr>
                      <td valign="top" colspan="9" className="text-center">
                        {t("no_data_available")}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 mt-2">
          <div>
            <table class="table table-striped table-bordered" id="dest3">
              <thead>
                <tr>
                  <th>{t("estimated_delivery_groups")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">{t("all")}</td>
                </tr>
                <tr>
                  <td className="text-center">0000-00-00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
          <div className="row">
            <form>
              <div class="search-cont">
                <div class="search">
                  <input
                    type="text"
                    class="search-input"
                    placeholder={t("search-order")}
                    onChange={(e) => setSearchOrderId(e.target.value)}
                    value={searchOrderId}
                    name="searchorderId"
                    id="searchorderId"
                  />
                  <Link to="" class="search-icon">
                    <i class="fa fa-search"></i>
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="scroll-two">
            <table class="table table-striped table-bordered " id="dest4">
              <thead>
                <tr>
                  <th className="align-top">{t("item_ref")}</th>
                  <th className="align-top">{t("pic")}</th>
                  <th className="align-top">{t("item_description")}</th>
                  <th className="align-top">{t("units")}</th>
                  <th className="align-top">{t("item_price")}</th>
                  <th className="align-top">{t("disc1")}</th>
                  <th className="align-top">{t("amount")}</th>
                  <th className="align-top">{t("estimated_delivery")}</th>
                  <th className="align-top">{t("status")}</th>
                </tr>
              </thead>

              {orderIdFilter?.length > 0 ? (
                myOrdersDetailsRes
                  ?.filter((value) => {
                    return searchOrderId === ""
                      ? value
                      : value?.sku?.toString()?.includes(searchOrderId);
                  })
                  ?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tbody>
                          <tr>
                            <td>{e?.sku}</td>
                            <td>
                              <img
                                src={`${baseUrlProduct}/${e?.image}`}
                                alt={e?.sku}
                                className="thumbnail"
                              />
                            </td>
                            <td>{e?.name}</td>
                            <td>{e?.qty}</td>
                            <td>
                              {langSwitcRes === "es" ? (
                                <>
                                  {Number(e?.price).toLocaleString("es-ES", {
                                    minimumFractionDigits: 2,
                                  })}
                                </>
                              ) : langSwitcRes === "fr" ? (
                                <>
                                  {Number(e?.price).toLocaleString("fr-FR", {
                                    minimumFractionDigits: 2,
                                  })}
                                </>
                              ) : (
                                <> {Number(e?.price).toFixed(2)} </>
                              )}
                              €
                            </td>
                            <td>{e?.linear_discount}%</td>
                            <td>
                              {langSwitcRes === "es" ? (
                                <>
                                  {Number(e?.qty * e?.price).toLocaleString(
                                    "es-ES",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </>
                              ) : langSwitcRes === "fr" ? (
                                <>
                                  {Number(e?.qty * e?.price).toLocaleString(
                                    "fr-FR",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </>
                              ) : (
                                <> {Number(e?.qty * e?.price).toFixed(2)} </>
                              )}
                              €
                            </td>
                            <td>{e?.dispatch_date}</td>
                              {e?.type == "b2b_qty" ? (
                                <><td className="successText">PRODUCTS IN STOCK</td></>
                              ) : e?.type == "b2c_or_quest_qty" ? (
                                <><td className="successText">PRODUCTS IN STOCK</td></>
                              ) : e?.type == "pre_order" ? (
                                <><td className="dangerText">PRESALE</td></>
                              ) :(
                                <></>
                              )}
                          </tr>
                        </tbody>
                      </React.Fragment>
                    );
                  })
              ) : (
                <tbody>
                  <tr>
                    <td valign="top" colspan="9" className="text-center">
                      {t("no_data_available")}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {/* {orderIdFilter.length > 0
            ? myOrdersDetailsRes
                ?.filter((value) => {
                  return searchOrderId === ""
                    ? value
                    : value?.sku?.toString()?.includes(searchOrderId);
                })
                ?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-2">
                          <div class="group relative cursor-pointer rounded border p-2 bg-white">
                            <p className="mb-0">
                              <strong>DELIVERY ADDRESS</strong>
                              <br />
                              Barrio de San Roque
                              <br />
                              09692 Canicosa de la sierra
                              <br />
                              Burgos
                              <br />
                              Spain
                            </p>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-2">
                          <div class="group relative cursor-pointer rounded border p-2 bg-white">
                            <p className="mb-0">
                              <strong>BILLING ADDRESS</strong>
                              <br />
                              Barrio de San Roque
                              <br />
                              09692 Canicosa de la sierra
                              <br />
                              Burgos
                              <br />
                              Spain
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                          <div class="group relative cursor-pointer rounded border p-2 bg-white">
                            <p className="mb-0">
                              <strong>DELIVERY METHOD</strong>
                              <br />
                              Text
                            </p>
                          </div>
                          <div class="group relative cursor-pointer rounded border mt-2 p-2 bg-white">
                            <p className="mb-0">
                              <strong>PAYMENT METHOD</strong>
                              <br />
                              Cash On Delivery
                            </p>
                          </div>
                        </div>
                        <div>
                          <div class="progressbar-wrapper">
                            <ul class="progressbar">
                              <li class="active">
                                <strong>Ordered</strong>
                                <br />
                                19 Aug 22
                              </li>
                              <li>In process</li>
                              <li>Shipped</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })
            : ""} */}
        </div>
      </div>
    </>
  );
};
