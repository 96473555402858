import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { forgotResetAction, login, password } from "../../../../Redux/Action";
import { useFormik } from "formik";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { LoginResponse } from "./LoginResponse";
import './Login.css';

export const Login = ({ langSwitcRes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);
  function toggle(value) {
    return !value;
  }

  //Individual Register Response

  const regData = useSelector((state) => state?.individualData?.data?.token);

  //Formik data for login

  const formik = useFormik({
    initialValues: {
      Lemail: "",
      Lpassword: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.Lemail) {
        errors.Lemail = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Lemail)
      ) {
        errors.Lemail = t("email_format_validation");
      }

      if (!values.Lpassword) {
        errors.Lpassword = t("password-validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(
        login(values, regData, { resetForm }, navigate, langSwitcRes, "login")
      );
    },
  });

  //Login content

  const loginContentRes = useSelector((state) => state?.loginContentData);

  //Forgot password

  const formiks = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("email_format_validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(password(values, { resetForm }, langSwitcRes));
    },
  });

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.forgetPassData
  );

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setDisplayError(true);

      setTimeout(() => {
        dispatch(forgotResetAction());
        setDisplayError(false);
      }, 8000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <div
        className="alert alert-success mt-3 d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>{successMsg}</p>
        </div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div
        className="alert alert-danger mt-3 d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>{errorInfo}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="login__container">
      <div className="btwob container">
        <div className="row login__block__row">
          <div className="login__block__col">
            <div className="register-top-block">
              <div className="register-main-title mb-3">
                {loginContentRes?.data?.keywords_list
                  ?.slice(7, 8)
                  ?.map((e, i) => {
                    const keywordData =
                      langSwitcRes === "en"
                        ? e?.keyword_en
                        : langSwitcRes === "es"
                        ? e?.keyword_es
                        : langSwitcRes === "fr"
                        ? e?.keyword_fr
                        : e?.keyword_it;

                    return (
                      <React.Fragment key={i}>{keywordData}</React.Fragment>
                    );
                  })}
              </div>
              <p>
                {loginContentRes?.data?.keywords_list
                  ?.slice(8, 9)
                  ?.map((e, i) => {
                    const keywordData =
                      langSwitcRes === "en"
                        ? e?.keyword_en
                        : langSwitcRes === "es"
                        ? e?.keyword_es
                        : langSwitcRes === "fr"
                        ? e?.keyword_fr
                        : e?.keyword_it;

                    return (
                      <React.Fragment key={i}>{keywordData}</React.Fragment>
                    );
                  })}
              </p>
            </div>
            <div className="register-bottom-block">
              {loginContentRes?.data?.keywords_list
              ?.slice(0, 1)
              ?.map((e, i) => {
                const keywordData =
                  langSwitcRes === "en"
                    ? e?.keyword_en
                    : langSwitcRes === "es"
                    ? e?.keyword_es
                    : langSwitcRes === "fr"
                    ? e?.keyword_fr
                    : e?.keyword_it;

                return (
                  <React.Fragment key={i}>
                    <div className="register-main-title mb-3">{keywordData}</div>
                  </React.Fragment>
                );
              })}
              <div className="login__col__botton">
                <button
                  className="loginbutton"
                  onClick={() => navigate(`/${langSwitcRes}/individualregister`)}
                  title="Individual User"
                >
                  <i
                    className="fa-solid fa-person"
                  ></i>
                  <div className="card-body">
                    {loginContentRes?.data?.keywords_list
                      ?.slice(1, 2)
                      ?.map((e, i) => {
                        const keywordData =
                          langSwitcRes === "en"
                            ? e?.keyword_en
                            : langSwitcRes === "es"
                            ? e?.keyword_es
                            : langSwitcRes === "fr"
                            ? e?.keyword_fr
                            : e?.keyword_it;

                        return (
                          <React.Fragment key={i}>
                            <h6 className="card-text mt-1 fs-6">{keywordData}</h6>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </button>
              </div>
              
            </div>
          </div>
          <div className="login__block__col">
            <div className="register-top-block">
              <div className="register-main-title mb-3">
                {loginContentRes?.data?.keywords_list
                  ?.slice(9, 10)
                  ?.map((e, i) => {
                    const keywordData =
                      langSwitcRes === "en"
                        ? e?.keyword_en
                        : langSwitcRes === "es"
                        ? e?.keyword_es
                        : langSwitcRes === "fr"
                        ? e?.keyword_fr
                        : e?.keyword_it;

                    return (
                      <React.Fragment key={i}>{keywordData}</React.Fragment>
                    );
                  })}
              </div>
              <p>
                {loginContentRes?.data?.keywords_list
                  ?.slice(10, 11)
                  ?.map((e, i) => {
                    const keywordData =
                      langSwitcRes === "en"
                        ? e?.keyword_en
                        : langSwitcRes === "es"
                        ? e?.keyword_es
                        : langSwitcRes === "fr"
                        ? e?.keyword_fr
                        : e?.keyword_it;

                    return (
                      <React.Fragment key={i}>{keywordData}</React.Fragment>
                    );
                  })}
              </p>
            </div>
            <div className="register-bottom-block">
              {loginContentRes?.data?.keywords_list
                ?.slice(2, 3)
                ?.map((e, i) => {
                  const keywordData =
                    langSwitcRes === "en"
                      ? e?.keyword_en
                      : langSwitcRes === "es"
                      ? e?.keyword_es
                      : langSwitcRes === "fr"
                      ? e?.keyword_fr
                      : e?.keyword_it;

                  return (
                    <React.Fragment key={i}>
                      <div className="register-main-title mb-3">{keywordData}:</div>{" "}
                    </React.Fragment>
                  );
                })}

              <div className="row">
                <div className="col col-md-6 col-sm-6">
                  <button
                    className="loginbutton"
                    onClick={() => navigate(`/${langSwitcRes}/shopregister`)}
                    title="Retailer"
                  >
                    <img
                      src="https://karactermania.com/kmania/images/banners/icon_shop_80.png"
                      alt="..."
                    />
                    <div className="card-body">
                      {loginContentRes?.data?.keywords_list
                        ?.slice(3, 4)
                        ?.map((e, i) => {
                          const keywordData =
                            langSwitcRes === "en"
                              ? e?.keyword_en
                              : langSwitcRes === "es"
                              ? e?.keyword_es
                              : langSwitcRes === "fr"
                              ? e?.keyword_fr
                              : e?.keyword_it;

                          return (
                            <React.Fragment key={i}>
                              <h6 className="card-text">{keywordData}</h6>{" "}
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </button>
                </div>
                <div className="col col-md-6 col-sm-6">
                  <button
                    className="loginbutton"
                    onClick={() => navigate(`/${langSwitcRes}/wholeregister`)}
                    title="Wholesaler"
                  >
                    <img
                      src="/../truck1.png"
                      alt="..."
                    />
                    <div className="card-body">
                      {loginContentRes?.data?.keywords_list
                        ?.slice(4, 5)
                        ?.map((e, i) => {
                          const keywordData =
                            langSwitcRes === "en"
                              ? e?.keyword_en
                              : langSwitcRes === "es"
                              ? e?.keyword_es
                              : langSwitcRes === "fr"
                              ? e?.keyword_fr
                              : e?.keyword_it;

                          return (
                            <React.Fragment key={i}>
                              <h6 className="card-text">{keywordData}</h6>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="login__block__col">
            <div className="card bg-light">
              <div className="card-body">
                <div className="register-main-title text-center">
                  {loginContentRes?.data?.keywords_list
                    ?.slice(5, 6)
                    ?.map((e, i) => {
                      const keywordData =
                        langSwitcRes === "en"
                          ? e?.keyword_en
                          : langSwitcRes === "es"
                          ? e?.keyword_es
                          : langSwitcRes === "fr"
                          ? e?.keyword_fr
                          : e?.keyword_it;

                      return (
                        <React.Fragment key={i}>{keywordData}</React.Fragment>
                      );
                    })}
                  <br />
                  {loginContentRes?.data?.keywords_list
                    ?.slice(6, 7)
                    ?.map((e, i) => {
                      const keywordData =
                        langSwitcRes === "en"
                          ? e?.keyword_en
                          : langSwitcRes === "es"
                          ? e?.keyword_es
                          : langSwitcRes === "fr"
                          ? e?.keyword_fr
                          : e?.keyword_it;

                      return (
                        <React.Fragment key={i}>{keywordData}</React.Fragment>
                      );
                    })}
                </div>
                <p className="text-center">
                  {loginContentRes?.data?.keywords_list
                    ?.slice(7, 8)
                    ?.map((e, i) => {
                      const keywordData =
                        langSwitcRes === "en"
                          ? e?.keyword_en
                          : langSwitcRes === "es"
                          ? e?.keyword_es
                          : langSwitcRes === "fr"
                          ? e?.keyword_fr
                          : e?.keyword_it;

                      return (
                        <React.Fragment key={i}>{keywordData}</React.Fragment>
                      );
                    })}
                </p>
                <div class="required-field">{t("required-fields")}</div>

                {/* success or error msg */}

                <LoginResponse />

                <Formik>
                  <form onSubmit={formik.handleSubmit}>
                    {/* Email */}
                    <div className="mt-3">
                      <input
                        title={t("email-address")}
                        className="form-control"
                        type="text"
                        name="Lemail"
                        placeholder={t("email-address")}
                        onChange={formik.handleChange}
                        value={formik.values.Lemail}
                        autoComplete="off"
                        id="Lemail"
                      />

                      <label htmlFor="Lemail" className="visually-hidden">
                        Email Address <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="register-error">{formik.errors.Lemail}</p>
                    {/* Password */}
                    <div className="mt-3">
                      <input
                        title={t("password")}
                        className="form-control"
                        type="password"
                        name="Lpassword"
                        placeholder={t("password")}
                        onChange={formik.handleChange}
                        value={formik.values.Lpassword}
                        autoComplete="off"
                        id="Lpassword"
                      />
                      <label htmlFor="Lpassword" className="visually-hidden">
                        Password <span className="text-danger">*</span>
                      </label>
                    </div>
                    <p className="register-error">{formik.errors.Lpassword}</p>

                    <button
                      className="blackbutton"
                      type="submit"
                      title={t("sign-in")}
                    >
                      {t("sign-in")}
                    </button>
                  </form>
                </Formik>
                <div className="form-check mt-2 mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    checked={checked}
                    onChange={() => setChecked(toggle)}
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    {t("forgot-your-password")}
                  </label>
                </div>
                {checked && (
                  <div>
                    {/* Forgot Password */}

                    <Formik>
                      <form onSubmit={formiks.handleSubmit}>
                        {/* Email */}
                        <p className="reset-password-cont">
                          {loginContentRes?.data?.keywords_list
                            ?.slice(11, 12)
                            ?.map((e, i) => {
                              const keywordData =
                                langSwitcRes === "en"
                                  ? e?.keyword_en
                                  : langSwitcRes === "es"
                                  ? e?.keyword_es
                                  : langSwitcRes === "fr"
                                  ? e?.keyword_fr
                                  : e?.keyword_it;

                              return (
                                <React.Fragment key={i}>
                                  {keywordData}
                                </React.Fragment>
                              );
                            })}
                        </p>

                        {successMsg && displayError && <SuccessAlert />}
                        {errorInfo && displayError && <FailureAlert />}

                        <div className="form-group">
                          <label htmlFor="email" className="visually-hidden">
                            Username
                          </label>
                          <input
                            title={t("email-address")}
                            autoComplete="off"
                            className="form-control"
                            placeholder={t("email-address")}
                            type="text"
                            name="email"
                            onChange={formiks.handleChange}
                            value={formiks.values.email}
                            id="email"
                          />
                        </div>

                        {/* Forgot Validation */}

                        <p className="register-error">{formiks.errors.email}</p>
                        <button
                          className="blackbutton"
                          type="submit"
                          title={t("submit")}
                        >
                          {t("submit")}
                        </button>
                      </form>
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
