import React, { useEffect } from "react";
import { Header } from "../../Common/Header/Header";
import { Sliders } from "../../Common/Slider/Sliders";
import { useParams } from "react-router";
import { languageSelector } from "../../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { LicensedProducts } from "./Components in Homepage/LicensedProducts";
import { DesignerManufacturer } from "./Components in Homepage/DesignerManufacturer";
import { ProductCategories } from "./Components in Homepage/ProductCategories";
import { RegisterAndDiscover } from "./Components in Homepage/RegisterAndDiscover";
import { OurBrands } from "./Components in Homepage/OurBrands";
import { OutstandingProducts } from "./Components in Homepage/OutstandingProducts";
import { ProductsAndAccessories } from "./Components in Homepage/ProductsAndAccessories";
import { FAQ } from "./Components in Homepage/FAQ";
import { Footer } from "../../Common/Footer/Footer";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { Helmet } from "react-helmet";

export const HomepageBeforeLogin = () => {
  const dispatch = useDispatch();

  const { langSwitcRes } = useParams();

  useEffect(() => {
    dispatch(languageSelector(langSwitcRes));
  }, [langSwitcRes, dispatch]);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  return (
    <>
      <Helmet>
        <title>
          KARACTERMANIA - Licensed Products Manufacturer and Wholesaler
        </title>
        <meta
          name="title"
          content="KARACTERMANIA - Licensed Products Manufacturer and Wholesaler"
        />
        <meta
          name="description"
          content="Online Shopping Site for licensed products of your favorite characters in wholesale and retail. Buy backpacks, bags, wallets, suitcases for men, women, and kids."
        />
      </Helmet>
      <Header />
      <Sliders />
      <LicensedProducts />
      <ProductsAndAccessories />
      <DesignerManufacturer />
      <ProductCategories />
      <RegisterAndDiscover />
      <OurBrands />
      <OutstandingProducts />
      <FAQ />
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
