import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "../../../index.css";
import { homePageCategory } from "../../../Redux/Action";

export const Footer = () => {
  const dispatch = useDispatch();

  const { langSwitcRes } = useParams();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  useEffect(() => {
    dispatch(homePageCategory(res, langSwitcRes));
  }, [dispatch, res, langSwitcRes]);

  const homepageRes = useSelector(
    (state) => state?.HomePageData?.footer_block_content
  );

  return (
    <>
      <div
        className="footer-container "
        style={{
          marginTop: "-1rem",
        }}
      >
        <div className="footer-top"></div>
        <div className="footer-mid">
          <div className="container">
            <div className="row fcw">
              {langSwitcRes &&
                homepageRes
                  ?.filter((e) => e?.language === langSwitcRes)
                  ?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div
                          dangerouslySetInnerHTML={{ __html: e?.content }}
                        ></div>
                      </React.Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="footer-bottom"></div>
      </div>
    </>
  );
};
