import React, { useEffect } from "react";
import "../../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { sliders } from "../../../Redux/Action";
import { baseUrls } from "../../../config";

export const Sliders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sliders());
  }, [dispatch]);

  const slidersRes = useSelector((state) => state?.sliderData);

  return (
    <>
      <React.Fragment>
        <div
          id="carouselExampleControls"
          className="carousel slide homepageBanner"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {slidersRes?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <a href={e?.url} target="_blank" rel="noreferrer">
                    <div
                      className={
                        i === 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      <img
                        className="d-block w-100"
                        src={`${baseUrls}/${e?.sliderimage}`}
                        alt={e?.image_alt}
                      />
                    </div>
                  </a>
                </React.Fragment>
              );
            })}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </React.Fragment>
    </>
  );
};
