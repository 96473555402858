import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myCatalog } from "../../../Redux/Action";

export const Catalogs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myCatalog());
  }, [dispatch]);

  const myCatalogRes = useSelector((state) => state?.myCatalogData);

  return (
    <>
      {myCatalogRes?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <div className="row">
              <div className="col">
                <h2>{e?.title}</h2>
              </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>
          </React.Fragment>
        );
      })}
    </>
  );
};
