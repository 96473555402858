import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAction,
  changePasswordResetAction,
} from "../../../Redux/Action";

export const ChangePassword = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const userId = res?.data?.userid;

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.currentPassword) {
        errors.currentPassword = t("current-password-validation");
      }

      if (!values.newPassword) {
        errors.newPassword = t("new-password-validation");
      } else if (values.newPassword.length < 6) {
        errors.newPassword = t("new_password_character_validation");
      }

      if (!values.confirmNewPassword) {
        errors.confirmNewPassword = t("confirm-new-password-validation");
      } else if (values.confirmNewPassword.length < 6) {
        errors.confirmNewPassword = t("confirm_password_validation");
      } else if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = t("password_validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(changePasswordAction(values, userId, { resetForm }));
    },
  });

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.changePasswordData
  );

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setIsAlertVisible(true);

      setTimeout(() => {
        dispatch(changePasswordResetAction());
        setIsAlertVisible(false);
      }, 5000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <div
        className="alert alert-success d-flex align-items-center p-0 mt-4"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
        ></i>
        <div className="register-error-success">{successMsg}</div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div
        className="alert alert-danger d-flex align-items-center p-0 mt-4"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
        ></i>
        <div className="register-error-message">{errorInfo}</div>
      </div>
    );
  };

  return (
    <>
      <Formik>
        <form onSubmit={formik.handleSubmit}>
          {successMsg && isAlertVisible && <SuccessAlert />}
          {errorInfo && isAlertVisible && <FailureAlert />}

          <div class="row g-2">
            {/* Current Password */}

            <div class="col-lg-12">
              <div>
                <label
                  htmlFor="currentPassword"
                  className="form-label visually-hidden"
                >
                  {t("current-password")}*
                </label>
                <input
                  title="Current Password"
                  class="form-control"
                  placeholder={t("current-password")}
                  type="password"
                  name="currentPassword"
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  id="currentPassword"
                  autoComplete="off"
                />
              </div>

              <p className="register-error">
                {formik.errors.currentPassword &&
                  formik.touched.currentPassword &&
                  formik.errors.currentPassword}
              </p>
            </div>

            {/* New Password */}

            <div class="col-lg-12">
              <div>
                <label
                  htmlFor="newPassword"
                  className="form-label visually-hidden"
                >
                  {t("new-password")}*
                </label>
                <input
                  type="password"
                  title="New Password"
                  class="form-control"
                  placeholder={t("new-password")}
                  name="newPassword"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  id="newPassword"
                  autoComplete="off"
                />
              </div>

              <p className="register-error">
                {formik.errors.newPassword &&
                  formik.touched.newPassword &&
                  formik.errors.newPassword}
              </p>
            </div>

            {/* Confirm New Password */}

            <div class="col-lg-12">
              <div>
                <label
                  htmlFor="confirmNewPassword"
                  className="form-label visually-hidden"
                >
                  {t("confirm-new-password")}*
                </label>
                <input
                  title="Confirm new Password"
                  class="form-control"
                  placeholder={t("confirm-new-password")}
                  type="password"
                  name="confirmNewPassword"
                  onChange={formik.handleChange}
                  value={formik.values.confirmNewPassword}
                  id="confirmNewPassword"
                  autoComplete="off"
                />
              </div>

              <p className="register-error">
                {formik.errors.confirmNewPassword &&
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword}
              </p>
            </div>

            <div class="col-lg-12">
              <div class="text-end">
                <button type="submit" class="blackbutton" title={t("confirm")}>
                  {t("confirm")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Formik>
    </>
  );
};
