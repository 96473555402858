import React from "react";
import { Footer } from "./../../Common/Footer/Footer";
import { BatmanPin } from "./../../Common/Footer/BatmanPin";
import { SearchPage } from "./Search Components/SearchPage";
import { useSelector } from "react-redux";
import { Header } from "../../Common/Header/Header";
import { useParams } from "react-router";

export const Search = () => {
  //Login Response

  const res = useSelector((state) => state?.loginData);

  //search input value

  const { srhInputValue } = useParams();

  return (
    <>
      <Header pathData={`search/value=${srhInputValue}`} />
      <SearchPage />
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
