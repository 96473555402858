import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  promoCodeAction,
  promocodeResetAction,
} from "../../../../Redux/Action";

export const ApplyCode = () => {
  const dispatch = useDispatch();

  const { langSwitcRes } = useParams();

  //Cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  //Total Price

  const totalPrice = Object?.keys(addToCartRes)?.reduce(
    (acc, item) => acc + addToCartRes[item]?.qty * addToCartRes[item]?.price,
    0
  );

  //Promo code

  const formiks = useFormik({
    initialValues: {
      promoValue: "",
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(
        promoCodeAction(values, totalPrice, langSwitcRes, {
          resetForm,
        })
      );
    },
  });

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.promoCodeData
  );

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setIsAlertVisible(true);

      setTimeout(() => {
        dispatch(promocodeResetAction());
        setIsAlertVisible(false);
      }, 5000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <p className="applycode-success-message">{successMsg?.data?.message}</p>
    );
  };

  const FailureAlert = () => {
    return <p className="register-error">{errorInfo?.data?.message}</p>;
  };

  return (
    <Formik>
      <form onSubmit={formiks.handleSubmit}>
        <div className="discount-form">
          <label htmlFor="coupon-code">Apply Code</label>
          <div className="mt-2 mb-2">
            <input
              type="text"
              className="form-control w-50"
              title="Apply code"
              name="promoValue"
              placeholder="Apply Code *"
              onChange={formiks.handleChange}
              value={formiks.values.promoValue}
              id="promoValue"
            />
          </div>

          {successMsg && isAlertVisible && <SuccessAlert />}
          {errorInfo && isAlertVisible && <FailureAlert />}

          <button
            className="checkoutbutton"
            type="submit"
            title="Apply Coupon"
            value="Apply Coupon"
          >
            Apply Coupon
          </button>
        </div>
      </form>
    </Formik>
  );
};
