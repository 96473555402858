import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  paymentApiAction,
  placeOrderAction,
  makePaymentAction,
  stepsAction,
  promocodeResetAction,
  promocodeCartAction,
} from "../../../../Redux/Action";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { OrderReviewProducts } from "./OrderReviewProducts";
import { ApplyCode } from "./ApplyCode";
import { BillingShipping } from "./BillingShipping";

export const CheckoutOrderReview = ({ setStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState("cashondelivery");
  const [accept, setAccept] = useState(true);

  //Cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  const cartId = Object.keys(addToCartRes)?.map(
    (e) => addToCartRes[e]?.cart_id
  );

  //Total Price

  const totalPrice = Object?.keys(addToCartRes)?.reduce(
    (acc, item) => acc + addToCartRes[item]?.qty * addToCartRes[item]?.price,
    0
  );

  //Language switcher

  const { langSwitcRes } = useParams();

  //Apply code Res

  const { applyCodeRes } = useSelector((state) => state?.promoCodeData);

  //Payment

  const placeOrderRes = useSelector((state) => state?.placeOrderPaypalData);

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [cancelData, setCancelData] = useState("");
  const [succesData, setSuccessData] = useState("");

  const initialOptions = {
    "client-id":
      "AW5GjqJxghYybz8gmeM48ZdBtNSXaio2SMNvA3C-CirZo0XlyL6VT3p8MJFo6X3DR37Bw14irltkE7-a",
    currency: "EUR",
  };

  // creates a paypal order

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "EUR",
              value:
                applyCodeRes?.data?.success == true
                  ? applyCodeRes?.data?.total_amount
                  : Number(totalPrice).toFixed(2),
            },
            invoice_id: placeOrderRes?.data?.message?.order_id,
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);

        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccessData(details);
      // if (details?.status == "COMPLETED") {
      //   navigate(`/${langSwitcRes}/thankyou`);
      // }
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  PayPalButtons.defaultProps = {
    env: "sandbox",
    onSuccess: (payment) => {
      setSuccessData(payment);
    },
    onCancel: (data) => {
      setCancelData(data);
    },
    onError: (err) => {},
  };

  // useEffect(() => {
  //   if (success) {
  //     alert("Payment successful!!");
  //   }
  // }, [success]);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const resGeneral = res?.data?.group;

  //Payment

  const formik = useFormik({
    initialValues: {
      option: "",
      acceptTerms: "",
    },

    validate: (values) => {
      const errors = {};

      //Accept Terms

      if (!values.acceptTerms) {
        errors.acceptTerms = "Choose one.";
      }

      return errors;
    },
    onSubmit: (values) => {
      dispatch(paymentApiAction(values, navigate));
    },
  });

  useEffect(() => {
    if (paymentMethod) {
      if (applyCodeRes?.data?.success == true) {
        dispatch(
          paymentApiAction(paymentMethod, applyCodeRes?.data?.total_amount)
        );
      } else {
        dispatch(
          paymentApiAction(paymentMethod, Number(totalPrice).toFixed(2))
        );
      }
    }
  }, [paymentMethod]);

  useEffect(() => {
    setShow(false);
    dispatch(promocodeCartAction());
  }, [addToCartRes]);

  //Payment Response

  const paymentRes = useSelector((state) => state?.paymentData?.payment_method);

  const userID = res?.data?.userid;

  const [checkoutData, setCheckoutData] = useState({
    preorderData: [],
    immediateServiceData: [],
  });

  useEffect(() => {
    const preSale = [];
    const immediateSer = [];

    Object.keys(addToCartRes)?.map((e) => {
      if (addToCartRes[e]?.pre_sale == 1) {
        preSale?.push(addToCartRes[e]);
      } else {
        immediateSer?.push(addToCartRes[e]);
      }
    });

    setCheckoutData({
      ...checkoutData,
      preorderData: preSale,
      immediateServiceData: immediateSer,
    });
  }, [addToCartRes]);

  //Billing Response

  const billingRes = useSelector((state) => state?.billingData);

  //Shipping Response

  const shippingRes = useSelector((state) => state?.shippingData?.data);

  const placeOrderSelect = () => {
    if (applyCodeRes?.data?.success == true) {
      dispatch(
        placeOrderAction(
          langSwitcRes,
          cartId,
          applyCodeRes?.data?.total_amount,
          applyCodeRes?.data?.success == true
            ? applyCodeRes?.data?.display_amount
            : Number(totalPrice).toFixed(2),
          applyCodeRes?.data?.discount_percentage,
          applyCodeRes?.data?.coupon_code,
          userID,
          resGeneral,
          paymentMethod,
          "success",
          billingRes?.data?.address_id,
          shippingRes?.data?.address_id,
          navigate
        )
      );
    } else {
      dispatch(
        placeOrderAction(
          langSwitcRes,
          cartId,
          applyCodeRes?.data?.success == true
            ? applyCodeRes?.data?.display_amount
            : Number(totalPrice).toFixed(2),
          applyCodeRes?.data?.success == true
            ? applyCodeRes?.data?.display_amount
            : Number(totalPrice).toFixed(2),
          applyCodeRes?.data?.discount_percentage,
          applyCodeRes?.data?.coupon_code,
          userID,
          resGeneral,
          paymentMethod,
          "success",
          billingRes?.data?.address_id,
          shippingRes?.data?.address_id,
          navigate
        )
      );
    }

    if (paymentMethod == "paypal") {
      setShow(!show);
    } else {
      setShow(show);
    }
  };

  const totalQuantity = Object?.keys(addToCartRes)?.reduce(
    (acc, item) => +acc + +addToCartRes[item]?.qty,
    0
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Object.keys(succesData)?.length > 0) {
      dispatch(
        makePaymentAction(
          paymentMethod,
          resGeneral,
          cartId,
          placeOrderRes?.data?.message?.order_id,
          succesData?.status,
          applyCodeRes?.data?.success == true
            ? applyCodeRes?.data?.display_amount
            : Number(totalPrice).toFixed(2),
          succesData?.id,
          navigate,
          langSwitcRes
        )
      );
    } else {
      dispatch(
        makePaymentAction(
          paymentMethod,
          resGeneral,
          cartId,
          placeOrderRes?.data?.message?.order_id,
          "CANCELLED",
          applyCodeRes?.data?.success == true
            ? applyCodeRes?.data?.display_amount
            : Number(totalPrice).toFixed(2),
          cancelData?.orderID,
          navigate,
          langSwitcRes
        )
      );
    }
  }, [succesData, cancelData]);

  useEffect(() => {
    dispatch(
      stepsAction([
        { step: "step1", stepValue: true },
        { step: "step2", stepValue: true },
        { step: "step3", stepValue: true },
        { step: "step4", stepValue: true },
        { step: "step5", stepValue: true },
      ])
    );
  }, []);

  const methodPayment = (e) => {
    const { value } = e.target;
    setPaymentMethod(value);
    setShow(false);
  };

  //When the Cart is empty it will lands in empty cart page

  useEffect(() => {
    if (Object.keys(addToCartRes)?.length === 0) {
      setTimeout(() => {
        setStep("SHOPPING CART");
        navigate(`/${langSwitcRes}/emptycart`);
      }, 2000);
    }
  }, [addToCartRes]);

  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        <div
          className="container"
          style={{ marginTop: "2rem", marginBottom: "5rem" }}
        >
          <div className="billing-cont">
            <div className="row">
              <div className="page-header text-center mb-3">
                <h3>YOUR SHOPPING BASKET</h3>
              </div>
              <OrderReviewProducts langSwitcRes={langSwitcRes} />
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                <ApplyCode />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="shipping-total-two">
                  <ul>
                    <li className="subtotal">
                      <span>SUBTOTAL </span>
                      <span className="price" id="checkout-subtotal">
                        {langSwitcRes === "es" ? (
                          <>
                            {Number(totalPrice).toLocaleString("es-ES", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        ) : langSwitcRes === "fr" ? (
                          <>
                            {Number(totalPrice).toLocaleString("fr-FR", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        ) : (
                          <> {Number(totalPrice).toFixed(2)} </>
                        )}
                        €
                      </span>
                    </li>
                    {/* <li className="subtotal">
                      <span>Free Shipping </span>
                      <span className="price" id="checkout-subtotal">
                        00,00 €
                      </span>
                    </li>
                    <li className="subtotal">
                      <span>Shipping Charge - Shipping Amount</span>
                      <span className="price" id="checkout-subtotal">
                        00,00 €
                      </span>
                    </li> */}
                    <li className="subtotal">
                      <span>Discount</span>
                      <span className="price" id="checkout-subtotal">
                        {applyCodeRes?.data?.success == true ? (
                          <> {applyCodeRes?.data?.discount_percentage} </>
                        ) : (
                          <>0</>
                        )}{" "}
                        %
                      </span>
                    </li>
                    <li className="total">
                      <strong>TOTAL</strong>
                      <span className="price" id="checkout-total">
                        {applyCodeRes?.data?.success == true ? (
                          <>
                            {langSwitcRes === "es" ? (
                              <>{applyCodeRes?.data?.display_amount}</>
                            ) : langSwitcRes === "fr" ? (
                              <>{applyCodeRes?.data?.display_amount}</>
                            ) : (
                              <>{applyCodeRes?.data?.display_amount}</>
                            )}
                            €
                          </>
                        ) : (
                          <>
                            {" "}
                            {langSwitcRes === "es" ? (
                              <>
                                {Number(totalPrice).toLocaleString("es-ES", {
                                  minimumFractionDigits: 2,
                                })}
                              </>
                            ) : langSwitcRes === "fr" ? (
                              <>
                                {Number(totalPrice).toLocaleString("fr-FR", {
                                  minimumFractionDigits: 2,
                                })}
                              </>
                            ) : (
                              <> {Number(totalPrice).toFixed(2)} </>
                            )}
                            €
                          </>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <BillingShipping setStep={setStep} />
            </div>

            {resGeneral == "retailer" ? (
              <></>
            ) : resGeneral == "wholesaler" ? (
              <></>
            ) : (
              <>
                <div className="payment-methods">PAYMENT METHODS</div>
              </>
            )}

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                <Formik>
                  <form onSubmit={formik.handleSubmit}>
                    {resGeneral == "retailer" ? (
                      <></>
                    ) : resGeneral == "wholesaler" ? (
                      <></>
                    ) : (
                      <>
                        {/* Redsys */}
                        <div className="form-check payment-redsys">
                          <input
                            onChange={methodPayment}
                            defaultChecked
                            value="redsys"
                            id="option2"
                            name="option"
                            className="form-check-input"
                            type="radio"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            <img
                              src="/images/payments/payment2.png"
                              alt="Redsys"
                            />
                          </label>
                        </div>

                        {/* Paypal */}
                        <div className="form-check payment-paypal">
                          <input
                            onChange={methodPayment}
                            value="paypal"
                            id="option1"
                            name="option"
                            className="form-check-input"
                            type="radio"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            <img
                              src="/images/payments/paypal.png"
                              alt="Paypal"
                            />
                          </label>
                        </div>

                        {/* Cash on delivery */}
                        {/* <div className="form-check payment-redsys">
                          <input
                            onChange={() => setPaymentMethod("cashondelivery")}
                            value="cashondelivery"
                            // defaultChecked
                            id="option2"
                            name="option"
                            className="form-check-input"
                            type="radio"
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Cash on Delivery
                          </label>
                        </div> */}
                      </>
                    )}
                  </form>
                </Formik>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-3">
                <div className="shipping-total">
                  <div className="inyour-cart">IN YOUR CART</div>
                  <ul>
                    <li className="subtotal">
                      <span>Subtotal ({totalQuantity} Items)</span>

                      <span className="price" id="checkout-subtotal">
                        {langSwitcRes === "es" ? (
                          <>
                            {Number(totalPrice).toLocaleString("es-ES", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        ) : langSwitcRes === "fr" ? (
                          <>
                            {Number(totalPrice).toLocaleString("fr-FR", {
                              minimumFractionDigits: 2,
                            })}
                          </>
                        ) : (
                          <> {Number(totalPrice).toFixed(2)} </>
                        )}
                        €
                      </span>
                    </li>
                    {/* <li className="subtotal">
                      <span>Shipping Charge - Shipping Amount</span>
                      <span className="price" id="checkout-subtotal">
                        00,00 €
                      </span>
                    </li> */}
                    <li className="subtotal">
                      <span>Discount</span>
                      <span className="price" id="checkout-subtotal">
                        {applyCodeRes?.data?.success == true ? (
                          <> {applyCodeRes?.data?.discount_percentage} </>
                        ) : (
                          <>0</>
                        )}{" "}
                        %
                      </span>
                    </li>
                    <li className="total">
                      <strong>TOTAL</strong>
                      <span className="price" id="checkout-total">
                        {applyCodeRes?.data?.success == true ? (
                          <>
                            {" "}
                            {langSwitcRes === "es" ? (
                              <>{applyCodeRes?.data?.display_amount}</>
                            ) : langSwitcRes === "fr" ? (
                              <>{applyCodeRes?.data?.display_amount}</>
                            ) : (
                              <>{applyCodeRes?.data?.display_amount}</>
                            )}
                            €
                          </>
                        ) : (
                          <>
                            {" "}
                            {langSwitcRes === "es" ? (
                              <>
                                {Number(totalPrice).toLocaleString("es-ES", {
                                  minimumFractionDigits: 2,
                                })}
                              </>
                            ) : langSwitcRes === "fr" ? (
                              <>
                                {Number(totalPrice).toLocaleString("fr-FR", {
                                  minimumFractionDigits: 2,
                                })}
                              </>
                            ) : (
                              <> {Number(totalPrice).toFixed(2)} </>
                            )}
                            €
                          </>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="iagree-cont">
                  <div className="iagree-checkbox">
                    <input
                      type="checkbox"
                      checked={accept}
                      onChange={(e) => {
                        setAccept(e.target.checked);
                      }}
                    />
                  </div>
                  I agree to the
                  <Link to={`/${langSwitcRes}/legal-notice`}>
                    Terms and Conditions
                  </Link>
                </div>

                {accept == false ? (
                  <>
                    <p className="register-error">
                      Please indicate that you accept the Terms and Conditions.
                    </p>
                  </>
                ) : (
                  <></>
                )}

                {/* <form> */}

                {resGeneral == "retailer" ? (
                  <>
                    {Object?.keys(addToCartRes)?.length > 0 ? (
                      <button
                        type="submit"
                        onClick={placeOrderSelect}
                        className="checkoutbutton w-100"
                      >
                        place order
                      </button>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="checkoutbutton w-100"
                          onClick={(e) => e?.preventDefault()}
                        >
                          place order
                        </button>
                      </>
                    )}
                  </>
                ) : resGeneral == "wholesaler" ? (
                  <>
                    {Object?.keys(addToCartRes)?.length > 0 ? (
                      <button
                        type="submit"
                        onClick={placeOrderSelect}
                        className="checkoutbutton w-100"
                      >
                        place order
                      </button>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className="checkoutbutton w-100"
                          onClick={(e) => e?.preventDefault()}
                        >
                          place order
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {paymentMethod == "redsys" &&
                    accept == true &&
                    addToCartRes ? (
                      <>
                        {" "}
                        <form
                          action={paymentRes?.host_url}
                          method="post"
                          name="redsys form"
                          target="_blank"
                        >
                          <input
                            type="hidden"
                            name="Ds_SignatureVersion"
                            value={paymentRes?.Ds_SignatureVersion}
                          />
                          <input
                            type="hidden"
                            name="Ds_MerchantParameters"
                            value={paymentRes?.Ds_MerchantParameters}
                          />
                          <input
                            type="hidden"
                            name="Ds_Signature"
                            value={paymentRes?.Ds_Signature}
                          />

                          <button
                            type="submit"
                            onClick={placeOrderSelect}
                            className="checkoutbutton w-100"
                          >
                            place order
                          </button>
                        </form>
                      </>
                    ) : paymentMethod == "paypal" &&
                      accept == true &&
                      Object.keys(addToCartRes)?.length > 0 ? (
                      <>
                        {show == true && placeOrderRes?.status == 200 ? (
                          <></>
                        ) : (
                          <>
                            {Object?.keys(addToCartRes)?.length > 0 ? (
                              <button
                                type="submit"
                                onClick={placeOrderSelect}
                                className="checkoutbutton w-100"
                              >
                                place order
                              </button>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="checkoutbutton w-100"
                                  onClick={(e) => e?.preventDefault()}
                                >
                                  place order
                                </button>
                              </>
                            )}
                          </>
                        )}

                        {show == true && placeOrderRes?.status == 200 ? (
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                          />
                        ) : null}
                      </>
                    ) : (
                      <>
                        <button type="submit" className="checkoutbutton w-100">
                          place order
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </PayPalScriptProvider>
    </>
  );
};
