import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { baseUrls } from "../../../../config";
import { useNavigate, useParams } from "react-router";

export const CategoryContact = () => {
  const navigate = useNavigate();

  //Language Switcher

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  //category content Response

  const categoryBlock1Res = useSelector(
    (state) => state?.categoryData?.contact_block_1
  );

  //category content Response

  const categoryBlock2Res = useSelector(
    (state) => state?.categoryData?.partner_block
  );

  //category content Response

  const categoryBlock3Res = useSelector(
    (state) => state?.categoryData?.contact_block_2
  );

  //category content Response

  const categoryBlock4Res = useSelector(
    (state) => state?.categoryData?.contact_block_3
  );

  //Category Register Response

  const categoryRegisterRes = useSelector(
    (state) => state?.categoryData?.b2b_identifier_block
  );

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="our-customers-cont">
        <div className="container">
          {langSwitcRes &&
            categoryBlock1Res
              ?.filter((e) => e?.language === langSwitcRes)
              ?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="section-heading mt-lg-4 text-uppercase">
                      {e?.tittle}
                    </div>
                    <p className="section-paragraph">
                      <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>
                    </p>
                  </React.Fragment>
                );
              })}

          <Slider {...settings} className=" text-center sliderContact">
            {categoryBlock2Res?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="container">
                    <div className="card cardmargins border-0  container">
                      {/* <a href="#"> */}
                      <div className="swapimgtwo-container-nb">
                        <img
                          className="card-img-top paacenter"
                          src={`${baseUrls}/${e?.image}`}
                          alt={e?.name}
                          title={e?.name}
                        />
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </Slider>

          <div className="d-flex justify-content-center mt-lg-5 ">
            <button
              className="blackbutton"
              title="Contact us"
              onClick={() => navigate(`/${langSwitcRes}/contactus`)}
            >
              CONTACT US
            </button>
          </div>

          <div className="container-fluid ">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-5">
                {langSwitcRes &&
                  categoryBlock3Res
                    ?.filter((e) => e?.language === langSwitcRes)
                    ?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="ProDGSpecial-content">
                            <div className="heading text-uppercase">
                              {e?.tittle}
                            </div>
                          </div>
                          <p
                            className="section-paragraph3"
                            dangerouslySetInnerHTML={{
                              __html: e?.content,
                            }}
                          ></p>
                        </React.Fragment>
                      );
                    })}

                {langSwitcRes &&
                  categoryBlock4Res
                    ?.filter((e) => e?.language === langSwitcRes)
                    ?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="ProDGSpecial-content">
                            <div className="heading text-uppercase">
                              {e?.tittle}
                            </div>
                          </div>
                          <p
                            className="section-paragraph3"
                            dangerouslySetInnerHTML={{
                              __html: e?.content,
                            }}
                          ></p>
                        </React.Fragment>
                      );
                    })}
              </div>

              {langSwitcRes &&
                categoryRegisterRes
                  ?.filter((e) => e?.language === langSwitcRes)
                  ?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                          <div className="dos-cont mt-4">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.content,
                              }}
                            ></p>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-5">
                {langSwitcRes &&
                  categoryBlock1Res
                    ?.filter((e) => e?.language === langSwitcRes)
                    ?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <img
                            src={`${baseUrls}/${e?.image}`}
                            className="w-75 mt-4 ms-5"
                            alt="category"
                          />
                        </React.Fragment>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-customers-bottom"></div>
    </>
  );
};
