import React, { useEffect, useState } from "react";
import "../../../index.css";
import { Footer } from "../../Common/Footer/Footer";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { Header } from "../../Common/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  countryApi,
  editAddressAction,
  myAddress,
} from "../../../Redux/Action";
import { Formik, useFormik } from "formik";
import { useNavigate, useParams } from "react-router";

export const EditAddress = () => {
  const dispatch = useDispatch();

  const { langSwitcRes } = useParams();

  const navigate = useNavigate();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const userID = res?.data?.userid;

  const loginResId = res?.data?.userid;

  useEffect(() => {
    dispatch(countryApi(langSwitcRes));
    dispatch(myAddress(loginResId, langSwitcRes));
  }, [loginResId, langSwitcRes, dispatch]);

  //Country Response in Register

  const counData = useSelector((state) => state?.countryData);

  //My Address Response

  const myAddressRes = useSelector(
    (state) => state?.editBillShiData?.data?.my_addresses
  );

  const [checked, setChecked] = useState(true);
  const [billingUncheck, setBillingUncheck] = useState(false);

  const [shippingCheck, setShippingChecked] = useState(true);
  const [shippingUncheck, setShippingUncheck] = useState(false);

  const formik = useFormik({
    initialValues: {
      first: myAddressRes[0]?.first_name,
      last: myAddressRes[0]?.last_name,
      countryId:
        langSwitcRes === "en"
          ? counData?.filter((e) => e?.name_en === myAddressRes[0]?.name_en)[0]
              ?.country_id
          : langSwitcRes === "es"
          ? counData?.filter((e) => e?.name_es === myAddressRes[0]?.name_es)[0]
              ?.country_id
          : langSwitcRes === "fr"
          ? counData?.filter((e) => e?.name_fr === myAddressRes[0]?.name_fr)[0]
              ?.country_id
          : counData?.filter((e) => e?.name_it === myAddressRes[0]?.name_it)[0]
              ?.country_id,

      phone: myAddressRes[0]?.telephone,
      billing1: myAddressRes[0]?.address_1,
      billing2: myAddressRes[0]?.address_2,
      state: myAddressRes[0]?.state,
      city: myAddressRes[0]?.city,
      zipCode: myAddressRes[0]?.postal_code,
      addressBook: checked,
      shippingAddress: shippingCheck,
      addressId: myAddressRes[0]?.address_id,
    },

    validate: (values) => {
      const errors = {};

      //First

      if (!values.first) {
        errors.first = "First name cannot be blank.";
      } else if (!/^[a-zA-Z ]*$/.test(values.first)) {
        errors.first = "First name should contain only characters";
      }

      //last

      if (!values.last) {
        errors.last = "Last name cannot be blank.";
      } else if (!/^[a-zA-Z ]*$/.test(values.last)) {
        errors.last = "First name should contain only characters";
      }

      //Phone

      if (!values.phone) {
        errors.phone = "Telephone cannot be blank.";
      } else if (!/^[0-9]*$/.test(values.phone)) {
        errors.phone = "Telephone should contain only numbers";
      }

      //Billing Address 1

      if (!values.billing1) {
        errors.billing1 = "Billing address cannot be blank.";
      }

      //Country

      if (!values.countryId) {
        errors.countryId = "Country cannot be blank.";
      }

      //State

      if (!values.state) {
        errors.state = "State/Province cannot be blank.";
      }

      //City

      if (!values.city) {
        errors.city = "City cannot be blank.";
      }

      //Zipcode

      if (!values.zipCode) {
        errors.zipCode = "Zipcode cannot be blank.";
      }

      return errors;
    },
    onSubmit: (values) => {
      {
        myAddressRes[0]?.default_shipping_address == "0" &&
        myAddressRes[0]?.default_billing_address == "1" ? (
          <>
            {dispatch(
              editAddressAction(
                values,
                navigate,
                langSwitcRes,
                userID,
                shippingUncheck,
                checked
              )
            )}
          </>
        ) : myAddressRes[0]?.default_shipping_address == "1" &&
          myAddressRes[0]?.default_billing_address == "1" ? (
          <>
            {dispatch(
              editAddressAction(
                values,
                navigate,
                langSwitcRes,
                userID,
                shippingCheck,
                checked
              )
            )}
          </>
        ) : myAddressRes[0]?.default_shipping_address == "1" &&
          myAddressRes[0]?.default_billing_address == "0" ? (
          <>
            {dispatch(
              editAddressAction(
                values,
                navigate,
                langSwitcRes,
                userID,
                shippingCheck,
                billingUncheck
              )
            )}
          </>
        ) : (
          <></>
        );
      }
    },
  });

  const newInputValue = (newName, newValue) => {
    formik.setFieldValue(newName, newValue);
  };

  return (
    <>
      <Header />
      <div className="myaccount-page-body mt-lg-5">
        <div className="container pt-lg-5">
          <div class="page-header">
            <h3>Add New Address</h3>
          </div>

          <div className="extramargin">
            <div className="row">
              <div className="col-6 backbutton">
                <a href={`/${langSwitcRes}/myaccount`}>&#171; Back</a>
              </div>
              <div className="col-6">
                <div className="required-field">* Required Fields</div>
              </div>
            </div>

            <Formik>
              <form onSubmit={formik.handleSubmit}>
                <div className="fieldset mb-3">
                  <h2 class="legend large">CONTACT INFORMATION</h2>
                  <div className="row">
                    {/* First name */}

                    <div class="col-6 mb-3 mt-3">
                      <label htmlFor="first" class="form-label">
                        First name <span className="rf">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="first"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.first}
                        id="first"
                        placeholder="First Name *"
                        autoComplete="off"
                      />
                      {/* First name validations */}

                      <p className="register-error">
                        {formik.errors.first &&
                          formik.touched.first &&
                          formik.errors.first}
                      </p>
                    </div>

                    {/* last name */}

                    <div class="col-6 mb-3 mt-3">
                      <label htmlFor="last" class="form-label">
                        Last name <span className="rf">*</span>
                      </label>
                      <input
                        name="last"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.last}
                        id="last"
                        type="text"
                        className="form-control"
                        placeholder="Last Name *"
                        autoComplete="off"
                      />

                      {/* Last name validations */}

                      <p className="register-error">
                        {formik.errors.last &&
                          formik.touched.last &&
                          formik.errors.last}
                      </p>
                    </div>

                    {/* Telephone */}

                    <div class="col-6 mb-3">
                      <label htmlFor="phone" class="form-label">
                        Telephone <span className="rf">*</span>
                      </label>
                      <input
                        name="phone"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.phone}
                        id="phone"
                        type="text"
                        className="form-control"
                        placeholder="Telephone / Mobile Number *"
                        autoComplete="off"
                      />
                      {/* Phone Validation */}

                      <p className="register-error">
                        {formik.errors.phone &&
                          formik.touched.phone &&
                          formik.errors.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fieldset mb-3">
                  <h2 class="legend large">ADDRESS</h2>
                  <div className="row">
                    <div class="col-12 mb-3 mt-3">
                      <label htmlFor="billing1" class="form-label">
                        Address 1 <span className="rf">*</span>
                      </label>
                      <input
                        name="billing1"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.billing1}
                        id="billing1"
                        type="text"
                        className="form-control"
                        placeholder="Address 1 *"
                        autoComplete="off"
                      />

                      {/* Billing 1 Validations */}

                      <p className="register-error">
                        {formik.errors.billing1 &&
                          formik.touched.billing1 &&
                          formik.errors.billing1}
                      </p>
                    </div>
                    <div class="col-12 mb-3">
                      <label htmlFor="billing2" class="form-label">
                        Address 2 <span className="rf">*</span>
                      </label>
                      <input
                        name="billing2"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.billing2}
                        id="billing2"
                        type="text"
                        className="form-control"
                        placeholder="Address 2 *"
                        autoComplete="off"
                      />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label htmlFor="city" class="form-label">
                        City <span className="rf">*</span>
                      </label>
                      <input
                        name="city"
                        type="text"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.city}
                        id="city"
                        className="form-control"
                        placeholder="City *"
                        autoComplete="off"
                      />

                      {/* City Validation */}

                      <p className="register-error">
                        {formik.errors.city &&
                          formik.touched.city &&
                          formik.errors.city}
                      </p>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label htmlFor="countryId" class="form-label">
                        Country <span className="rf">*</span>
                      </label>
                      <select
                        id="countryId"
                        name="countryId"
                        aria-label="Floating label select example"
                        value={formik.values.countryId}
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        className="form-select"
                        aria-required="true"
                        aria-invalid="true"
                      >
                        <option value=""> Choose a Country </option>
                        {counData?.map((e) => {
                          const slugData =
                            langSwitcRes === "en"
                              ? e?.name_en
                              : langSwitcRes === "es"
                              ? e?.name_es
                              : langSwitcRes === "fr"
                              ? e?.name_fr
                              : e?.name_it;
                          return (
                            <>
                              <option value={e.country_id}>{slugData}</option>
                            </>
                          );
                        })}
                      </select>

                      {/* select country Validation */}

                      <p className="register-error">
                        {formik.errors.countryId &&
                          formik.touched.countryId &&
                          formik.errors.countryId}
                      </p>
                    </div>
                    <div class="col-6 mb-3">
                      <label htmlFor="zipCode" class="form-label">
                        Zip/Postal Code <span className="rf">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="zipCode"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.zipCode}
                        id="zipCode"
                        placeholder="Zip/Postal Code *"
                        autoComplete="off"
                      />

                      {/* Postal code Validation */}

                      <p className="register-error">
                        {formik.errors.zipCode &&
                          formik.touched.zipCode &&
                          formik.errors.zipCode}
                      </p>
                    </div>
                    <div class="col-6 mb-3">
                      <label htmlFor="state" class="form-label">
                        State/Province <span className="rf">*</span>
                      </label>
                      <input
                        name="state"
                        onChange={(e) =>
                          newInputValue(e.target.name, e.target.value)
                        }
                        value={formik.values.state}
                        id="state"
                        type="text"
                        className="form-control"
                        placeholder="State / Province *"
                        autoComplete="off"
                      />
                      {/* state Validation */}

                      <p className="register-error">
                        {formik.errors.state &&
                          formik.touched.state &&
                          formik.errors.state}
                      </p>
                    </div>
                    <div class="col-12">
                      <div class="form-check">
                        {myAddressRes[0]?.default_billing_address == "1" ? (
                          <>
                            <input
                              type="checkbox"
                              defaultChecked={checked}
                              onChange={() => setChecked(!checked)}
                              value="default billing address"
                              id="addressBook"
                              name="addressBook"
                              className="form-check-input"
                            />
                          </>
                        ) : (
                          <>
                            <input
                              type="checkbox"
                              onChange={() =>
                                setBillingUncheck(!billingUncheck)
                              }
                              value="default billing address"
                              id="addressBook"
                              name="addressBook"
                              className="form-check-input"
                            />
                          </>
                        )}

                        <label class="form-check-label" for="addressBook">
                          Use as my default billing address
                        </label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-check">
                        {myAddressRes[0]?.default_shipping_address == "1" ? (
                          <>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              onChange={() =>
                                setShippingChecked(!shippingCheck)
                              }
                              defaultChecked={shippingCheck}
                              value="default shipping address"
                              id="shippingAddress"
                              name="shippingAddress"
                            />
                          </>
                        ) : (
                          <>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              onChange={() =>
                                setShippingUncheck(!shippingUncheck)
                              }
                              value="default shipping address"
                              id="shippingAddress"
                              name="shippingAddress"
                            />
                          </>
                        )}

                        <label class="form-check-label" for="gridCheck">
                          Use as my default shipping address
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="blackbutton">
                    Save Address
                  </button>
                </div>
              </form>
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
      <BatmanPin />
    </>
  );
};
