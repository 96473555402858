import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { baseUrls } from "../../../../config/index";
import { pageCategories } from "../../../../Redux/Action";

export const CategoryLicense = () => {
  const dispatch = useDispatch();

  const { slugName } = useParams();

  //Language Switcher

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  useEffect(() => {
    dispatch(pageCategories(langSwitcRes, slugName, res));
  }, [langSwitcRes, slugName, res, dispatch]);

  const categoryPageRes = useSelector(
    (state) => state?.categoryData?.brand_block
  );

  const categoryConRes = useSelector(
    (state) => state?.categoryData?.brand_block_content
  );

  return (
    <>
      <div className="licensedproducts-cont">
        {categoryConRes
          ?.filter((e) => e?.language === langSwitcRes)
          ?.map((e, i) => {
            return (
              <React.Fragment key={i}>
                <div className="section-heading text-uppercase">
                  {e?.tittle}
                </div>
                <p className="section-paragraph">
                  <span dangerouslySetInnerHTML={{ __html: e?.content }}></span>
                </p>
              </React.Fragment>
            );
          })}

        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2">
            {categoryPageRes?.slice(1, 2)?.map((e, i) => {
              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={titleData} />
                      <div className="text-white p-3 lptcont">
                        <h2>{titleData}</h2>
                        <p className="lptbg">
                          <Link
                            to={`/${langSwitcRes}/productos/${titleData}`}
                            title={e?.sort_description}
                          >
                            {e?.sort_description}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {categoryPageRes?.slice(0, 1)?.map((e, i) => {
              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={titleData} />
                      <div className="text-white p-3 lptcont">
                        <h2>{titleData}</h2>
                        <p className=" lptbg">
                          <Link
                            to={`/${langSwitcRes}/productos/${titleData}`}
                            title={e?.sort_description}
                          >
                            {e?.sort_description}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {categoryPageRes?.slice(4, 5)?.map((e, i) => {
              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;
              return (
                <React.Fragment key={i}>
                  <div
                    className="col-12 col-lg-12 col-md-12 col-xl-12 gx-0"
                    key={i}
                  >
                    <div className="graybg-bg">
                      <img
                        src={`${baseUrls}/${e?.image}`}
                        alt={titleData}
                        className="w-100"
                      />
                      <div className="text-white p-3 lptcont">
                        <h2>{titleData}</h2>
                        <p className=" lptbg">
                          <Link
                            to={`/${langSwitcRes}/productos/${titleData}`}
                            title={e?.sort_description}
                          >
                            {e?.sort_description}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {categoryPageRes?.slice(2, 3)?.map((e, i) => {
              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={titleData} />
                      <div className="text-white p-3 lptcont">
                        <h2>{titleData}</h2>
                        <p className=" lptbg">
                          <Link
                            to={`/${langSwitcRes}/productos/${titleData}`}
                            title={e?.sort_description}
                          >
                            {e?.sort_description}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {categoryPageRes?.slice(3, 4)?.map((e, i) => {
              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={titleData} />
                      <div className="text-white p-3 lptcont">
                        <h2>{titleData}</h2>
                        <p className=" lptbg">
                          <Link
                            to={`/${langSwitcRes}/productos/${titleData}`}
                            title={e?.sort_description}
                          >
                            {e?.sort_description}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
