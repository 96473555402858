import React, { useEffect, useState } from "react";
import { HiShoppingCart } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { baseUrlProduct } from "../../../../config";
import {
  addToCartApi,
  cartBtn,
  qtyDecrement,
  qtyDecrementRetailer,
  qtyIncrement,
  qtyIncrementRetailer,
} from "../../../../Redux/Action";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FadeLoader } from "react-spinners";
import "../../PLP & PDP Pages/PLP Page/ProductsPage.css";
import { useElementOnScreen } from "../../../Common/Infinite scroll/Scroll";

export default function SearchList(props) {
  const dispatch = useDispatch();

  const { currentItems, setCurrentItems, srhInputValue, data } = props;

  const [ref, isVisible, loading, setIsVisible] = useElementOnScreen(true);

  //Language Switcher

  const { langSwitcRes } = useParams();

  const [isAlertVisible, setIsAlertVisible] = useState(0);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  const resGeneral = res?.data?.group;

  const userID = res?.data?.userid;

  const cartId = useSelector((state) => state?.cart);

  const quantityRes = useSelector((state) => state?.quantity);

  const qtyRetailerRes = useSelector((state) => state?.quantityRetailer);

  //Quantity Decrement

  const decFunction = (sku) => {
    return (
      <button
        className="btn btn-black-two btn-lg"
        id="minus-btn"
        onClick={(event) => {
          event.preventDefault();
          dispatch(qtyDecrement(sku));
        }}
      >
        <i className="fa fa-minus"></i>
      </button>
    );
  };

  //Quantity Decrement

  const decFunctionB2B = (sku, minQty) => {
    return (
      <button
        className="btn btn-black-two btn-lg float-end"
        id="minus-btn"
        onClick={(event) => {
          event.preventDefault();
          dispatch(qtyDecrementRetailer(sku, minQty));
        }}
      >
        <i className="fa fa-minus"></i>
      </button>
    );
  };

  //Input value

  const inputValue = (sku) => {
    return (
      <div className="cartwrap-center">
        <input
          type="text"
          id="qty_input"
          className="form-control bg-light form-control-sm item-count"
          value={quantityRes[sku] || 1}
          min="1"
          autoComplete="off"
          disabled
        />
      </div>
    );
  };

  //Retailer input value

  const inputValueB2B = (sku, minQty) => {
    return (
      <div className="cartwrap-center float-end">
        <input
          type="text"
          id="qty_input"
          className="form-control bg-light form-control-sm item-count"
          value={qtyRetailerRes[sku] || minQty}
          min={minQty}
          autoComplete="off"
          disabled
        />
      </div>
    );
  };

  //CONTENTS

  const contentTitle = useSelector((state) => state?.productListingData);

  //Before login price

  const beforeLoginPrice = (price_before, b2c_price) => {
    if (price_before === b2c_price) {
      return (
        <>
          <div className="hprice-box">
            <p className="special-price">
              <span className="price-strike"></span>
              <span className="price">
                {langSwitcRes === "es" ? (
                  <>
                    {Number(b2c_price).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(b2c_price).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <>{Number(b2c_price).toFixed(2)}</>
                )}{" "}
                €
              </span>
            </p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="hprice-box">
            <p className="special-price">
              <span className="price-strike">
                {resGeneral == "retailer" ? (
                  <></>
                ) : resGeneral == "wholesaler" ? (
                  <></>
                ) : (
                  <>
                    {langSwitcRes === "es" ? (
                      <>
                        {Number(price_before).toLocaleString("es-ES", {
                          minimumFractionDigits: 2,
                        })}
                      </>
                    ) : langSwitcRes === "fr" ? (
                      <>
                        {Number(price_before).toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                        })}
                      </>
                    ) : (
                      <> {Number(price_before).toFixed(2)}</>
                    )}
                    €
                  </>
                )}
              </span>
              <span className="price">
                {" "}
                {langSwitcRes === "es" ? (
                  <>
                    {Number(b2c_price).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(b2c_price).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <>{Number(b2c_price).toFixed(2)}</>
                )}{" "}
                €
              </span>
            </p>
          </div>
        </>
      );
    }
  };

  //After login wholesaler price

  const afterLoginPrice = (price_before, price) => {
    if (price_before === price) {
      return (
        <>
          <div className="product-detail-wrap smallwidth">
            <div className="row align-items-center bg">
              <p className="customertype">Distributor price</p>
              <div className="reducedprice-two noprice">
                <span className="currency"></span>
              </div>
              <div className="price-two">
                {langSwitcRes === "es" ? (
                  <>
                    {Number(price).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(price).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <>{Number(price).toFixed(2)}</>
                )}
                <span className="currency"> €</span>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="product-detail-wrap smallwidth">
            <div className="row align-items-center bg">
              <p className="customertype">Distributor price</p>
              <div className="reducedprice-two">
                {langSwitcRes === "es" ? (
                  <>
                    {Number(price_before).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(price_before).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <>{Number(price_before).toFixed(2)}</>
                )}
                <span className="currency">€</span>
              </div>
              <div className="price-two">
                {langSwitcRes === "es" ? (
                  <>
                    {Number(price).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(price).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <> {Number(price).toFixed(2)}</>
                )}
                <span className="currency"> €</span>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    if (isAlertVisible) {
      setTimeout(() => {
        setIsAlertVisible(0);
      }, 3000);
    }
  }, [isAlertVisible]);

  const handleButtonClick = (pData) => {
    dispatch(cartBtn(pData));
    setIsAlertVisible(pData?.sku);
  };

  //Infinite scroll

  useEffect(() => {
    if (currentItems?.length > 0 && currentItems?.length <= data?.length) {
      const index = data?.findIndex(
        (item) => item?.sku === currentItems[currentItems?.length - 1]?.sku
      );

      const isLastIndex = index === data?.length - 1;

      setTimeout(() => {
        setCurrentItems((currentItems) => [
          ...currentItems,
          ...data?.slice(index + 1, isLastIndex ? isLastIndex : index + 10),
        ]);
        setIsVisible(false);
      }, 200);
    }
  }, [isVisible, data]);

  return (
    <>
      <Helmet>
        <title>{contentTitle?.Product_listing_block_metaTitle}</title>
      </Helmet>

      {/* Pagination */}
      {/* <div className="pagination-cont">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          disabledClassName="pagination__link--disabled"
          activeLinkClassName="active"
        />
      </div> */}

      {currentItems?.length === 0 ? (
        <>
          <div class="text-center">
            No Products are available for {srhInputValue}
          </div>
        </>
      ) : (
        ""
      )}
      {currentItems?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <div className="row search__list__block">
              <div
                className="search__list__imgblock"
                id={e?.sku}
                ref={currentItems?.length === i + 1 ? ref : null}
              >
                <Link to={`/${langSwitcRes}/ProductDetails/${e?.slug}`}>
                  <div className="grayborder shadow">
                    <div className="col-12 col-sm-12 col-md-12">
                      <div className="product-default">
                        <div className="card cardnoborder">
                          <div className="swapimg-container productimage">
                            <img
                              src={`${baseUrlProduct}/${e?.image}`}
                              alt={e?.brand}
                            />
                            {res?.status === 200 &&
                            resGeneral === "retailer" ? (
                              <>
                                {e?.pre_sale == 1 ? (
                                  <div class="pre_order_B2B en"></div>
                                ) : e?.pre_sale == 0 &&
                                  e?.min_qty_retailer > e?.b2b_qty ? (
                                  <>
                                    <div class="pre_order_B2B en"></div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : res?.status === 200 &&
                              resGeneral === "wholesaler" ? (
                              <>
                                {e?.pre_sale == 1 ? (
                                  <div class="pre_order_B2B en"></div>
                                ) : e?.pre_sale == 0 &&
                                  e?.min_qty_wholesaler > e?.b2b_qty ? (
                                  <>
                                    <div class="pre_order_B2B en"></div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}

                            {e?.outlet_status == 1 ? (
                              <>
                                {langSwitcRes == "en" ? (
                                  <>
                                    <span class="offer_label">OFFER</span>
                                  </>
                                ) : langSwitcRes == "fr" ? (
                                  <>
                                    <span class="offer_label">OFFRE</span>
                                  </>
                                ) : langSwitcRes == "it" ? (
                                  <>
                                    <span class="offer_label">OFFERTA</span>
                                  </>
                                ) : (
                                  <>
                                    <span class="offer_label">OFERTA</span>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}

                            {e?.sku === isAlertVisible && (
                              <div class="success-msg">
                                <div class="success-msg-home-pdt">
                                  <i class="fa fa-check" aria-hidden="true"></i>{" "}
                                  Added
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="search__list__decblock">
                <div className="product-details mt-3">
                  <div className="d-flex flex-row bd-highlight">
                    <div className="bd-highlight">
                      <Link to={`/${langSwitcRes}/ProductDetails/${e?.slug}`}>
                        {e?.sku}
                      </Link>
                    </div>
                    <div className="bd-highlight" title={e?.format}>
                      <Link to={`/${langSwitcRes}/ProductDetails/${e?.slug}`}>
                        {" "}
                        {e?.format}
                      </Link>
                    </div>
                    <div
                      className="bd-highlight category-list-two"
                      title={e?.brand}
                    >
                      {e?.brand}
                    </div>
                  </div>

                  {res?.status === 200 && resGeneral === "retailer" ? (
                    <>
                      {" "}
                      {beforeLoginPrice(
                        e?.price_before,
                        e?.b2c_price[0]?.b2c_price
                      )}
                    </>
                  ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                    <>
                      {" "}
                      {beforeLoginPrice(
                        e?.price_before,
                        e?.b2c_price[0]?.b2c_price
                      )}
                    </>
                  ) : (
                    <>{beforeLoginPrice(e?.price_before, e?.b2c_price)}</>
                  )}

                  {res?.status === 200 && resGeneral === "retailer" ? (
                    <>{afterLoginPrice(e?.price_before, e?.retailer_price)}</>
                  ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                    <>{afterLoginPrice(e?.price_before, e?.wholesaler_price)}</>
                  ) : (
                    ""
                  )}

                  <p className="search__list__dec">{e?.description}</p>
                  <div className="cartwrap cartwidth cartwrap__list__block">
                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>
                        <div
                          className="cartwrap-left-three cartwrap__list__cart"
                          onClick={() => handleButtonClick(e)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn float-end"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  e,
                                  res,
                                  qtyRetailerRes[e?.sku] || e?.min_qty_retailer,
                                  userID,
                                  cartId[e?.sku]?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  e?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            {e?.pre_sale == 1 ? (
                              <span>Pre-sale</span>
                            ) : e?.pre_sale == 0 &&
                              e?.min_qty_retailer > e?.b2b_qty ? (
                              <>
                                <span>Pre-sale</span>
                              </>
                            ) : (
                              <>
                                {" "}
                                <span>Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>
                        {/* quantityDecrement */}

                        {decFunctionB2B(e?.sku, e?.min_qty_retailer)}

                        {/* Input value */}

                        {inputValueB2B(e?.sku, e?.min_qty_retailer)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                e?.sku,
                                e?.min_qty_retailer,
                                e?.pre_order_qty,
                                e?.pre_sale
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        <div
                          className="cartwrap-left-three cartwrap__list__cart"
                          onClick={() => handleButtonClick(e)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn float-end"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  e,
                                  res,
                                  qtyRetailerRes[e?.sku] ||
                                    e?.min_qty_wholesaler,
                                  userID,
                                  cartId[e?.sku]?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  e?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            {e?.pre_sale == 1 ? (
                              <span>Pre-sale</span>
                            ) : e?.pre_sale == 0 &&
                              e?.min_qty_wholesaler > e?.b2b_qty ? (
                              <>
                                <span>Pre-sale</span>
                              </>
                            ) : (
                              <>
                                {" "}
                                <span>Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>
                        {/* quantityDecrement */}

                        {decFunctionB2B(e?.sku, e?.min_qty_wholesaler)}

                        {/* Input value */}

                        {inputValueB2B(e?.sku, e?.min_qty_wholesaler)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                e?.sku,
                                e?.min_qty_wholesaler,
                                e?.pre_order_qty,
                                e?.pre_sale
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className="cartwrap-left-three cartwrap__list__cart"
                          onClick={() => handleButtonClick(e)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn float-end"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  e,
                                  res,
                                  quantityRes[e?.sku] || 1,
                                  userID,
                                  cartId[e?.sku]?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  e?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />

                            <span>Add to cart</span>
                          </button>
                        </div>
                        {/* quantityDecrement */}

                        {decFunction(e?.sku)}

                        {/* Input value */}

                        {inputValue(e?.sku)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(qtyIncrement(e?.sku, e?.b2c_qty));
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}

      <center>
        {data?.length != currentItems?.length ? (
          <>
            {" "}
            {loading && (
              <FadeLoader color={"#0da5e8 "} loading={loading} size={20} />
            )}
          </>
        ) : (
          <></>
        )}
      </center>
    </>
  );
}
