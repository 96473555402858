import React from "react";
import { useSelector } from "react-redux";
import "../../../../index.css";
import { baseUrls } from "../../../../config/index";
import { Link } from "react-router-dom";

export const OurBrands = () => {
  //Homepage Products Response

  const homepageRes = useSelector((state) => state?.HomePageData?.brand_block);

  //Homepage Content Response

  const homepageConRes = useSelector(
    (state) => state?.HomePageData?.brand_block_content
  );

  //Language Switcher

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  return (
    <>
      <div className="container ourbrands-parent">
        {langSwitcRes &&
          homepageConRes
            ?.filter((e) => e?.language === langSwitcRes)
            ?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="section-heading mt-4">{e?.tittle}</div>
                  <p className="section-paragraph">
                    <span
                      dangerouslySetInnerHTML={{ __html: e?.content }}
                    ></span>
                  </p>
                </React.Fragment>
              );
            })}
        {homepageConRes?.find((e) => {
          return e?.status === "enable";
        }) ? (
          <>
            <div className="ourbrands-cont mt-3 row">
              {homepageRes?.map((e, i) => {
                const slugData =
                  langSwitcRes === "en"
                    ? e?.slugname_en
                    : langSwitcRes === "es"
                    ? e?.slugname_es
                    : langSwitcRes === "fr"
                    ? e?.slugname_fr
                    : e?.slugname_it;

                const titleData =
                  langSwitcRes === "en"
                    ? e?.title_en
                    : langSwitcRes === "es"
                    ? e?.title_es
                    : langSwitcRes === "fr"
                    ? e?.title_fr
                    : e?.title_it;
                return (
                  <React.Fragment key={i}>
                    <div className="col-md-4 ourbrands">
                      <Link
                        to={`/${langSwitcRes}/brand/${slugData}`}
                        title={titleData}
                      >
                        <div className="brand-parent">
                          <img
                            src={`${baseUrls}/${e?.image}`}
                            alt={e?.page_title}
                            title={titleData}
                          />
                          <div className="brand-child">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
