import React, { useEffect } from "react";
import { BrandBanner } from "./Brand Components/BrandBanner";
import { BrandRegister } from "./Brand Components/BrandRegister";
import { BrandFullWidth2 } from "./Brand Components/BrandFullWidth2";
import { BrandLicense } from "./Brand Components/BrandLicense";
import { BrandLists } from "./Brand Components/BrandLists";
import { BrandFullWidth3 } from "./Brand Components/BrandFullWidth3";
import { BrandSupplier } from "./Brand Components/BrandSupplier";
import { Footer } from "../../Common/Footer/Footer";
import { BrandProducts } from "./Brand Components/BrandProducts";
import { BrandConditions2 } from "./Brand Components/BrandConditions2";
import { BrandSpecialConditions } from "./Brand Components/BrandSpecialConditions";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Header } from "../../Common/Header/Header";
export const BrandPages = () => {
  //Login Response

  const res = useSelector((state) => state?.loginData);

  const { slugData } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header pathData={`brand/${slugData}`} />
      <BrandBanner />
      <BrandLicense />
      <BrandRegister />
      <BrandProducts />
      <BrandFullWidth2 />
      <BrandLists />
      <BrandConditions2 />
      <BrandFullWidth3 />
      <BrandSpecialConditions />
      <BrandSupplier />
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
