import React from "react";
import "../../../../index.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

export const CategoryFAQ = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Language Switcher

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  //Category content Response

  const categoryConRes = useSelector(
    (state) => state?.categoryData?.faq_block_content
  );

  //Category Response

  const categorypageRes = useSelector(
    (state) => state?.categoryData?.faq_block
  );

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  return (
    <>
      <div className="product-categories-cont">
        <div className="faq-cont-two">
          <div className="container faq-width">
            {langSwitcRes &&
              categoryConRes
                ?.filter((e) => e?.language === langSwitcRes)
                ?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="section-heading mt-4">{e?.tittle}</div>
                      <p
                        className="section-paragraph"
                        dangerouslySetInnerHTML={{ __html: e?.content }}
                      ></p>
                    </React.Fragment>
                  );
                })}
            {langSwitcRes &&
              categorypageRes
                ?.filter((e) => e?.language === langSwitcRes)
                ?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="accordion-cont">
                        <Accordion
                          expanded={expanded === `panel${i}`}
                          onChange={handleChange(`panel${i}`)}
                        >
                          <AccordionSummary
                            expandIcon={<CustomExpandIcon />}
                            aria-controls={`panel${i}bh-content`}
                            id={`panel${i}bh-content`}
                          >
                            <Typography className="faqheading">
                              {e?.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              dangerouslySetInnerHTML={{ __html: e?.answer }}
                            ></Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </React.Fragment>
                  );
                })}
          </div>
        </div>
      </div>
      <div className="product-categories-bottom"></div>
    </>
  );
};
