import React from "react";
import { BiTrash } from "react-icons/bi";
import { FiRefreshCcw } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Td, Tr } from "react-super-responsive-table";
import { baseUrlProduct } from "../../../../config";
import {
  addToCartApi,
  qtyDecrement,
  qtyDecrementRetailer,
  qtyIncrement,
  qtyIncrementRetailer,
  removeApiAction,
} from "../../../../Redux/Action";

export const PreOrder = (props) => {
  const dispatch = useDispatch();

  const { preorderData, langSwitcRes } = props;

  const preorderProducts = preorderData?.preorderData;

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  const resGeneral = res?.data?.group;

  const quantityRes = useSelector((state) => state?.quantity);

  const qtyRetailerRes = useSelector((state) => state?.quantityRetailer);

  const userID = res?.data?.userid;

  const cartId = useSelector((state) => state?.cart);

  return (
    <>
      {Object.keys(preorderProducts)?.length > 0 ? (
        <>
          <Tr>
            <Td valign="top" colspan="9" className="text-center redhighlight">
              PRE-ORDER
            </Td>
          </Tr>
        </>
      ) : (
        ""
      )}

      {Object.keys(preorderProducts)?.map((e, i) => {
        const formatData = preorderProducts[e]?.productnames
          ?.filter((e) => e?.lang == langSwitcRes)
          ?.map((Pname, i) => {
            return <React.Fragment key={i}>{Pname?.name}</React.Fragment>;
          });
        return (
          <React.Fragment key={i}>
            <Tr>
              <Td>
                <a
                  href={`/${langSwitcRes}/ProductDetails/${preorderProducts[e]?.slug}`}
                >
                  <img
                    src={`${baseUrlProduct}/${preorderProducts[e]?.image}`}
                    alt="text"
                    className="thumbnail checkoutImage"
                  />
                </a>
              </Td>
              <Td>
                <div className="product-name">
                  <a
                    href={`/${langSwitcRes}/ProductDetails/${preorderProducts[e]?.slug}`}
                  >
                    {formatData}
                  </a>
                </div>
                <div className="item-error">
                  * Pre order: {preorderProducts[e]?.qty} Qty{" "}
                  {preorderProducts[e]?.preorder_date}
                </div>
              </Td>
              <Td>{preorderProducts[e]?.product_sku}</Td>
              <Td>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(preorderProducts[e]?.before_price).toLocaleString(
                      "es-ES",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(preorderProducts[e]?.before_price).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : (
                  <>{Number(preorderProducts[e]?.before_price).toFixed(2)}</>
                )}
                <span> €</span>
              </Td>
              <Td>
                {preorderProducts[e]?.discount == "undefined" ? (
                  <>0 %</>
                ) : (
                  <>{preorderProducts[e]?.discount} %</>
                )}
              </Td>
              <Td>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(preorderProducts[e]?.price).toLocaleString(
                      "es-ES",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(preorderProducts[e]?.price).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : (
                  <>{Number(preorderProducts[e]?.price).toFixed(2)}</>
                )}
                <span> €</span>
              </Td>
              <Td className="pro-qty-cont">
                {res?.status === 200 && resGeneral === "retailer" ? (
                  <>
                    <div className="pro-qty">
                      <div
                        className="inc qtybtn"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            qtyIncrementRetailer(
                              preorderProducts[e]?.product_sku,
                              preorderProducts[e]?.min_qty_retailer,
                              preorderProducts[e]?.b2b_qty,
                              preorderProducts[e]?.pre_order_qty,
                              preorderProducts[e]?.pre_sale
                            )
                          );
                        }}
                      >
                        +
                      </div>

                      <input
                        type="text"
                        id="qty_input"
                        className="form-control-two form-control-sm"
                        min="1"
                        value={
                          qtyRetailerRes[preorderProducts[e]?.product_sku] ||
                          preorderProducts[e]?.min_qty_retailer
                        }
                      />

                      <div className="cartwrap-right">
                        <div
                          className="dec qtybtn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyDecrementRetailer(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.min_qty_retailer
                              )
                            );
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </>
                ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                  <>
                    <div className="pro-qty">
                      <div
                        className="inc qtybtn"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            qtyIncrementRetailer(
                              preorderProducts[e]?.product_sku,
                              preorderProducts[e]?.min_qty_wholesaler,
                              preorderProducts[e]?.b2b_qty,
                              preorderProducts[e]?.pre_order_qty,
                              preorderProducts[e]?.pre_sale
                            )
                          );
                        }}
                      >
                        +
                      </div>

                      <input
                        type="text"
                        id="qty_input"
                        className="form-control-two form-control-sm"
                        min="1"
                        value={
                          qtyRetailerRes[preorderProducts[e]?.product_sku] ||
                          preorderProducts[e]?.min_qty_wholesaler
                        }
                      />

                      <div className="cartwrap-right">
                        <div
                          className="dec qtybtn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyDecrementRetailer(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.min_qty_wholesaler
                              )
                            );
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pro-qty">
                      <div
                        className="inc qtybtn"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            qtyIncrement(
                              preorderProducts[e]?.product_sku,
                              preorderProducts[e]?.b2c_qty
                            )
                          );
                        }}
                      >
                        +
                      </div>

                      <input
                        type="text"
                        id="qty_input"
                        className="form-control-two form-control-sm"
                        min="1"
                        value={
                          quantityRes[preorderProducts[e]?.product_sku] || 1
                        }
                      />

                      <div className="cartwrap-right">
                        <div
                          className="dec qtybtn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyDecrement(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.b2c_qty
                              )
                            );
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Td>
              <Td>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(
                      preorderProducts[e]?.qty * preorderProducts[e]?.price
                    ).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(
                      preorderProducts[e]?.qty * preorderProducts[e]?.price
                    ).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    {Number(
                      preorderProducts[e]?.qty * preorderProducts[e]?.price
                    ).toFixed(2)}{" "}
                  </>
                )}
                <span> €</span>
              </Td>
              <Td className="text-center">
                <Link to="">
                  <BiTrash
                    size={24}
                    onClick={(event) => {
                      event.preventDefault();
                      dispatch(
                        removeApiAction(
                          preorderProducts[e]?.cart_id,
                          preorderProducts[e]?.product_sku
                        )
                      );
                    }}
                  />
                </Link>

                <Link to="">
                  {res?.status === 200 && resGeneral === "retailer" ? (
                    <>
                      <FiRefreshCcw
                        size={20}
                        className="mx-2"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            addToCartApi(
                              preorderProducts[e],
                              res,
                              qtyRetailerRes[
                                preorderProducts[e]?.product_sku
                              ] || preorderProducts[e]?.min_qty_retailer,
                              userID,
                              cartId[preorderProducts?.product_sku]?.cart_id,
                              priceRes,
                              groupRes,
                              discountRes,
                              preorderProducts[e]?.preorder_date,
                              langSwitcRes
                              // e?.discount,
                            )
                          );
                        }}
                      />
                    </>
                  ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                    <>
                      <FiRefreshCcw
                        size={20}
                        className="mx-2"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            addToCartApi(
                              preorderProducts[e],
                              res,
                              qtyRetailerRes[
                                preorderProducts[e]?.product_sku
                              ] || preorderProducts[e]?.min_qty_wholesaler,
                              userID,
                              cartId[preorderProducts?.product_sku]?.cart_id,
                              priceRes,
                              groupRes,
                              discountRes,
                              preorderProducts[e]?.preorder_date,
                              langSwitcRes
                              // e?.discount,
                            )
                          );
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FiRefreshCcw
                        size={20}
                        className="mx-2"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            addToCartApi(
                              preorderProducts[e],
                              res,
                              quantityRes[preorderProducts[e]?.product_sku] ||
                                1,
                              userID,
                              cartId[preorderProducts?.product_sku]?.cart_id,
                              priceRes,
                              groupRes,
                              discountRes,
                              preorderProducts[e]?.preorder_date,
                              langSwitcRes
                              // e?.discount,
                            )
                          );
                        }}
                      />
                    </>
                  )}
                </Link>
              </Td>
            </Tr>
          </React.Fragment>
        );
      })}
    </>
  );
};
