import React, { useEffect, useState } from "react";
import { HiShoppingCart } from "react-icons/hi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { baseUrlProduct } from "../../../../../config";
import {
  addToCartApi,
  cartBtn,
  qtyDecrement,
  qtyDecrementRetailer,
  qtyIncrement,
  qtyIncrementRetailer,
} from "../../../../../Redux/Action";
import { Link } from "react-router-dom";

export const ProductOutstanding = () => {
  const dispatch = useDispatch();

  const [isAlertVisible, setIsAlertVisible] = useState(0);

  //Language Switcher

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  //Product details Response

  const productDetRes = useSelector((state) => state?.productDetailsData);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  const resGeneral = res?.data?.group;

  const userID = res?.data?.userid;

  const cartId = useSelector((state) => state?.cart);

  const quantityRes = useSelector((state) => state?.quantity);

  const qtyRetailerRes = useSelector((state) => state?.quantityRetailer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productDetRes]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  //Input value

  const inputValue = (sku) => {
    return (
      <div className="cartwrap-center float-end">
        <input
          type="text"
          id="qty_input"
          className="form-control bg-light form-control-sm item-count"
          value={quantityRes[sku] || 1}
          min="1"
          autoComplete="off"
          disabled
        />
      </div>
    );
  };

  //Retailer input value

  const inputValueB2B = (sku, min_qty_retailer) => {
    return (
      <div className="cartwrap-center float-end">
        <input
          type="text"
          id="qty_input"
          className="form-control bg-light form-control-sm item-count"
          value={qtyRetailerRes[sku] || min_qty_retailer}
          min={min_qty_retailer}
          autoComplete="off"
          disabled
        />
      </div>
    );
  };

  //Quantity Decrement

  const decFunction = (sku) => {
    return (
      <button
        className="btn btn-black-two btn-lg"
        id="minus-btn"
        onClick={(event) => {
          event.preventDefault();
          dispatch(qtyDecrement(sku));
        }}
      >
        <i className="fa fa-minus"></i>
      </button>
    );
  };

  //Quantity Decrement

  const decFunctionB2B = (sku, minQty) => {
    return (
      <button
        className="btn btn-black-two btn-lg"
        id="minus-btn"
        onClick={(event) => {
          event.preventDefault();
          dispatch(qtyDecrementRetailer(sku, minQty));
        }}
      >
        <i className="fa fa-minus"></i>
      </button>
    );
  };

  //Before login price

  const beforeLoginPrice = (price_before, b2c_price) => {
    if (price_before === b2c_price) {
      return (
        <>
          <div className="reducedprice">
            <span className="currency"> </span>
          </div>
          <div className="price">
            {langSwitcRes === "es" ? (
              <>
                {Number(b2c_price).toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : langSwitcRes === "fr" ? (
              <>
                {Number(b2c_price).toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : (
              <> {Number(b2c_price).toFixed(2)}</>
            )}
            <span className="currency">€</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="reducedprice">
            {resGeneral == "general" ? (
              <>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(price_before).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(price_before).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <> {Number(price_before).toFixed(2)}</>
                )}
                <span className="currency"> €</span>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="price">
            {langSwitcRes === "es" ? (
              <>
                {Number(b2c_price).toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : langSwitcRes === "fr" ? (
              <>
                {Number(b2c_price).toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
              </>
            ) : (
              <>{Number(b2c_price).toFixed(2)}</>
            )}
            <span className="currency">€</span>
          </div>
        </>
      );
    }
  };

  //After login wholesaler price

  const afterLoginPrice = (price_before, price) => {
    if (price_before === price) {
      return (
        <>
          <div className="row align-items-center bg">
            <p className="customertype">Distributor price</p>
            <div className="reducedprice-two noprice">
              <span className="currency"></span>
            </div>
            <div className="price-two">
              {langSwitcRes === "es" ? (
                <>
                  {Number(price).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : langSwitcRes === "fr" ? (
                <>
                  {Number(price).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : (
                <> {Number(price).toFixed(2)}</>
              )}

              <span className="currency"> €</span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="row align-items-center bg">
            <p className="customertype">Distributor price</p>
            <div className="reducedprice-two">
              {langSwitcRes === "es" ? (
                <>
                  {Number(price_before).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : langSwitcRes === "fr" ? (
                <>
                  {Number(price_before).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : (
                <> {Number(price_before).toFixed(2)}</>
              )}
              <span className="currency">€</span>
            </div>
            <div className="price-two">
              {langSwitcRes === "es" ? (
                <>
                  {Number(price).toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : langSwitcRes === "fr" ? (
                <>
                  {Number(price).toLocaleString("fr-FR", {
                    minimumFractionDigits: 2,
                  })}
                </>
              ) : (
                <> {Number(price).toFixed(2)}</>
              )}
              <span className="currency"> €</span>
            </div>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    if (isAlertVisible) {
      setTimeout(() => {
        setIsAlertVisible(0);
      }, 3000);
    }
  }, [isAlertVisible]);

  const handleButtonClick = (pData) => {
    dispatch(cartBtn(pData));
    setIsAlertVisible(pData?.sku);
  };

  return (
    <>
      <div className="container">
        {productDetRes?.similar_products?.length > 0 && (
          <div className="heading-ab-underlines">Related Products</div>
        )}
        <Slider {...settings} className="container mt-2 text-center mb-0">
          {productDetRes?.similar_products?.map((e, i) => {
            return (
              <React.Fragment key={i}>
                <div className="card shadow hover-zoom cardmargins-bg">
                  <div className="productimage">
                    <Link to={`/${langSwitcRes}/ProductDetails/${e?.slug}`}>
                      <img
                        src={`${baseUrlProduct}/${e?.image}`}
                        alt="Backpack"
                      />
                    </Link>
                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>
                        {e?.pre_sale == 1 ? (
                          <div class="pre_order_B2B en"></div>
                        ) : e?.pre_sale == 0 &&
                          e?.min_qty_retailer > e?.b2b_qty ? (
                          <>
                            <div class="pre_order_B2B en"></div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        {e?.pre_sale == 1 ? (
                          <div class="pre_order_B2B en"></div>
                        ) : e?.pre_sale == 0 &&
                          e?.min_qty_wholesaler > e?.b2b_qty ? (
                          <>
                            <div class="pre_order_B2B en"></div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {e?.outlet_status == 1 ? (
                      <>
                        {langSwitcRes == "en" ? (
                          <>
                            <span class="offer_label">OFFER</span>
                          </>
                        ) : langSwitcRes == "fr" ? (
                          <>
                            <span class="offer_label">OFFRE</span>
                          </>
                        ) : langSwitcRes == "it" ? (
                          <>
                            <span class="offer_label">OFFERTA</span>
                          </>
                        ) : (
                          <>
                            <span class="offer_label">OFERTA</span>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {e?.sku === isAlertVisible && (
                      <div class="success-msg">
                        <div class="success-msg-home-pdt">
                          <i class="fa fa-check" aria-hidden="true"></i> Added
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="cartwrap"
                    style={{
                      borderLeft: "5px solid #fff",
                      borderRight: "5px solid #fff",
                    }}
                  >
                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>
                        <div
                          className="cartwrap-left-six"
                          onClick={() => handleButtonClick(e)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  e,
                                  res,
                                  qtyRetailerRes[e?.sku] || e?.min_qty_retailer,
                                  userID,
                                  cartId[e?.sku]?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  e?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            {e?.pre_sale == 1 ? (
                              <span>Pre-sale</span>
                            ) : e?.pre_sale == 0 &&
                              e?.min_qty_retailer > e?.b2b_qty ? (
                              <>
                                <span>Pre-sale</span>
                              </>
                            ) : (
                              <>
                                {" "}
                                <span>Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>
                        {/* quantityDecrement */}

                        <div className="cartwrap-right">
                          {decFunctionB2B(e?.sku, e?.min_qty_retailer)}
                        </div>

                        {/* Input value */}

                        {inputValueB2B(e?.sku, e?.min_qty_retailer)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                e?.sku,
                                e?.min_qty_retailer,
                                e?.b2b_qty,
                                e?.pre_order_qty
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        <div
                          className="cartwrap-left-six"
                          onClick={() => handleButtonClick(e)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  e,
                                  res,
                                  qtyRetailerRes[e?.sku] ||
                                    e?.min_qty_wholesaler,
                                  userID,
                                  cartId[e?.sku]?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  e?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            {e?.pre_sale == 1 ? (
                              <span>Pre-sale</span>
                            ) : e?.pre_sale == 0 &&
                              e?.min_qty_wholesaler > e?.b2b_qty ? (
                              <>
                                <span>Pre-sale</span>
                              </>
                            ) : (
                              <>
                                {" "}
                                <span>Add to cart</span>
                              </>
                            )}
                          </button>
                        </div>
                        {/* quantityDecrement */}

                        <div className="cartwrap-right">
                          {decFunctionB2B(e?.sku, e?.min_qty_wholesaler)}
                        </div>

                        {/* Input value */}

                        {inputValueB2B(e?.sku, e?.min_qty_wholesaler)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                e?.sku,
                                e?.min_qty_wholesaler,
                                e?.b2b_qty,
                                e?.pre_order_qty
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className="cartwrap-left-six"
                          onClick={() => handleButtonClick(e)}
                        >
                          <button
                            type="button"
                            title="Add to Cart"
                            className="btn btn-primary border-0 cart-btn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  e,
                                  res,
                                  quantityRes[e?.sku] || 1,
                                  userID,
                                  cartId[e?.sku]?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  e?.pre_order_desc,
                                  langSwitcRes
                                )
                              );
                            }}
                          >
                            <HiShoppingCart className="HiShoppingCart-icon" />
                            <span>Add to cart</span>
                          </button>
                        </div>
                        {/* quantityDecrement */}

                        <div className="cartwrap-right">
                          {decFunction(e?.sku)}
                        </div>

                        {/* Input value */}

                        {inputValue(e?.sku)}

                        <button
                          className="btn btn-black-two btn-lg float-end"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(qtyIncrement(e?.sku, e?.b2c_qty));
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </>
                    )}
                  </div>

                  <div className="product-detail-wrap">
                    <div className="row">
                      <p className="desc" title={e?.format}>
                        {e?.format}
                      </p>
                      <div className="skuid">
                        <Link
                          to={`/${langSwitcRes}/ProductDetails/${e?.slug}`}
                          className="text-decoration-none"
                        >
                          {e?.sku}
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="title fw-bold" title={e?.brand}>
                        {e?.brand}
                      </div>
                      {res?.status === 200 && resGeneral === "retailer" ? (
                        <>
                          {" "}
                          {beforeLoginPrice(
                            e?.price_before,
                            e?.b2c_price?.b2c_price
                          )}
                        </>
                      ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                        <>
                          {" "}
                          {beforeLoginPrice(
                            e?.price_before,
                            e?.b2c_price?.b2c_price
                          )}
                        </>
                      ) : (
                        <>{beforeLoginPrice(e?.price_before, e?.b2c_price)}</>
                      )}
                    </div>

                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>{afterLoginPrice(e?.price_before, e?.retailer_price)}</>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        {afterLoginPrice(e?.price_before, e?.wholesaler_price)}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
