import React from "react";
import { BiTrash } from "react-icons/bi";
import { FiRefreshCcw } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Td, Tr } from "react-super-responsive-table";
import { baseUrlProduct } from "../../../../config";
import {
  addToCartApi,
  qtyDecrement,
  qtyDecrementRetailer,
  qtyIncrement,
  qtyIncrementRetailer,
  removeApiAction,
} from "../../../../Redux/Action";

export const ImmediateService = (props) => {
  const dispatch = useDispatch();

  const { langSwitcRes, immediateServiceData } = props;

  const immediateServiceProd = immediateServiceData?.immediateServiceData;

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const resGeneral = res?.data?.group;

  const quantityRes = useSelector((state) => state?.quantity);

  const qtyRetailerRes = useSelector((state) => state?.quantityRetailer);

  const userID = res?.data?.userid;

  const cartId = useSelector((state) => state?.cart);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  return (
    <>
      {Object.keys(immediateServiceProd).length > 0 ? (
        <>
          <Tr>
            <Td valign="top" colspan="9" className="text-center greenhighlight">
              DATE OF SERVICE: IMMEDIATE SERVICE
            </Td>
          </Tr>
        </>
      ) : (
        ""
      )}

      {Object.keys(immediateServiceProd)?.map((e, i) => {
        const formatData = immediateServiceProd[e]?.productnames
          ?.filter((e) => e?.lang == langSwitcRes)
          ?.map((Pname, i) => {
            return <React.Fragment key={i}>{Pname?.name}</React.Fragment>;
          });
        return (
          <React.Fragment key={i}>
            <>
              <Tr>
                <Td>
                  <a
                    href={`/${langSwitcRes}/ProductDetails/${immediateServiceProd[e]?.slug}`}
                  >
                    <img
                      src={`${baseUrlProduct}/${immediateServiceProd[e]?.image}`}
                      alt="text"
                      className="thumbnail checkoutImage"
                    />
                  </a>
                </Td>
                <Td>
                  <div className="product-name">
                    <a
                      href={`/${langSwitcRes}/ProductDetails/${immediateServiceProd[e]?.slug}`}
                    >
                      {formatData}
                    </a>
                  </div>
                  <div className="item-success">
                    * Immediate Service: {immediateServiceProd[e]?.qty} Qty{" "}
                    {immediateServiceProd[e]?.preorder_date}
                  </div>
                </Td>
                <Td>{immediateServiceProd[e]?.product_sku}</Td>
                <Td>
                  {langSwitcRes === "es" ? (
                    <>
                      {Number(
                        immediateServiceProd[e]?.before_price
                      ).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })}
                    </>
                  ) : langSwitcRes === "fr" ? (
                    <>
                      {Number(
                        immediateServiceProd[e]?.before_price
                      ).toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                      })}
                    </>
                  ) : (
                    <>
                      {Number(immediateServiceProd[e]?.before_price).toFixed(2)}
                    </>
                  )}
                  <span> €</span>
                </Td>
                <Td>
                  {immediateServiceProd[e]?.discount == "undefined" ? (
                    <>0 %</>
                  ) : (
                    <>{immediateServiceProd[e]?.discount} %</>
                  )}
                </Td>
                <Td>
                  {langSwitcRes === "es" ? (
                    <>
                      {Number(immediateServiceProd[e]?.price).toLocaleString(
                        "es-ES",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </>
                  ) : langSwitcRes === "fr" ? (
                    <>
                      {Number(immediateServiceProd[e]?.price).toLocaleString(
                        "fr-FR",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </>
                  ) : (
                    <>{Number(immediateServiceProd[e]?.price).toFixed(2)}</>
                  )}
                  <span> €</span>
                </Td>
                <Td className="pro-qty-cont">
                  {res?.status === 200 && resGeneral === "retailer" ? (
                    <>
                      <div className="pro-qty">
                        <div
                          className="inc qtybtn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                immediateServiceProd[e]?.product_sku,
                                immediateServiceProd[e]?.min_qty_retailer,
                                immediateServiceProd[e]?.b2b_qty,
                                immediateServiceProd[e]?.pre_order_qty,
                                immediateServiceProd[e]?.pre_sale
                              )
                            );
                          }}
                        >
                          +
                        </div>

                        <input
                          type="text"
                          id="qty_input"
                          className="form-control-two form-control-sm"
                          min="1"
                          // value={immediateServiceProd[e]?.qty}
                          value={
                            qtyRetailerRes[
                              immediateServiceProd[e]?.product_sku
                            ] || immediateServiceProd[e]?.min_qty_retailer
                          }
                        />

                        <div className="cartwrap-right">
                          <div
                            className="dec qtybtn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                qtyDecrementRetailer(
                                  immediateServiceProd[e]?.product_sku,
                                  immediateServiceProd[e]?.min_qty_retailer
                                )
                              );
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </>
                  ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                    <>
                      <div className="pro-qty">
                        <div
                          className="inc qtybtn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                immediateServiceProd[e]?.product_sku,
                                immediateServiceProd[e]?.min_qty_wholesaler,
                                immediateServiceProd[e]?.b2b_qty,
                                immediateServiceProd[e]?.pre_order_qty,
                                immediateServiceProd[e]?.pre_sale
                              )
                            );
                          }}
                        >
                          +
                        </div>

                        <input
                          type="text"
                          id="qty_input"
                          className="form-control-two form-control-sm"
                          min="1"
                          value={
                            qtyRetailerRes[
                              immediateServiceProd[e]?.product_sku
                            ] || immediateServiceProd[e]?.min_qty_wholesaler
                          }
                        />

                        <div className="cartwrap-right">
                          <div
                            className="dec qtybtn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                qtyDecrementRetailer(
                                  immediateServiceProd[e]?.product_sku,
                                  immediateServiceProd[e]?.min_qty_wholesaler
                                )
                              );
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pro-qty">
                        <div
                          className="inc qtybtn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrement(
                                immediateServiceProd[e]?.product_sku,
                                immediateServiceProd[e]?.b2c_qty
                              )
                            );
                          }}
                        >
                          +
                        </div>

                        <input
                          type="text"
                          id="qty_input"
                          className="form-control-two form-control-sm"
                          min="1"
                          value={
                            quantityRes[immediateServiceProd[e]?.product_sku] ||
                            1
                          }
                        />

                        <div className="cartwrap-right">
                          <div
                            className="dec qtybtn"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                qtyDecrement(
                                  immediateServiceProd[e]?.product_sku,
                                  immediateServiceProd[e]?.b2c_qty
                                )
                              );
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Td>
                <Td style={{ clear: "both" }}>
                  {langSwitcRes === "es" ? (
                    <>
                      {Number(
                        immediateServiceProd[e]?.qty *
                          immediateServiceProd[e]?.price
                      ).toLocaleString("es-ES", {
                        minimumFractionDigits: 2,
                      })}
                    </>
                  ) : langSwitcRes === "fr" ? (
                    <>
                      {Number(
                        immediateServiceProd[e]?.qty *
                          immediateServiceProd[e]?.price
                      ).toLocaleString("fr-FR", {
                        minimumFractionDigits: 2,
                      })}
                    </>
                  ) : (
                    <>
                      {" "}
                      {Number(
                        immediateServiceProd[e]?.qty *
                          immediateServiceProd[e]?.price
                      ).toFixed(2)}{" "}
                    </>
                  )}
                  <span> €</span>
                </Td>
                <Td className="text-center">
                  <Link to="">
                    <BiTrash
                      size={24}
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch(
                          removeApiAction(
                            immediateServiceProd[e]?.cart_id,
                            immediateServiceProd[e]?.product_sku
                          )
                        );
                      }}
                    />
                  </Link>

                  <Link to="">
                    {res?.status === 200 && resGeneral === "retailer" ? (
                      <>
                        <FiRefreshCcw
                          size={20}
                          className="mx-2"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              addToCartApi(
                                immediateServiceProd[e],
                                res,
                                qtyRetailerRes[
                                  immediateServiceProd[e]?.product_sku
                                ] || immediateServiceProd[e]?.min_qty_retailer,
                                userID,
                                cartId[immediateServiceProd?.product_sku]
                                  ?.cart_id,
                                priceRes,
                                groupRes,
                                discountRes,
                                immediateServiceProd[e]?.preorder_date,
                                // e?.discount,
                                langSwitcRes
                              )
                            );
                          }}
                        />
                      </>
                    ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                      <>
                        <FiRefreshCcw
                          size={20}
                          className="mx-2"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              addToCartApi(
                                immediateServiceProd[e],
                                res,
                                qtyRetailerRes[
                                  immediateServiceProd[e]?.product_sku
                                ] ||
                                  immediateServiceProd[e]?.min_qty_wholesaler,
                                userID,
                                cartId[immediateServiceProd?.product_sku]
                                  ?.cart_id,
                                priceRes,
                                groupRes,
                                discountRes,
                                immediateServiceProd[e]?.preorder_date,
                                langSwitcRes
                                // e?.discount,
                              )
                            );
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <FiRefreshCcw
                          size={20}
                          className="mx-2"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              addToCartApi(
                                immediateServiceProd[e],
                                res,
                                quantityRes[
                                  immediateServiceProd[e]?.product_sku
                                ] || 1,
                                userID,
                                cartId[immediateServiceProd?.product_sku]
                                  ?.cart_id,
                                priceRes,
                                groupRes,
                                discountRes,
                                immediateServiceProd[e]?.preorder_date,
                                langSwitcRes
                                // e?.discount,
                              )
                            );
                          }}
                        />
                      </>
                    )}
                  </Link>
                </Td>
              </Tr>
            </>
          </React.Fragment>
        );
      })}
    </>
  );
};
