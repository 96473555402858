import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../Common/Footer/Footer";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { securePaymentAction } from "../../../Redux/Action";
import { useParams } from "react-router";
import { Header } from "../../Common/Header/Header";

export const SecurePayment = () => {
  const dispatch = useDispatch();

  //LangSwitcher

  const { langSwitcRes } = useParams();

  useEffect(() => {
    dispatch(securePaymentAction(langSwitcRes));
  }, [langSwitcRes, dispatch]);

  const securePaymentRes = useSelector(
    (state) => state?.securePaymentData?.data?.details
  );

  //Login Response

  const res = useSelector((state) => state?.loginData);

  return (
    <>
      <Header pathData="secure-payment" />
      {securePaymentRes?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>;
          </React.Fragment>
        );
      })}
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
