import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import BasicBreadcrumbs from "./BasicBreadcrubms";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "./ProductGrid";
import ProductList from "./ProductList";
import {
  productFiltersAction,
  productListingAction,
  productSortAction,
  // productTypeListAction,
} from "../../../../../Redux/Action";
import { baseUrlColor } from "../../../../../config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ProductBanner } from "./ProductBanner";
import PropagateLoader from "react-spinners/PropagateLoader";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const ProductsCopy = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [showList, setShowList] = useState(false);

  const [selectAcc, setSelectAcc] = useState("");

  const [AccorBrandType, setAccorBrandType] = useState({});

  const [loading, setLoading] = useState(true);

  const [secLoader, setSecLoader] = useState(false);

  //Brand,Product type filter

  const [brandTypeValue, setBrandTypeValue] = useState([]);
  const [productTypeValue, setProductTypeValue] = useState([]);
  const [colorFilter, setColorFilter] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);

  //Pagination

  const [itemOffset, setItemOffset] = useState(1);

  //Price filters

  const [priceFilter, setPriceFilter] = useState("ztoa");

  const [resetProduct, setResetProduct] = useState("");

  const [selectedValue, setSelectedValue] = useState([]);

  const [productSelect, setProductSelect] = useState("");
  const [brandSelect, setBrandSelect] = useState("");

  const [subCatName, setSubCatName] = useState([]);

  const dispatch = useDispatch();

  const { title, page_title } = useParams();

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const [currentHeight, setcurrentHeight] = useState();

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 700) {
        setcurrentHeight(window.pageYOffset);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const [tempPageCount, setTempPageCount] = useState(1);

  //ProductListing API Response
  const [apiStatus, setApiStatus] = useState(false);

  //ProductListing API Response

  useEffect(() => {
    setApiStatus(true);

    dispatch(
      productListingAction(
        title,
        page_title,
        res,
        langSwitcRes,
        itemOffset,
        priceFilter,
        tempPageCount
      )
    );
  }, [
    title,
    page_title,
    res,
    langSwitcRes,
    itemOffset,
    priceFilter,
    tempPageCount,
  ]);

  const [pagenumber, setpagenumber] = useState([]);

  const contentTitle = useSelector((state) => state?.productListingData);

  useEffect(() => {
    let pagecount = contentTitle?.no_pages;
    const triggerHeight = 1000;
    if (itemOffset !== 1) return;

    // let result = pagenumber?.find(pn => pagenumber?.length + 1 === pn)

    if (
      pagenumber?.length >= 0 &&
      currentHeight > triggerHeight * pagenumber?.length + 1
    ) {
      setTempPageCount(tempPageCount + 1);
      setpagenumber((prevState) => [...prevState, tempPageCount + 1]);
    } else if (pagenumber?.length === 0) {
      if (currentHeight > triggerHeight && pagecount <= 1) {
        setTempPageCount(2);
        setpagenumber((prevState) => [...prevState, 2]);
      }
    }
  }, [currentHeight]);

  const productListingRes = useSelector(
    (state) => state?.productListingData?.Product_list
  );

  const catagoryBlockRes = useSelector(
    (state) => state?.productListingData?.Category_block
  );

  const productTypeData = useSelector(
    (state) => state?.productListingData?.product_type_list
  );

  const brandTypeData = useSelector(
    (state) => state?.productListingData?.brand_type_list
  );

  //Brand and Product type Listing

  const brandListRes = useSelector(
    (state) => state?.brandListData?.Brand_Type_listing
  );

  const productTypeListRes = useSelector(
    (state) => state?.productTypeListData?.Product_Type_listing
  );

  //Products filter API Response

  const totalFiltersCount = useSelector(
    (state) => state?.productFiltersData?.product_count
  );

  const productFilterRes = useSelector(
    (state) => state?.productFiltersData?.product_list
  );

  const productSortRes = useSelector(
    (state) => state?.productSortData?.product_list
  );

  const totalSortCount = useSelector(
    (state) => state?.productSortData?.product_count
  );

  const [data, setData] = useState(productListingRes);

  const showGridHandler = () => {
    setShowGrid(true);
    setShowList(false);
  };

  const showListHandler = () => {
    setShowGrid(false);
    setShowList(true);
  };

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [filterBy, setFilterBy] = useState([]);

  //Category Filter

  const handleLicense = (e) => {
    setSecLoader(true);

    const { value } = e.target;
    const { name } = e.target;

    if (e.target.checked) {
      let sortByData = filterBy;

      contentTitle?.Sub_Category_Details[0]?.subcat?.map((item1, index1) => {
        return item1?.brandtype?.filter((item2, index2) => {
          if (item2?.slug_name == value) {
            sortByData?.push(item1);
          }
        });
      });
      setFilterBy([...sortByData]);
    } else {
      setFilterBy(filterBy?.slice(0, -1));
    }

    if (e.target.checked) {
      if (name === "brandType") {
        setBrandTypeValue((pState) => [...pState, value]);
        setBrandSelect(value);
      } else if (name === "productType") {
        setProductTypeValue((pState) => [...pState, value]);
        setProductSelect(value);
      } else if (name === "color") {
        setColorFilter((pState) => [...pState, value]);
      }
    } else {
      if (name === "brandType") {
        const brandFilter = brandTypeValue?.filter((e) => e !== value);
        setBrandTypeValue([...brandFilter]);
        setBrandSelect(brandFilter[brandFilter?.length - 1]);
      } else if (name === "productType") {
        const productFilter = productTypeValue?.filter((e) => e !== value);
        setProductTypeValue([...productFilter]);
        setProductSelect(productFilter[productFilter?.length - 1]);
      } else if (name === "color") {
        setColorFilter(colorFilter?.filter((e) => e !== value));
      }
    }
  };

  const handleLicenses = (e) => {
    e.preventDefault();
    setSecLoader(true);

    const { value, name } = e.target;

    setProductSelect(value);

    setResetProduct(value === "X" ? "" : value);

    setSelectedValue({ name, value });
  };

  const handleSortBy = (e) => {
    setSecLoader(true);

    const { value, name } = e.target;
    setProductSelect(value);

    setResetProduct(value === "X" ? "" : value);

    setSelectedValue({ name, value });

    setSelectAcc(value);
  };

  //Price filter

  const handlePriceFilter = (e) => {
    const { value } = e.target;

    setPriceFilter(value);
  };

  const colorFilteration = (e) => {
    const { value, name } = e.target;

    if (name === "color") {
      if (e.target.checked) {
        setColorFilter((pState) => [...pState, value]);
      } else {
        setColorFilter(colorFilter?.filter((e) => e !== value));
      }
    }
  };

  useEffect(() => {
    if (productFilterRes) {
      setData(productFilterRes);
    } else if (productSortRes) {
      setData(productSortRes);
    } else {
      setData(productListingRes);
    }
  }, [productListingRes, productFilterRes, productSortRes]);

  useEffect(() => {
    if (
      contentTitle?.product_type_list?.map((e) => e?.brandslugname) == title ||
      contentTitle?.brand_type_list?.map((e) => e?.productslugname) == title
    ) {
      setLoading(false);
    } else if (contentTitle?.ParentName?.slug_name == page_title) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [title, contentTitle]);

  useEffect(() => {
    if (
      brandTypeValue?.length > 0 ||
      productTypeValue?.length > 0 ||
      colorFilter?.length > 0 ||
      Object.keys(selectedValue)?.length > 0 ||
      Object.keys(resetProduct)?.length > 0
      // selectAcc?.length > 0
    ) {
      dispatch(
        productFiltersAction(
          page_title,
          title,
          subCatName,
          langSwitcRes,
          res,
          brandTypeValue,
          productTypeValue,
          categoryDropdown,
          colorFilter,
          selectedValue,
          priceFilter,
          itemOffset
        )
      );
    } else {
      setData(productListingRes);
    }
  }, [
    page_title,
    title,
    subCatName,
    langSwitcRes,
    res,
    brandTypeValue,
    productTypeValue,
    categoryDropdown,
    colorFilter,
    selectedValue,
    priceFilter,
    itemOffset,
  ]);

  useEffect(() => {
    setItemOffset(1);
  }, [
    selectedValue,
    productTypeValue,
    brandTypeValue,
    priceFilter,
    colorFilter,
  ]);

  // const handleBrand = (productType) => {
  //   dispatch(productTypeListAction(productType, langSwitcRes));
  // };

  //ProductType

  const filteredProducts = data?.map((e) => e?.product_type);
  const filtered = data
    ?.filter(
      ({ product_type }, index) =>
        !filteredProducts?.includes(product_type, index + 1)
    )
    ?.sort((a, b) => a?.product_type.localeCompare(b?.product_type));

  //brand type

  const filterBrand = filterBy?.map((e) => e?.subcatname);
  const brandFilter = filterBy
    ?.filter(
      ({ subcatname }, index) => !filterBrand?.includes(subcatname, index + 1)
    )
    ?.sort((a, b) => a?.subcatname.localeCompare(b?.subcatname));

  //Brands

  const brandsData = data?.map((e) => e?.brand);
  const brandDataFilter = data
    ?.filter(({ brand }, index) => !brandsData?.includes(brand, index + 1))
    ?.sort((a, b) => a?.brand.localeCompare(b?.brand));

  return (
    <>
      {loading ? (
        <>
          <center className="page-body-container">
            <h4>Please Wait...</h4>

            <PropagateLoader color={"#0da5e8 "} loading={loading} size={20} />
          </center>
        </>
      ) : (
        <>
          <HelmetProvider>
            <div className="page-body-container">
              <Helmet>
                <title>{contentTitle?.Product_listing_block_metaTitle}</title>
                <meta
                  name="description"
                  content={contentTitle?.Product_listing_block_metaDesc}
                />
                <meta
                  name="keyword"
                  content={contentTitle?.Product_listing_block_metaKey}
                />
              </Helmet>
              <BasicBreadcrumbs />
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                    <div className="filter-accordion-cont">
                      {/* {catagoryBlockRes?.name?.toLowerCase() ===
                page_title.toLowerCase() ? (
                  <Accordion disabled>
                    <AccordionSummary
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>{catagoryBlockRes?.name}</Typography>
                    </AccordionSummary>
                  </Accordion>
                ) : (
                  ""
                )} */}

                      {catagoryBlockRes &&
                      brandTypeData?.length === 0 &&
                      productTypeData?.length === 0 ? (
                        <Accordion disabled>
                          <AccordionSummary
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                          >
                            <Typography>
                              {catagoryBlockRes?.page_title}
                            </Typography>
                          </AccordionSummary>
                        </Accordion>
                      ) : (
                        ""
                      )}

                      {/* Brands */}

                      {contentTitle?.Sub_Category_Details?.length === 0
                        ? contentTitle?.brand_type_list?.map((e, i) => {
                            return (
                              <React.Fragment key={i}>
                                <Accordion disabled>
                                  <AccordionSummary
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                  >
                                    <Typography>{e?.productname}</Typography>
                                  </AccordionSummary>
                                </Accordion>
                                <Accordion
                                  expanded={expanded === `panel${i}`}
                                  onChange={handleChange(`panel${i}`)}
                                >
                                  <AccordionSummary
                                    expandIcon={<CustomExpandIcon />}
                                    aria-controls={`panel${i}bh-content`}
                                    id={`panel${i}bh-content`}
                                  >
                                    <Typography className="faqheading">
                                      {e?.productname}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {e?.brandsname?.map((e, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <Typography onClick={handleLicense}>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={e?.brandslugname}
                                                id={e?.brandname}
                                                name="brandType"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={e?.brandname}
                                              >
                                                {e?.brandname} &nbsp;({e?.count}
                                                )
                                              </label>
                                            </div>
                                          </Typography>
                                        </React.Fragment>
                                      );
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </React.Fragment>
                            );
                          })
                        : contentTitle?.Sub_Category_Details?.map((e, i) => {
                            return (
                              <React.Fragment key={i}>
                                {e?.subcat?.map((e, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      {e?.subcatid == "" ? (
                                        <>
                                          <Accordion
                                            expanded={
                                              expanded ===
                                                `panel${e?.subcatid}` ||
                                              e?.subcatid ===
                                                parseInt(selectAcc)
                                            }
                                            onChange={handleChange(
                                              `panel${e?.subcatid}`
                                            )}
                                          >
                                            <AccordionDetails className="mt-lg-3">
                                              {e?.brandtype?.map((e, i) => {
                                                return (
                                                  <React.Fragment key={i}>
                                                    <Typography
                                                      onClick={handleLicense}
                                                    >
                                                      <div className="form-check">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          value={e?.slug_name}
                                                          id={e?.id}
                                                          name="brandType"
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={e?.id}
                                                        >
                                                          {e?.brandname} &nbsp;(
                                                          {
                                                            contentTitle
                                                              ?.brand_count[
                                                              e?.slug_name
                                                            ]
                                                          }
                                                          )
                                                        </label>
                                                      </div>
                                                    </Typography>
                                                  </React.Fragment>
                                                );
                                              })}
                                            </AccordionDetails>
                                          </Accordion>
                                        </>
                                      ) : (
                                        <>
                                          <Accordion
                                            expanded={
                                              expanded ===
                                                `panel${e?.subcatid}` ||
                                              e?.subcatid ===
                                                parseInt(selectAcc)
                                            }
                                            onChange={handleChange(
                                              `panel${e?.subcatid}`
                                            )}
                                          >
                                            <AccordionSummary
                                              expandIcon={<CustomExpandIcon />}
                                              aria-controls={`panel${i}bh-content`}
                                              id={`panel${i}bh-content`}
                                            >
                                              <Typography className="faqheading">
                                                {e?.subcatname}
                                              </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              {e?.brandtype?.map((e, i) => {
                                                return (
                                                  <React.Fragment key={i}>
                                                    <Typography
                                                      onClick={handleLicense}
                                                    >
                                                      <div className="form-check">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          value={e?.slug_name}
                                                          id={e?.id}
                                                          name="brandType"
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={e?.id}
                                                        >
                                                          {e?.brandname}
                                                        </label>
                                                      </div>
                                                    </Typography>
                                                  </React.Fragment>
                                                );
                                              })}
                                            </AccordionDetails>
                                          </Accordion>
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}

                      {/* product type */}

                      {contentTitle?.Sub_Category_Details?.length === 0 ? (
                        contentTitle?.product_type_list?.map((e, i) => {
                          return (
                            <>
                              <Accordion disabled className="mt-4">
                                <AccordionSummary
                                  aria-controls="panel3a-content"
                                  id="panel3a-header"
                                >
                                  <Typography>
                                    <span className="disabledlabel">
                                      PRODUCT TYPES
                                    </span>
                                  </Typography>
                                </AccordionSummary>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel100"}
                                onChange={handleChange("panel100")}
                              >
                                <AccordionSummary
                                  expandIcon={<CustomExpandIcon />}
                                  aria-controls="panel5bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography className="faqheading">
                                    Products
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography onChange={handleLicense}>
                                    <React.Fragment key={i}>
                                      {e?.productname?.map((e, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={e?.productslugname}
                                                id={e?.productslugname}
                                                name="productType"
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={e?.productslugname}
                                              >
                                                {e?.productname} &nbsp;(
                                                {e?.count})
                                              </label>
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                    </React.Fragment>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <Accordion disabled className="mt-4">
                            <AccordionSummary
                              aria-controls="panel3a-content"
                              id="panel3a-header"
                            >
                              <Typography>
                                <span className="disabledlabel">
                                  PRODUCT TYPES
                                </span>
                              </Typography>
                            </AccordionSummary>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel100"}
                            onChange={handleChange("panel100")}
                          >
                            <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel5bh-content"
                              id="panel1bh-header"
                            >
                              <Typography className="faqheading">
                                Products
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography onChange={handleLicense}>
                                {contentTitle?.product_unique?.map((e, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={e?.productslugname}
                                          id={e}
                                          name="productType"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={e?.productname}
                                        >
                                          {e?.productname} &nbsp;(
                                          {
                                            contentTitle?.product_count[
                                              e?.productslugname
                                            ]
                                          }
                                          )
                                        </label>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      )}
                      {contentTitle?.Sub_Category_Details?.length === 0 &&
                        contentTitle?.product_type_list?.length === 0 &&
                        contentTitle?.brand_type_list?.length === 0 && (
                          <>
                            <Accordion disabled className="mt-4">
                              <AccordionSummary
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                              >
                                <Typography>
                                  <span className="disabledlabel">
                                    PRODUCT TYPES
                                  </span>
                                </Typography>
                              </AccordionSummary>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel100"}
                              onChange={handleChange("panel100")}
                            >
                              <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel5bh-content"
                                id="panel1bh-header"
                              >
                                <Typography className="faqheading">
                                  Products
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {productTypeListRes?.map((e, i) => {
                                  const slugData =
                                    langSwitcRes === "en"
                                      ? e?.slugname_en
                                      : langSwitcRes === "es"
                                      ? e?.slugname_es
                                      : langSwitcRes === "fr"
                                      ? e?.slugname_fr
                                      : e?.slugname_it;
                                  const titleData =
                                    langSwitcRes === "en"
                                      ? e?.title_en
                                      : langSwitcRes === "es"
                                      ? e?.title_es
                                      : langSwitcRes === "fr"
                                      ? e?.title_fr
                                      : e?.title_it;
                                  return (
                                    <React.Fragment key={i}>
                                      <Typography onChange={handleLicense}>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={slugData}
                                            id={titleData}
                                            name="productType"
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={titleData}
                                          >
                                            {titleData}
                                          </label>
                                        </div>
                                      </Typography>
                                    </React.Fragment>
                                  );
                                })}
                              </AccordionDetails>
                            </Accordion>
                          </>
                        )}
                      <Accordion disabled className="mt-4">
                        <AccordionSummary
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography>COLOR</Typography>
                        </AccordionSummary>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel20"}
                        onChange={handleChange("panel20")}
                      >
                        <AccordionSummary
                          expandIcon={<CustomExpandIcon />}
                          aria-controls="panel6bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className="faqheading">Color</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography onChange={colorFilteration}>
                            <div className="colorpalette-cont">
                              <ul className="colorpalette-colors">
                                {contentTitle?.Color_block?.map((e, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <li>
                                        <input
                                          type="checkbox"
                                          value={e?.color}
                                          id={`cb${i}`}
                                          name="color"
                                        />
                                        <label for={`cb${i}`}>
                                          <img
                                            alt={e?.color}
                                            title={e?.color}
                                            src={`${baseUrlColor}/${e?.color_image}`}
                                          />
                                        </label>
                                      </li>
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 product__section">
                    {/* {productDataRes?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="product-main-title">{page_title}</div>
                    <ProductBanner />
                    <div className="row">
                      <div className="page-heading">{e?.tittle}</div>
                      <p>{e?.content}</p>
                    </div>
                  </React.Fragment>
                );
              })} */}

                    {catagoryBlockRes &&
                    brandTypeData?.length === 0 &&
                    productTypeData?.length === 0 ? (
                      <div className="product-main-title">
                        {catagoryBlockRes?.page_title}
                      </div>
                    ) : brandTypeData?.length > 0 &&
                      productTypeData?.length === 0 ? (
                      brandTypeData?.map((e, i) => {
                        return (
                          <div className="product-main-title">
                            {e?.productname}
                          </div>
                        );
                      })
                    ) : productTypeData?.length > 0 &&
                      brandTypeData?.length === 0 ? (
                      productTypeData?.map((e, i) => {
                        return (
                          <div className="product-main-title">
                            {e?.brandname}
                          </div>
                        );
                      })
                    ) : (
                      ""
                    )}

                    <ProductBanner />
                    <div className="row">
                      {/* <div className="page-heading">
                    {contentTitle?.Product_listing_block_contentTitle}
                  </div> */}

                      {langSwitcRes && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contentTitle?.Product_listing_block_content,
                          }}
                        ></p>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="row g-3 align-items-center sortby">
                          <div className="col-auto">
                            <label className="col-form-label">Filter By</label>
                          </div>
                          <div className="col-auto">
                            {contentTitle?.Sub_Category_Details?.length === 0 &&
                            contentTitle?.brand_type_list?.length === 0 ? (
                              <></>
                            ) : (
                              <>
                                <form className="d-flex">
                                  <select
                                    className="form-select form-select-lg"
                                    name="brandType"
                                    aria-label=".form-select-lg example"
                                    onChange={handleSortBy}
                                  >
                                    <option
                                      selected={resetProduct === ""}
                                      value=""
                                    >
                                      BRANDS
                                    </option>

                                    {contentTitle?.Sub_Category_Details
                                      ?.length === 0 ? (
                                      brandDataFilter?.map((e, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <option
                                              selected={
                                                e?.brand_slug === brandSelect
                                              }
                                              value={e?.brand_slug}
                                            >
                                              {e?.brand?.toUpperCase()}
                                            </option>
                                          </React.Fragment>
                                        );
                                      })
                                    ) : (
                                      <>
                                        {brandFilter?.length > 0 ? (
                                          <>
                                            {brandFilter?.map((e, i) => {
                                              return (
                                                <React.Fragment key={i}>
                                                  <option
                                                    selected={
                                                      e?.product_type_slug ===
                                                      productSelect
                                                    }
                                                    value={e?.subcatid}
                                                  >
                                                    {e?.subcatname?.toUpperCase()}
                                                  </option>
                                                </React.Fragment>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            {contentTitle?.Sub_Category_Details?.map(
                                              (e, i) => {
                                                return (
                                                  <React.Fragment key={i}>
                                                    {e?.subcat?.map((e, i) => {
                                                      return (
                                                        <React.Fragment key={i}>
                                                          {e?.brandtype?.map(
                                                            (e, i) => {
                                                              return (
                                                                <React.Fragment
                                                                  key={i}
                                                                >
                                                                  <option
                                                                    value={
                                                                      e?.slug_name
                                                                    }
                                                                    selected={
                                                                      e?.slug_name ===
                                                                      productSelect
                                                                    }
                                                                  >
                                                                    {e?.brandname?.toUpperCase()}
                                                                  </option>
                                                                </React.Fragment>
                                                              );
                                                            }
                                                          )}
                                                        </React.Fragment>
                                                      );
                                                    })}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </select>

                                  <input
                                    type="reset"
                                    onClick={handleLicenses}
                                    className="mx-2 btn btn-dark text-light"
                                    name="brandType"
                                    value="X"
                                  />
                                </form>
                              </>
                            )}
                          </div>
                          <div className="col-auto d-flex ">
                            {contentTitle?.Sub_Category_Details?.length === 0 &&
                            contentTitle?.product_type_list?.length === 0 ? (
                              <></>
                            ) : (
                              <>
                                <form className="d-flex">
                                  <select
                                    className="form-select form-select-lg"
                                    aria-label=".form-select-lg example"
                                    name="productType"
                                    onChange={handleLicenses}
                                  >
                                    <option
                                      selected={resetProduct === ""}
                                      value=""
                                    >
                                      PRODUCT TYPE
                                    </option>
                                    {filtered?.map((e, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <option
                                            selected={
                                              e?.product_type_slug ===
                                              productSelect
                                            }
                                            value={e?.product_type_slug}
                                          >
                                            {e?.product_type?.toUpperCase()}
                                          </option>
                                        </React.Fragment>
                                      );
                                    })}
                                  </select>

                                  <input
                                    type="reset"
                                    onClick={handleLicenses}
                                    className="mx-2 btn btn-dark text-light"
                                    name="productType"
                                    value="X"
                                    placeholder="X"
                                  />
                                </form>
                              </>
                            )}
                          </div>

                          <div className="col-auto d-flex">
                            <select
                              className="form-select form-select-lg"
                              aria-label=".form-select-lg example"
                              onChange={handlePriceFilter}
                            >
                              <option selected value="ztoa">
                                SORT BY BRANDS (Z - A)
                              </option>
                              <option value="atoz">
                                SORT BY BRANDS (A - Z)
                              </option>
                              <option value="low-high">
                                PRICE-LOW TO HIGH
                              </option>
                              <option value="high-low">
                                PRICE-HIGH TO LOW
                              </option>
                            </select>
                            {/* <button
                              type="reset"
                              className="mx-2 btn btn-dark text-light"
                              onClick={handleLicenses}
                              name="productTypeDefault"
                              value=""
                            >
                              X
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="row product__grid__row">
                        <div className="product__page__count">
                          <div className="total-product">
                            <div className="col-auto">
                              <label className="col-form-label">
                                Total Items:{" "}
                                {(productFilterRes &&
                                  brandTypeValue?.length > 0) ||
                                productTypeValue?.length > 0 ||
                                colorFilter?.length > 0 ||
                                Object.keys(selectedValue)?.length > 0 ? (
                                  <span id="total-count">
                                    {totalFiltersCount}
                                  </span>
                                ) : contentTitle && !productSortRes ? (
                                  <span id="total-count">
                                    {contentTitle?.Total_Product_Count}
                                  </span>
                                ) : (
                                  <>
                                    {" "}
                                    <span id="total-count">{totalSortCount}</span>
                                  </>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="grid__list__view">
                          <div className="row g-3 align-items-center float-end">
                            <div className="col-auto">
                              <div className="view-mode float-end">
                                {showGrid ? (
                                  <>
                                    <Link to="#">
                                      <span className="grid active" title="Grid">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-grid"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                        </svg>
                                      </span>
                                    </Link>
                                    <Link to="#" onClick={showListHandler}>
                                      <span className="list" title="List">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-list-ul"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                          />
                                        </svg>
                                      </span>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="#" onClick={showGridHandler}>
                                      <span className="grid" title="Grid">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-grid"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                        </svg>
                                      </span>
                                    </Link>
                                    <Link to="#">
                                      <span className="list active" title="List">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-list-ul"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                          />
                                        </svg>
                                      </span>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {showGrid ? (
                        <ProductGrid
                          data={data}
                          loader={secLoader}
                          setSecLoader={setSecLoader}
                          contentTitle={contentTitle}
                          setItemOffset={setItemOffset}
                          itemOffset={itemOffset}
                          setApiStatus={setApiStatus}
                          apiStatus={apiStatus}
                        />
                      ) : (
                        <ProductList
                          data={data}
                          loader={secLoader}
                          setSecLoader={setSecLoader}
                          contentTitle={contentTitle}
                          setItemOffset={setItemOffset}
                          itemOffset={itemOffset}
                          setApiStatus={setApiStatus}
                          apiStatus={apiStatus}
                        />
                      )}
                    </div>

                    {langSwitcRes && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            contentTitle?.product_listing_block_page_description_footer,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </HelmetProvider>
        </>
      )}
    </>
  );
};

// const agent = {subcatId: {1,checked}}
