import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../index.css";
import { useSelector } from "react-redux";
import { baseUrls } from "../../../../config/index";
import { Link, useParams } from "react-router-dom";

export const CategoryRegister = () => {
  //Language Switcher

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes, slugName } = useParams();

  const categoryPageRes = useSelector(
    (state) => state?.categoryData?.characters_block
  );

  //category Content Response

  const categoryConRes = useSelector(
    (state) => state?.categoryData?.characters_block_content
  );

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="accessories-cont">
        <div className="container">
          {langSwitcRes &&
            categoryConRes
              ?.filter((e) => e?.language === langSwitcRes)
              ?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="section-heading mt-4 text-uppercase">
                      {e?.tittle}
                    </div>
                    <p
                      className="section-paragraph"
                      dangerouslySetInnerHTML={{ __html: e?.content }}
                    ></p>
                  </React.Fragment>
                );
              })}

          <Slider {...settings} className=" text-center mt-lg-3">
            {categoryPageRes?.map((e, i) => {
              const slugData =
                langSwitcRes === "en"
                  ? e?.slugname_en
                  : langSwitcRes === "es"
                  ? e?.slugname_es
                  : langSwitcRes === "fr"
                  ? e?.slugname_fr
                  : e?.slugname_it;

              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;

              return (
                <React.Fragment key={i}>
                  <div className="card cardmargins border-0  pb-3">
                    <Link to={`/${langSwitcRes}/${slugName}/${slugData}`}>
                      <div className="swapimg-container-nb">
                        <img
                          className="card-img-top paacenter"
                          src={`${baseUrls}/${e?.character_image}`}
                          alt={titleData}
                        />
                      </div>
                    </Link>

                    <Link
                      to={`/${langSwitcRes}/${slugName}/${slugData}`}
                      title={titleData}
                    >
                      {titleData}
                    </Link>
                  </div>
                </React.Fragment>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};
