import React, { useEffect } from "react";
import { AccountsInfo } from "./AccountsInfo";
import { MyOrders } from "./MyOrders";
import { MyInvoices } from "./MyInvoices";
import { Catalogs } from "./Catalogs";
import { Launch } from "./Launch";
import BasicBreadcrumbs from "./BasicBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { customerArea } from "../../../Redux/Action";
import { useTranslation } from "react-i18next";

export const CustomerArea = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const langSwitcRes = useSelector((state) => state?.langSwitchData);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const loginResId = res?.data?.userid;

  useEffect(() => {
    dispatch(customerArea(loginResId, langSwitcRes));
  }, [dispatch, loginResId, langSwitcRes]);

  //Customer Area Response

  const customerAreaRes = useSelector((state) => state?.customerAreaData);

  const resGeneral = res?.data?.group;

  return (
    <>
      <div className="myaccount-page-body pt-5 mt-lg-5">
        <div className="container mb-4">
          <img
            src="/images/account/my-account-banner.jpg"
            class="img-fluid"
            alt="my account"
          />
        </div>
        <BasicBreadcrumbs />
        <div className="container">
          <div className="page-header">
            <h3>CUSTOMER AREA</h3>
          </div>
          <div class="card text-dark bg-light basicinfo">
            {customerAreaRes?.length === 0 ? (
              <>
                <div class="card-body">
                  <div class="row rowheight">
                    <div class="col">{t("customer")} :</div>
                    <div class="col highlight">-</div>
                    <div class="col">{t("account-type")}</div>
                    <div class="col highlight">{resGeneral?.toUpperCase()}</div>
                  </div>
                  <div class="row rowheight">
                    <div class="col">{t("customer-id")}:</div>
                    <div class="col highlight">-</div>
                    <div class="col">{t("default-address")}:</div>
                    <div class="col highlight">-</div>
                  </div>
                  <div class="row rowheight">
                    <div class="col">{t("customer-discount")}:</div>
                    <div class="col highlight">-</div>
                    <div class="col">{t("payment-method")}:</div>
                    <div class="col highlight">-</div>
                  </div>
                  <div class="row rowheight">
                    <div class="col">{t("equal-charge")}:</div>
                    <div class="col highlight">-</div>
                    <div class="col"></div>
                    <div class="col highlight"></div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {customerAreaRes?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div class="card-body">
                    <div class="row rowheight">
                      <div class="col">{t("customer")}:</div>
                      <div class="col highlight">{e?.company}</div>
                      <div class="col">{t("account-type")}:</div>
                      <div class="col highlight">
                        {resGeneral?.toUpperCase()}
                      </div>
                    </div>
                    <div class="row rowheight">
                      <div class="col">{t("customer-id")}:</div>
                      <div class="col highlight">
                        {e?.erp_customer_id ? (
                          <>{e?.erp_customer_id}</>
                        ) : (
                          <>{e?.customer_id}</>
                        )}
                      </div>
                      <div class="col">{t("default-address")}:</div>
                      <div class="col highlight">
                        {e?.address_1}, {e?.state},
                        <br />
                        {e?.city}, {e?.postal_code}
                      </div>
                    </div>
                    <div class="row rowheight">
                      <div class="col">{t("customer-discount")}:</div>
                      <div class="col highlight">
                        {e?.linear_discount ? (
                          <>{e?.linear_discount}%</>
                        ) : (
                          <>-</>
                        )}
                      </div>
                      <div class="col">{t("payment-method")}:</div>
                      <div class="col highlight">
                        {e?.payment_method ? <>{e?.payment_method}%</> : <>-</>}
                      </div>
                    </div>
                    <div class="row rowheight">
                      <div class="col">{t("equal-charge")}:</div>
                      <div class="col highlight">
                        {e?.recarge_of_equivalen ? (
                          <>{e?.recarge_of_equivalen}</>
                        ) : (
                          <>-</>
                        )}
                      </div>
                      <div class="col"></div>
                      <div class="col highlight"></div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          <div className="myaccount-tab-cont">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="myaccount-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#myaccount"
                  type="button"
                  role="tab"
                  aria-controls="myaccount"
                  aria-selected="true"
                >
                  {t("myaccounts")}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="myorders-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#myorders"
                  type="button"
                  role="tab"
                  aria-controls="myorders"
                  aria-selected="false"
                >
                  {t("myorders")}
                </button>
              </li>

              {(res?.status === 200) &
              (resGeneral === "wholesaler" || resGeneral === "retailer") ? (
                <>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="myinvoices-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#myinvoices"
                      type="button"
                      role="tab"
                      aria-controls="myinvoices"
                      aria-selected="false"
                    >
                      {t("my_invoices")}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="catalogs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#catalogs"
                      type="button"
                      role="tab"
                      aria-controls="catalogs"
                      aria-selected="false"
                    >
                      Catalogs
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="launch-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#launch"
                      type="button"
                      role="tab"
                      aria-controls="launch"
                      aria-selected="false"
                    >
                      Launch
                    </button>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="myaccount"
                role="tabpanel"
                aria-labelledby="myaccount-tab"
              >
                <AccountsInfo />
              </div>
              <div
                class="tab-pane fade"
                id="myorders"
                role="tabpanel"
                aria-labelledby="myorders-tab"
              >
                <MyOrders />
              </div>
              <div
                class="tab-pane fade"
                id="myinvoices"
                role="tabpanel"
                aria-labelledby="myinvoices-tab"
              >
                {(res?.status === 200) &
                (resGeneral === "retailer" || resGeneral === "wholesaler") ? (
                  <>
                    <MyInvoices />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div
                class="tab-pane fade"
                id="catalogs"
                role="tabpanel"
                aria-labelledby="catalogs-tab"
              >
                {(res?.status === 200) &
                (resGeneral === "retailer" || resGeneral === "wholesaler") ? (
                  <>
                    <Catalogs />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div
                class="tab-pane fade"
                id="launch"
                role="tabpanel"
                aria-labelledby="launch-tab"
              >
                {(res?.status === 200) &
                (resGeneral === "retailer" || resGeneral === "wholesaler") ? (
                  <>
                    <Launch />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
