import React from "react";
import { FaUser } from "react-icons/fa";
import { IoIosCart } from "react-icons/io";
import { ImRoad } from "react-icons/im";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../../../index.css";

export const BatmanPin = () => {
  //Language Switcher
  const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const navigate = useNavigate();

  const handleChange = () => {
    navigate(`/${langSwitcRes}/individualRegister`);
    window.location.reload();
  };

  const handleShop = () => {
    navigate(`/${langSwitcRes}/shopregister`);
    window.location.reload();
  };

  const handleWholesale = () => {
    navigate(`/${langSwitcRes}/wholeregister`);
    window.location.reload();
  };

  return (
    <div>
      <a
        className="btn-wrapper"
        href=""
        // href={`/${langSwitcRes}/login`}
        // onClick={() => navigate(`/${langSwitcRes}/login`)}
        id="btn-batman"
        data-bs-toggle="modal"
        data-bs-target="#welcomeModal"
      >
        <div className="icon-batman pin" id="icon-batman">
          <img
            className="tooltip"
            src="/images/footer/batman/btn_register_en.svg"
            alt="Sign Up."
          />
          <img
            src="/images/footer/batman/batman_bg_transp_new.gif"
            alt="Icono de Batman"
            className="icon-batman-btn"
          />
        </div>
      </a>

      <div className="modal fade" id="welcomeModal" tabindex="1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div class="row g-lg-5 ">
              <div
                class="col-sm-6  col-md-6 text-light modalLeft"
                style={{ background: "#0da5e8" }}
              >
                <p className="modelTitle">WELCOME!</p>
                <p className="modelPara">
                  Create your account now to access the KARACTERMANIA online
                  store and receive all the news.
                </p>
              </div>
              <div class="col-6 col-sm-5 col-md-4">
                <div class="row float-end">
                  <button
                    type="button"
                    className="btn-close "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="row">
                  <button
                    className="buton button2 mt-lg-5"
                    onClick={handleChange}
                  >
                    <div className="d-flex" title="Customer">
                      <FaUser size={18} className="mx-lg-2" />
                      CUSTOMER
                    </div>
                  </button>
                </div>
                <div class="row">
                  <button className="buton button2 mt-3" onClick={handleShop}>
                    <div className="d-flex " title="Store">
                      <IoIosCart size={25} className="mx-lg-3" />
                      STORE
                    </div>
                  </button>
                </div>
                <div class="row">
                  <button
                    className="buton button2 mt-3"
                    onClick={handleWholesale}
                  >
                    <div className="d-flex" title="Wholesaler">
                      <ImRoad size={18} className="mx-lg-3" />
                      WHOLESALER
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
