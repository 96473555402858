import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { baseUrls } from "../../../config";

export const Brands = ({ pageTitle }) => {
  //Language Switcher

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  //Homepage Products Response

  const homepageRes = useSelector((state) => state?.HomePageData?.brand_block);

  return (
    <>
      <ul className="dropdown-menu megamenu">
        <li>
          <div className="row justify-content-center mt-4">
            {homepageRes?.map((e, i) => {
              const slugData =
                langSwitcRes === "en"
                  ? e?.slugname_en
                  : langSwitcRes === "es"
                  ? e?.slugname_es
                  : langSwitcRes === "fr"
                  ? e?.slugname_fr
                  : e?.slugname_it;

              const titleData =
                langSwitcRes === "en"
                  ? e?.title_en
                  : langSwitcRes === "es"
                  ? e?.title_es
                  : langSwitcRes === "fr"
                  ? e?.title_fr
                  : e?.title_it;
              return (
                <React.Fragment key={i}>
                  <div className="col-2">
                    <div className="menu-ourbrands">
                      <Link
                        to={`/${langSwitcRes}/brand/${slugData}`}
                        title={titleData}
                      >
                        <div className="menu-brand-parent ourbrands-right-border">
                          <img
                            src={`${baseUrls}/${e?.image}`}
                            alt={e?.page_title}
                            title={titleData}
                          />
                          <div className="menu-brand-child">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </li>
      </ul>
    </>
  );
};
