import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";

export const MenuLinks = ({ pageTitle }) => {
  //Mega menu subcatagories response

  const menuRes = useSelector(
    (state) => state?.menuData[pageTitle]?.res?.category_details
  );

  const { langSwitcRes } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    {
      menuRes?.length > 0 && setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [pageTitle]);

  return (
    <>
      <ul className="dropdown-menu megamenu">
        <li>
          <div className="row">
            {loading && pageTitle ? (
              <>
                <center className="page-body-container mt-lg-5">
                  <FadeLoader color={"#0da5e8 "} loading={loading} size={20} />{" "}
                </center>
              </>
            ) : (
              <>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 ">
                  <div className="megamenu-subheading"></div>

                  <div className="row border-right">
                    {menuRes?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="megamenu-custom-column">
                            <div className="col-megamenu">
                              {item[0]?.map((e, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <ul className="licenses-menu">
                                      <Link
                                        to={`/${langSwitcRes}/category/${e?.subcatslugname}`}
                                        className="text-decoration-none"
                                      >
                                        <div
                                          className="megamenu-column-heading"
                                          title={e?.subcatname}
                                        >
                                          {e?.subcatname}
                                        </div>
                                      </Link>

                                      {e?.brandtype?.map((items, indexes) => {
                                        const slugData =
                                          langSwitcRes === "en"
                                            ? items?.slugname_en
                                            : langSwitcRes === "es"
                                            ? items?.slugname_es
                                            : langSwitcRes === "fr"
                                            ? items?.slugname_fr
                                            : items?.slugname_it;

                                        return (
                                          <React.Fragment key={indexes}>
                                            <li>
                                              {items?.slug_name ? (
                                                <>
                                                  <Link
                                                    to={`/${langSwitcRes}/${pageTitle}/${items?.slug_name}`}
                                                    title={items?.brandname?.toUpperCase()}
                                                  >
                                                    {items?.brandname?.toUpperCase()}
                                                  </Link>
                                                </>
                                              ) : (
                                                <>
                                                  <Link
                                                    to={`/${langSwitcRes}/${pageTitle}/${slugData}`}
                                                    title={items?.brandname?.toUpperCase()}
                                                  >
                                                    {items?.brandname?.toUpperCase()}
                                                  </Link>
                                                </>
                                              )}
                                            </li>
                                          </React.Fragment>
                                        );
                                      })}
                                    </ul>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="megamenu-subheading-two">Products</div>
                  <div className="row">
                    <div className="cols-megamenu">
                      <ul className="products-menu">
                        {" "}
                        {menuRes?.map((item, i) => {
                          return (
                            <React.Fragment key={i}>
                              {item?.producttype?.map((e, i) => {
                                const slugData =
                                  langSwitcRes === "en"
                                    ? e?.slugname_en
                                    : langSwitcRes === "es"
                                    ? e?.slugname_es
                                    : langSwitcRes === "fr"
                                    ? e?.slugname_fr
                                    : e?.slugname_it;
                                return (
                                  <React.Fragment key={i}>
                                    <li>
                                      {e?.slug_name ? (
                                        <>
                                          <Link
                                            to={`/${langSwitcRes}/${pageTitle}/${e?.slug_name}`}
                                            title={e?.productname}
                                          >
                                            {e?.productname?.toUpperCase()}
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          <Link
                                            to={`/${langSwitcRes}/${pageTitle}/${slugData}`}
                                            title={e?.productname}
                                          >
                                            {e?.productname?.toUpperCase()}
                                          </Link>
                                        </>
                                      )}
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </li>
      </ul>
    </>
  );
};
