import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { baseUrls } from "../../../../config/index";

export const BrandFullWidth2 = () => {
  const brandPageRes = useSelector((state) => state?.brandPageData?.banner_2);

  //Language Switcher

  const { langSwitcRes, slugData } = useParams();

  return (
    <>
      <div className="licensedproducts-cont">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 p-0 mr-0">
              {brandPageRes?.slice(0, 1)?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="graybg-sm">
                      <img
                        src={`${baseUrls}/${e?.image}`}
                        alt={e?.tittle}
                        className="w-100"
                      />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.tittle?.toUpperCase()}</h2>
                        <span
                          dangerouslySetInnerHTML={{ __html: e?.content }}
                        ></span>
                        {/* <p className="subheading">FASHION COLLECTION</p>
                        <p className="lptbg">
                          <Link
                            to={`/${langSwitcRes}/${slugData}`}
                            title={e?.content}
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: e?.content }}
                            ></span>
                          </Link>
                        </p> */}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
