import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../Common/Footer/Footer";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { cookiesAction } from "../../../Redux/Action";
import { useParams } from "react-router";
import { Header } from "../../Common/Header/Header";

export const Cookies = () => {
  const dispatch = useDispatch();

  //LangSwitcher

  const { langSwitcRes } = useParams();

  useEffect(() => {
    dispatch(cookiesAction(langSwitcRes));
  }, [langSwitcRes, dispatch]);

  const cookiesRes = useSelector((state) => state?.cookiesData?.data?.details);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  return (
    <>
      <Header pathData="cookies" />
      {cookiesRes?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>;
          </React.Fragment>
        );
      })}
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
