import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  changeBillingAction,
  changeShippingAction,
} from "../../../../Redux/Action";

export const BillingShipping = ({ setStep }) => {
  const dispatch = useDispatch();

  //Language switcher

  const { langSwitcRes } = useParams();

  //Billing Response

  const billingRes = useSelector((state) => state?.billingData?.data);

  //Shipping Response

  const shippingRes = useSelector((state) => state?.shippingData?.data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {" "}
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-3">
        <div className="card p-3">
          <address>
            <div className="addresses">Billing Address</div>
            <strong>
              {billingRes?.details?.first_name} {billingRes?.details?.last_name}
            </strong>
            <br />
            {billingRes?.details?.address_1}
            <br />
            {billingRes?.details?.city}
            <br />
            {billingRes?.details?.state}
            <br />
            {billingRes?.details?.country_name?.map((e, i) => {
              const slugData =
                langSwitcRes === "en"
                  ? e?.name_en
                  : langSwitcRes === "es"
                  ? e?.name_es
                  : langSwitcRes === "fr"
                  ? e?.name_fr
                  : e?.name_it;
              return <React.Fragment key={i}>{slugData}</React.Fragment>;
            })}
            - {billingRes?.details?.postal_code}
            <br />
            Tel No - {billingRes?.details?.telephone}
            <br />
            <button
              className="checkoutbutton"
              type="button"
              onClick={() =>
                dispatch(
                  changeBillingAction(
                    langSwitcRes,
                    billingRes?.address_id,
                    setStep
                  )
                )
              }
            >
              Change
            </button>
          </address>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-3">
        <div className="card p-3">
          <address>
            <div className="addresses">Shipping Address</div>

            {shippingRes?.details?.billing_option == null &&
            shippingRes &&
            (billingRes?.details?.default_shipping_address == 0 ||
              shippingRes?.details?.default_shipping_address == 1) ? (
              <>
                <strong>
                  {shippingRes?.details?.first_name}{" "}
                  {shippingRes?.details?.last_name}
                </strong>
                <br />
                {shippingRes?.details?.address_1}
                <br />
                {shippingRes?.details?.city}
                <br />
                {shippingRes?.details?.state}
                <br />
                {shippingRes?.details?.country_name?.map((e, i) => {
                  const slugData =
                    langSwitcRes === "en"
                      ? e?.name_en
                      : langSwitcRes === "es"
                      ? e?.name_es
                      : langSwitcRes === "fr"
                      ? e?.name_fr
                      : e?.name_it;
                  return <React.Fragment key={i}>{slugData}</React.Fragment>;
                })}
                - {shippingRes?.details?.postal_code}
                <br />
                Tel No - {shippingRes?.details?.telephone}
                <br />
                <button
                  className="checkoutbutton"
                  type="button"
                  onClick={() =>
                    dispatch(
                      changeShippingAction(
                        langSwitcRes,
                        shippingRes?.address_id,
                        setStep
                      )
                    )
                  }
                >
                  Change
                </button>
              </>
            ) : (
              <>
                <strong>
                  {billingRes?.details?.first_name}
                  {billingRes?.details?.last_name}
                </strong>
                <br />
                {billingRes?.details?.address_1}
                <br />
                {billingRes?.details?.city}
                <br />
                {billingRes?.details?.state}
                <br />
                {billingRes?.details?.country_name?.map((e, i) => {
                  const slugData =
                    langSwitcRes === "en"
                      ? e?.name_en
                      : langSwitcRes === "es"
                      ? e?.name_es
                      : langSwitcRes === "fr"
                      ? e?.name_fr
                      : e?.name_it;
                  return <React.Fragment key={i}>{slugData}</React.Fragment>;
                })}
                - {billingRes?.details?.postal_code}
                <br />
                Tel No - {billingRes?.details?.telephone}
                <br />{" "}
                <button
                  className="checkoutbutton"
                  type="button"
                  onClick={() =>
                    dispatch(
                      changeShippingAction(
                        langSwitcRes,
                        billingRes?.address_id,
                        setStep
                      )
                    )
                  }
                >
                  Change
                </button>
              </>
            )}
          </address>
        </div>
      </div>
    </>
  );
};
