import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aboutUsAction } from "../../../Redux/Action";
import { Footer } from "../../Common/Footer/Footer";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import "../About us/AboutUs.css";
import { useParams } from "react-router";
import { Header } from "../../Common/Header/Header";

export const AboutUs = () => {
  const dispatch = useDispatch();

  //LangSwitcher

  const { langSwitcRes } = useParams();

  useEffect(() => {
    dispatch(aboutUsAction(langSwitcRes));
  }, [langSwitcRes, dispatch]);

  const aboutusRes = useSelector((state) => state?.aboutusData?.data?.details);

  //Login Response

  const res = useSelector((state) => state?.loginData);

  return (
    <>
      <Header pathData="aboutus" />
      {aboutusRes?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>;
          </React.Fragment>
        );
      })}
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
