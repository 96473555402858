import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ImmediateServiceReview } from "./ImmediateServiceReview";
import { PreOrderReview } from "./PreOrderReview";

export const OrderReviewProducts = ({ langSwitcRes }) => {
  const [checkoutData, setCheckoutData] = useState({
    preorderData: [],
    immediateServiceData: [],
  });

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const resGeneral = res?.data?.group;

  //Cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  useEffect(() => {
    const preSale = [];
    const immediateSer = [];

    Object.keys(addToCartRes)?.map((e) => {
      if (
        addToCartRes[e]?.pre_sale == 0 &&
        resGeneral == "retailer" &&
        addToCartRes[e]?.min_qty_retailer > addToCartRes[e]?.b2b_qty
      ) {
        preSale?.push(addToCartRes[e]);
      } else if (
        addToCartRes[e]?.pre_sale == 0 &&
        resGeneral == "wholesaler" &&
        addToCartRes[e]?.min_qty_wholesaler > addToCartRes[e]?.b2b_qty
      ) {
        preSale?.push(addToCartRes[e]);
      } else if (addToCartRes[e]?.pre_sale == 1) {
        preSale?.push(addToCartRes[e]);
      } else {
        immediateSer?.push(addToCartRes[e]);
      }
    });

    setCheckoutData({
      ...checkoutData,
      preorderData: preSale,
      immediateServiceData: immediateSer,
    });
  }, [addToCartRes]);

  //Date

  // const d = new Date();
  // const month = d.getMonth() + 1;
  // const showTodayDate = d.getDate() + "/" + month + "/" + d.getFullYear();

  return (
    <>
      <table data-testid="table" className="checkouttable responsiveTable mb-2">
        <thead data-testid="thead">
          <tr data-testid="tr">
            <th data-testid="th"></th>
            <th data-testid="th">PRODUCT NAME</th>
            <th data-testid="th">SKU</th>
            <th data-testid="th">PRICE</th>
            <th data-testid="th">DISCOUNT</th>
            <th data-testid="th">CLIENT PRICE</th>
            <th data-testid="th">QUANTITY</th>
            <th data-testid="th">SUB TOTAL</th>
            <th data-testid="th">ACTION</th>
          </tr>
        </thead>
        <tbody data-testid="tbody">
          {Object.keys(addToCartRes).length === 0 && (
            <>
              <tr>
                <td valign="top" colspan="9" className="text-center cartempty">
                  <strong>Your Cart is Empty</strong>
                  <br />
                  Looks like you haven't added anything to your cart yet. No
                  data available in table
                </td>
              </tr>
            </>
          )}

          {checkoutData?.immediateServiceData?.length > 0 && (
            <ImmediateServiceReview
              immediateServiceData={checkoutData}
              langSwitcRes={langSwitcRes}
            />
          )}

          {res?.status === 200 && checkoutData?.preorderData?.length > 0 ? (
            <>
              <PreOrderReview
                preorderData={checkoutData}
                langSwitcRes={langSwitcRes}
              />
            </>
          ) : (
            ""
          )}
        </tbody>
      </table>
    </>
  );
};
