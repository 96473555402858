import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import "../../../../index.css";

export const BrandSupplier = () => {
  const categoryPageRes = useSelector(
    (state) => state?.categoryData?.page_details
  );

  return (
    <>
      <Helmet>
        <title>{categoryPageRes?.meta_title}</title>
        <meta name="description" content={categoryPageRes?.meta_description} />
      </Helmet>
      <div className="product-supplier-cont">
        <div className="product-supplier-cont-bg">
          <div className="row">
            <div className="heading">Brand SUPPLIER</div>
          </div>
          <span
            dangerouslySetInnerHTML={{
              __html: categoryPageRes?.page_description_footer,
            }}
          ></span>

          {/* 
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <Link to="">Frozen products distribution</Link>
                </li>
                <li>
                  <Link to="">Disney products distribution</Link>
                </li>
                <li>
                  <Link to="">Mickey products distribution</Link>
                </li>
                <li>
                  <Link to="">PJ Masks products distribution</Link>
                </li>
                <li>
                  <Link to="">LOL Surpise products distribution</Link>
                </li>
                <li>
                  <Link to="">Marvel products distribution</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <Link to="">Star Wars products distribution</Link>
                </li>
                <li>
                  <Link to="">Avengers products distribution</Link>
                </li>
                <li>
                  <Link to="">Spiderman products distribution</Link>
                </li>
                <li>
                  <Link to="">Peppa Pig products distribution</Link>
                </li>
                <li>
                  <Link to="">Patrul la Canina products distribution</Link>
                </li>
                <li>
                  <Link to="">Lady Bug products distribution</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <Link to="">Dragon Ball products distribution</Link>
                </li>
                <li>
                  <Link to="">Trolls products distribution</Link>
                </li>
                <li>
                  <Link to="">Pokemon products distribution</Link>
                </li>
                <li>
                  <Link to="">Minnie Mouse products distribution</Link>
                </li>
                <li>
                  <Link to="">Mi pequei\o pony products distribution</Link>
                </li>
                <li>
                  <Link to="">Juego de Tronos products distribution</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <Link to="">Juego de Tronos products distribution</Link>
                </li>
                <li>
                  <Link to="">Rick and Morty products distribution</Link>
                </li>
                <li>
                  <Link to="">Bebes Llorones products distribution</Link>
                </li>
                <li>
                  <Link to="">Rolling Stones products distribution</Link>
                </li>
                <li>
                  <Link to="">Emoji products distribution</Link>
                </li>
                <li>
                  <Link to="">Harry Potter products distribution</Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
