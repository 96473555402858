import { useEffect, useRef, useState } from "react";

export const useElementOnScreen = (reinitializeFlag) => {
  const options = { root: null, rootMargin: "0px", threshold: 0 };
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (!isVisible) {
      setIsVisible(entry.isIntersecting);
      setLoading(!entry.isIntersecting);
    }
  };

  useEffect(() => {
    if (reinitializeFlag === false) {
      setIsVisible(false);
    }
  }, [reinitializeFlag]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible, loading, setIsVisible];
};
