import React from "react";
import { Footer } from "../../../Common/Footer/Footer";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { CasualGaming } from "./product details components/CasualGaming";
import { MajorBrands } from "./product details components/MajorBrands";
import { ProductOutstanding } from "./product details components/ProductOutstanding";
import { ProductDetailedInfo } from "./product details components/ProductDetailedInfo";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Header } from "../../../Common/Header/Header";

export const ProductDetails = () => {
  //Login Response

  const res = useSelector((state) => state?.loginData);

  const { slug } = useParams();

  return (
    <>
      <Header pathData={`ProductDetails/${slug}`} />
      <ProductDetailedInfo />
      <ProductOutstanding />
      <CasualGaming />
      <MajorBrands />
      {res?.status === 200 ? "" : <BatmanPin />}
      <Footer />
    </>
  );
};
