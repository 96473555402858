import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BasicBreadcrumbs() {
  const navigate = useNavigate();

  //Language Switcher
  const langSwitcRes = useSelector((state) => state?.langSwitchData);
  return (
    <div className="container">
      <div className="breadcrumb-cont">
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href={`/${langSwitcRes}/home`}
              onClick={() => navigate(`/${langSwitcRes}/home`)}
            >
              Karactermania
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              href="/material-ui/getting-started/installation/"
            >
              Products
            </Link> */}
            <Typography color="text.primary">My Account</Typography>
          </Breadcrumbs>
        </div>
      </div>
    </div>
  );
}
