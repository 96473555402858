import React from "react";
import { Footer } from "../../../Common/Footer/Footer";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { Products } from "./Products Page Components/Products";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ProductsCopy } from "./Products Page Components/ProductsCopy";
import { Header } from "../../../Common/Header/Header";

export const ProductsPage = () => {
  //Login Response

  const res = useSelector((state) => state?.loginData);

  const { page_title, title } = useParams();

  return (
    <>
      {page_title && !title ? (
        <>
          <Header pathData={`${page_title}`} />
        </>
      ) : (
        <>
          <Header pathData={`${page_title}/${title}`} />
        </>
      )}

      {title ? (
        <>
          <ProductsCopy />
        </>
      ) : page_title == "brands" ? (
        <>
          <ProductsCopy />
        </>
      ) : (
        <>
          <Products />
        </>
      )}

      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
