import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ImmediateService } from "./ImmediateService";
import { PreOrder } from "./PreOrder";

export const CartData = ({ langSwitcRes }) => {
  const [checkoutData, setCheckoutData] = useState({
    preorderData: [],
    immediateServiceData: [],
  });

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const resGeneral = res?.data?.group;

  //Cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  useEffect(() => {
    const preSale = [];
    const immediateSer = [];

    Object.keys(addToCartRes)?.map((e) => {
      if (
        addToCartRes[e]?.pre_sale == 0 &&
        resGeneral == "retailer" &&
        addToCartRes[e]?.min_qty_retailer > addToCartRes[e]?.b2b_qty
      ) {
        preSale?.push(addToCartRes[e]);
      } else if (
        addToCartRes[e]?.pre_sale == 0 &&
        resGeneral == "wholesaler" &&
        addToCartRes[e]?.min_qty_wholesaler > addToCartRes[e]?.b2b_qty
      ) {
        preSale?.push(addToCartRes[e]);
      } else if (addToCartRes[e]?.pre_sale == 1) {
        preSale?.push(addToCartRes[e]);
      } else {
        immediateSer?.push(addToCartRes[e]);
      }
    });

    setCheckoutData({
      ...checkoutData,
      preorderData: preSale,
      immediateServiceData: immediateSer,
    });
  }, [addToCartRes]);

  //Date

  // const d = new Date();
  // const month = d.getMonth() + 1;
  // const showTodayDate = d.getDate() + "/" + month + "/" + d.getFullYear();

  return (
    <>
      {Object.keys(addToCartRes).length === 0 && (
        <>
          <tr>
            <td valign="top" colspan="9" className="text-center cartempty">
              <strong>Your Cart is Empty</strong>
              <br />
              {t("empty_cart_message")}
            </td>
          </tr>
        </>
      )}

      {checkoutData?.immediateServiceData?.length > 0 && (
        <ImmediateService
          immediateServiceData={checkoutData}
          langSwitcRes={langSwitcRes}
        />
      )}

      {res?.status === 200 && checkoutData?.preorderData?.length > 0 ? (
        <>
          <PreOrder preorderData={checkoutData} langSwitcRes={langSwitcRes} />
        </>
      ) : (
        ""
      )}
    </>
  );
};
