import React from "react";
import { BiTrash } from "react-icons/bi";
import { FiRefreshCcw } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Td, Tr } from "react-super-responsive-table";
import { baseUrlProduct } from "../../../../config";
import {
  addToCartApi,
  qtyDecrement,
  qtyDecrementRetailer,
  qtyIncrement,
  qtyIncrementRetailer,
  removeApiAction,
} from "../../../../Redux/Action";

export const PreOrderReview = (props) => {
  const dispatch = useDispatch();

  const { langSwitcRes, preorderData } = props;

  const preorderProducts = preorderData?.preorderData;

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  const resGeneral = res?.data?.group;

  const quantityRes = useSelector((state) => state?.quantity);

  const qtyRetailerRes = useSelector((state) => state?.quantityRetailer);

  const userID = res?.data?.userid;

  const cartId = useSelector((state) => state?.cart);

  return (
    <>
      {Object.keys(preorderProducts).length > 0 ? (
        <>
          <Tr>
            <Td valign="top" colspan="9" className="text-center redhighlight">
              PRE-ORDER
            </Td>
          </Tr>
        </>
      ) : (
        ""
      )}

      {Object.keys(preorderProducts)?.map((e, i) => {
        const formatData = preorderProducts[e]?.productnames
          ?.filter((e) => e?.lang == langSwitcRes)
          ?.map((Pname, i) => {
            return <React.Fragment key={i}>{Pname?.name}</React.Fragment>;
          });
        return (
          <React.Fragment key={i}>
            <tr data-testid="tr">
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  PIC
                </div>
                <a
                  href={`/${langSwitcRes}/ProductDetails/${preorderProducts[e]?.slug}`}
                >
                  <img
                    src={`${baseUrlProduct}/${preorderProducts[e]?.image}`}
                    alt="text"
                    className="thumbnail"
                  />
                </a>
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  PRODUCT NAME
                </div>
                <div className="product-name">
                  <a
                    href={`/${langSwitcRes}/ProductDetails/${preorderProducts[e]?.slug}`}
                  >
                    {formatData}
                  </a>
                </div>
                <div className="item-error">
                  * Pre order: {preorderProducts[e]?.qty} Qty{" "}
                  {preorderProducts[e]?.preorder_date}
                </div>
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  SKU
                </div>
                {preorderProducts[e]?.product_sku}
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  PRICE
                </div>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(preorderProducts[e]?.before_price).toLocaleString(
                      "es-ES",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(preorderProducts[e]?.before_price).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : (
                  <>{Number(preorderProducts[e]?.before_price).toFixed(2)}</>
                )}
                €
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  DISCOUNT
                </div>
                {preorderProducts[e]?.discount == "undefined" ? (
                  <>0 %</>
                ) : (
                  <>{preorderProducts[e]?.discount} %</>
                )}
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  CLIENT PRICE
                </div>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(preorderProducts[e]?.price).toLocaleString(
                      "es-ES",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(preorderProducts[e]?.price).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </>
                ) : (
                  <>{Number(preorderProducts[e]?.price).toFixed(2)}</>
                )}
                <span> €</span>
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  QUANTITY
                </div>

                {res?.status === 200 && resGeneral === "retailer" ? (
                  <>
                    <div className="cartwrap-three cartmt mt-1">
                      <div className="cartwrap-left-two">
                        <button
                          className="btn btn-dgray btn-lg"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.min_qty_retailer,
                                preorderProducts[e]?.b2b_qty,
                                preorderProducts[e]?.pre_order_qty,
                                preorderProducts[e]?.pre_sale
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                      <div className="cartwrap-right">
                        <button
                          className="btn btn-dgray btn-lg"
                          id="minus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyDecrementRetailer(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.min_qty_retailer
                              )
                            );
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                      <div className="cartwrap-center-three">
                        <input
                          type="text"
                          id="qty_input"
                          className="form-control-two form-control-sm"
                          min={preorderProducts[e]?.min_qty_retailer}
                          value={
                            qtyRetailerRes[preorderProducts[e]?.product_sku] ||
                            preorderProducts[e]?.min_qty_retailer
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                  <>
                    <div className="cartwrap-three cartmt mt-1">
                      <div className="cartwrap-left-two">
                        <button
                          className="btn btn-dgray btn-lg"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrementRetailer(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.min_qty_wholesaler,
                                preorderProducts[e]?.b2b_qty,
                                preorderProducts[e]?.pre_order_qty,
                                preorderProducts[e]?.pre_sale
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                      <div className="cartwrap-right">
                        <button
                          className="btn btn-dgray btn-lg"
                          id="minus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyDecrementRetailer(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.min_qty_wholesaler
                              )
                            );
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                      <div className="cartwrap-center-three">
                        <input
                          type="text"
                          id="qty_input"
                          className="form-control-two form-control-sm"
                          min={preorderProducts[e]?.min_qty_wholesaler}
                          value={
                            qtyRetailerRes[preorderProducts[e]?.product_sku] ||
                            preorderProducts[e]?.min_qty_wholesaler
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="cartwrap-three cartmt mt-1">
                      <div className="cartwrap-left-two">
                        <button
                          className="btn btn-dgray btn-lg"
                          id="plus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyIncrement(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.b2c_qty
                              )
                            );
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                      <div className="cartwrap-right">
                        <button
                          className="btn btn-dgray btn-lg"
                          id="minus-btn"
                          onClick={(event) => {
                            event.preventDefault();
                            dispatch(
                              qtyDecrement(
                                preorderProducts[e]?.product_sku,
                                preorderProducts[e]?.b2c_qty
                              )
                            );
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                      <div className="cartwrap-center-three">
                        <input
                          type="text"
                          id="qty_input"
                          className="form-control-two form-control-sm"
                          min="1"
                          value={
                            quantityRes[preorderProducts[e]?.product_sku] || 1
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </td>
              <td data-testid="td" className=" pivoted">
                <div data-testid="td-before" className="tdBefore">
                  SUB TOTAL
                </div>
                {langSwitcRes === "es" ? (
                  <>
                    {Number(
                      preorderProducts[e]?.qty * preorderProducts[e]?.price
                    ).toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : langSwitcRes === "fr" ? (
                  <>
                    {Number(
                      preorderProducts[e]?.qty * preorderProducts[e]?.price
                    ).toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                  </>
                ) : (
                  <>
                    {" "}
                    {Number(
                      preorderProducts[e]?.qty * preorderProducts[e]?.price
                    ).toFixed(2)}{" "}
                  </>
                )}
                <span> €</span>
              </td>
              <td data-testid="td" className="text-center pivoted">
                <div data-testid="td-before" className="tdBefore">
                  ACTION
                </div>
                <Link to="">
                  <Td className="text-center">
                    <Link to="">
                      <BiTrash
                        size={24}
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(
                            removeApiAction(
                              preorderProducts[e]?.cart_id,
                              preorderProducts[e]?.product_sku
                            )
                          );
                        }}
                      />
                    </Link>
                    <Link to="">
                      {res?.status === 200 && resGeneral === "retailer" ? (
                        <>
                          <FiRefreshCcw
                            size={20}
                            className="mx-2"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  preorderProducts[e],
                                  res,
                                  qtyRetailerRes[
                                    preorderProducts[e]?.product_sku
                                  ] || preorderProducts[e]?.min_qty_retailer,
                                  userID,
                                  cartId[preorderProducts?.product_sku]
                                    ?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  langSwitcRes
                                  // e?.discount,
                                )
                              );
                            }}
                          />
                        </>
                      ) : res?.status === 200 && resGeneral === "wholesaler" ? (
                        <>
                          <FiRefreshCcw
                            size={20}
                            className="mx-2"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  preorderProducts[e],
                                  res,
                                  qtyRetailerRes[
                                    preorderProducts[e]?.product_sku
                                  ] || preorderProducts[e]?.min_qty_wholesaler,
                                  userID,
                                  cartId[preorderProducts?.product_sku]
                                    ?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  langSwitcRes
                                  // e?.discount,
                                )
                              );
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <FiRefreshCcw
                            size={20}
                            className="mx-2"
                            onClick={(event) => {
                              event.preventDefault();
                              dispatch(
                                addToCartApi(
                                  preorderProducts[e],
                                  res,
                                  quantityRes[
                                    preorderProducts[e]?.product_sku
                                  ] || 1,
                                  userID,
                                  cartId[preorderProducts?.product_sku]
                                    ?.cart_id,
                                  priceRes,
                                  groupRes,
                                  discountRes,
                                  langSwitcRes
                                  // e?.discount,
                                )
                              );
                            }}
                          />
                        </>
                      )}
                    </Link>
                  </Td>
                </Link>
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </>
  );
};
