import React from "react";
import { useSelector } from "react-redux";
import { baseUrls } from "../../../../../config";

export const ProductBanner = () => {
  const contentTitle = useSelector((state) => state?.productListingData);

  return (
    <>
      <div className="banner-small-cont">
        <img
          className="d-block w-100"
          src={`${baseUrls}/${contentTitle?.product_listing_block_page_image}`}
          alt={contentTitle?.Product_listing_block_contentTitle}
        />
      </div>
    </>
  );
};
