import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { Footer } from "../../Common/Footer/Footer";
import { Header } from "../../Common/Header/Header";

export const Success = () => {
  //Login Response

  const res = useSelector((state) => state?.loginData);

  //Language switcher

  const langSwitcRes = useSelector((state) => state?.langSwitchData);

  //Payment

  const placeOrderRes = useSelector((state) => state?.placeOrderData);

  //Paypal Payment

  const placeOrderPaypalRes = useSelector(
    (state) => state?.placeOrderPaypalData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <Header pathData="thankyou" />

      <div className="page-body-container">
        <center>
          <h3>
            Thank you for shopping with us! we will send you the invoice shortly
          </h3>
          <h4 className="text-success">
            Your Order id :
            {Object.keys(placeOrderPaypalRes)?.length > 0 ? (
              <>{placeOrderPaypalRes?.data?.message?.order_id}</>
            ) : (
              <>{placeOrderRes?.data?.message?.order_id} </>
            )}
          </h4>
          <Link to={`/${langSwitcRes}/women`}>
            <button className="blackbutton">Click here to shop now</button>
          </Link>
        </center>
      </div>

      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
