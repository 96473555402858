import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { baseUrls } from "../../../../config/index";
import { brandPageAction } from "../../../../Redux/Action";

export const BrandLicense = () => {
  const dispatch = useDispatch();

  const { slugData, langSwitcRes } = useParams();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const groupRes = res?.data?.group;

  const priceRes = res?.data?.price_list;

  const discountRes = res?.data?.linear_discount;

  useEffect(() => {
    dispatch(
      brandPageAction(
        langSwitcRes,
        slugData,
        res,
        groupRes,
        priceRes,
        discountRes
      )
    );
  }, [langSwitcRes, slugData, res, groupRes, priceRes, discountRes, dispatch]);

  const brandPageRes = useSelector(
    (state) => state?.brandPageData?.brand_block
  );

  const brandConRes = useSelector(
    (state) => state?.brandPageData?.brand_block_content
  );

  return (
    <>
      <div className="licensedproducts-cont">
        {brandConRes
          ?.filter((e) => e?.language === langSwitcRes)
          ?.map((e, i) => {
            return (
              <React.Fragment key={i}>
                <div className="section-heading text-uppercase">
                  {e?.tittle}
                </div>
                <p className="section-paragraph">
                  <span dangerouslySetInnerHTML={{ __html: e?.content }}></span>
                </p>
              </React.Fragment>
            );
          })}

        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2">
            {brandPageRes?.slice(1, 2)?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={e?.title} />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.product_keyword}</h2>
                        <p className="lptbg">
                          {/* <Link
                            to={`/${langSwitcRes}/${slugData}/${e?.product_keyword}`}
                            title={e?.description}
                          > */}
                          {e?.description}
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {brandPageRes?.slice(0, 1)?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={e?.title} />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.product_keyword}</h2>
                        <p className=" lptbg">
                          {/* <Link
                            to={`/${langSwitcRes}/${slugData}/${e?.product_keyword}`}
                            title={e?.description}
                          > */}
                          {e?.description}
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {brandPageRes?.slice(4, 5)?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className="col-12 col-lg-12 col-md-12 col-xl-12 gx-0"
                    key={i}
                  >
                    <div className="graybg-bg">
                      <img
                        src={`${baseUrls}/${e?.image}`}
                        alt={e?.title}
                        className="w-100"
                      />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.product_keyword}</h2>
                        <p className=" lptbg">
                          {/* <Link
                            to={`/${langSwitcRes}/${slugData}/${e?.product_keyword}`}
                            title={e?.description}
                          > */}
                          {e?.description}
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {brandPageRes?.slice(2, 3)?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={e?.title} />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.product_keyword}</h2>
                        <p className=" lptbg">
                          {/* <Link
                            to={`/${langSwitcRes}/${slugData}/${e?.product_keyword}`}
                            title={e?.description}
                          > */}
                          {e?.description}
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}

            {brandPageRes?.slice(3, 4)?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="col gx-0">
                    <div className="graybg-sm">
                      <img src={`${baseUrls}/${e?.image}`} alt={e?.title} />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.product_keyword}</h2>
                        <p className=" lptbg">
                          {/* <Link
                            to={`/${langSwitcRes}/${slugData}/${e?.product_keyword}`}
                            title={e?.description}
                          > */}
                          {e?.description}
                          {/* </Link> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
