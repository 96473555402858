const initialState = {
  logoData: [],
  langSwitchData: "",
  sliderData: [],
  searchData: [],
  langData: [],
  HomePageData: [],
  categoryData: [],
  menuData: {},
  productListingData: [],
  productFiltersData: [],
  resetPassData: [],
  loginData: [],
  loginSuccess: { errorInfo: "", successMsg: "" },
  forgetPassData: { errorInfo: "", successMsg: "" },
  individualData: { errorInfo: "", successMsg: "" },
  shopData: { errorInfo: "", successMsg: "" },
  countryData: [],
  logoutData: [],
  accountInfoData: [],
  customerAreaData: [],
  myAddressData: [],
  myOrdersData: [],
  myOrdersDetailsData: [],
  myInVoiceData: [],
  myCatalogData: [],
  brandListData: [],
  productTypeListData: [],
  productDetailsData: [],
  cartData: [],
  launchData: [],
  checkoutGuestData: [],
  billingData: [],
  changeBillingData: [],
  shippingData: [],
  changeShippingData: [],
  regContentData: [],
  incrementData: 1,
  qtyData: {},
  quantity: {},
  quantityRetailer: {},
  cart: {},
  addCartData: [],
  removeFromCartData: [],
  paymentData: [],
  editAddressData: [],
  changePasswordData: { errorInfo: "", successMsg: "" },
  contactFormData: { errorInfo: "", successMsg: "" },
  aboutusData: [],
  placeOrderData: [],
  legalWarningData: [],
  contactusData: [],
  shippingReturnData: [],
  securePaymentData: [],
  cookiesData: [],
  productSortData: [],
  promoCodeData: { applyCodeRes: "", errorInfo: "", successMsg: "" },
  loginCustomerData: [],
  cartBtnData: {},
  placeOrderPaypalData: [],
  makePaymentData: [],
  stepsData: {},
  editShippingData: [],
  brandPageData: [],
  loginContentData: [],
  editBillShiData: {},
  deleteAddressData: {},
  reCaptchaData: {},
  pageNotFoundData: {},
};

export const Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    //Logo

    case "LANGUAGESWITCHER":
      return { ...state, langSwitchData: payload };

    //Search

    case "SEARCH_API":
      return { ...state, searchData: payload };

    //Logo

    case "LOGOAPI":
      return { ...state, logoData: payload };

    //Brand

    case "BRAND_API":
      return { ...state, brandPageData: payload };

    //About us

    case "ABOUT_US":
      return { ...state, aboutusData: payload };

    //Contact us

    case "CONTACT_US":
      return { ...state, contactusData: payload };

    //Page not found

    case "PAGE_NOT_FOUND":
      return { ...state, pageNotFoundData: payload };

    // Legal Warning

    case "LEGAL_NOTICE":
      return { ...state, legalWarningData: payload };

    // Shipping Return

    case "SHIPPING_RETURN":
      return { ...state, shippingReturnData: payload };

    // Shipping Return

    case "SECURE_PAYMENT":
      return { ...state, securePaymentData: payload };

    // Cookies

    case "COOKIES":
      return { ...state, cookiesData: payload };

    //slider

    case "SLIDERAPI":
      return { ...state, sliderData: payload };

    //Language

    case "LANGAPI":
      return { ...state, langData: payload };

    //Menu API

    case "MENU_API":
      return {
        ...state,
        menuData: {
          ...state?.menuData,
          [payload?.slugname]: {
            res: payload?.res?.data,
            langSwitcRes: payload?.langSwitcRes,
          },
        },
      };

    //Edit address

    case "EDITADDRESS_API":
      return { ...state, editBillShiData: payload };

    //Delete address

    case "DELETE_ADDRESS_API":
      return { ...state, myAddressData: payload };

    //Home Page Product Listing APIs

    case "HOMEPRODUCTAPI":
      return { ...state, HomePageData: payload };

    //Category API

    case "CATEGORYAPI":
      return { ...state, categoryData: payload };

    //ProductListing API

    case "PRODUCTLISTINGAPI":
      return { ...state, productListingData: payload };

    //Product Details API

    case "PRODUCT_DETAILS_API":
      return { ...state, productDetailsData: payload };

    //Product Sort API

    case "PRODUCT_SORT_API":
      return { ...state, productSortData: payload };

    //Product Details API

    case "PRODUCT_FILTERS_API":
      return { ...state, productFiltersData: payload };

    //Brand List API

    case "BRANDLISTAPI":
      return { ...state, brandListData: payload };

    //ProductType List API

    case "PRODUCTTYPELISTAPI":
      return { ...state, productTypeListData: payload };

    //Login API

    case "LOGINAPI":
      return { ...state, loginData: payload };

    case "LOGIN_API_RESET":
      return { ...state, loginData: [] };

    //Forget Password API

    case "FORGOT_PASSWORD_API_SUCCESS":
      return {
        ...state,
        forgetPassData: {
          ...state?.forgetPassData,
          errorInfo: "",
          successMsg: payload?.message,
        },
      };

    case "FORGOT_PASSWORD_API_FAILURE":
      return {
        ...state,
        forgetPassData: {
          ...state?.forgetPassData,
          errorInfo: payload?.message,
          successMsg: "",
        },
      };

    case "FORGOT_PASSWORD_API_RESET":
      return {
        ...state,
        forgetPassData: {
          ...state?.forgetPassData,
          errorInfo: "",
          successMsg: "",
        },
      };

    //Reset Password API

    case "RESETPASSWORD":
      return { ...state, resetPassData: payload };

    //Register Content API

    case "REG_CONTENT_API":
      return { ...state, regContentData: payload };

    //Google Recaptcha

    case "GOOGLE_RECAPTCHA":
      return { ...state, reCaptchaData: payload };

    //Register Individual API

    case "INDIVIDUAL_REGISTER_API_SUCCESS":
      return {
        ...state,
        individualData: {
          ...state?.individualData,
          errorInfo: "",
          successMsg: payload?.message,
        },
      };

    case "INDIVIDUAL_REGISTER_API_FAILURE":
      return {
        ...state,
        individualData: {
          ...state?.individualData,
          errorInfo: payload?.message,
          successMsg: "",
        },
      };

    case "INDIVIDUAL_REGISTER_API_RESET":
      return {
        ...state,
        individualData: {
          ...state?.individualData,
          errorInfo: "",
          successMsg: "",
        },
      };

    //B2b register API

    case "B2B_REGISTER_API_SUCCESS":
      return {
        ...state,
        shopData: {
          ...state?.shopData,
          errorInfo: "",
          successMsg: payload?.message,
        },
      };

    case "B2B_REGISTER_API_FAILURE":
      return {
        ...state,
        shopData: {
          ...state?.shopData,
          errorInfo: payload?.message,
          successMsg: "",
        },
      };

    case "B2B_REGISTER_API_RESET":
      return {
        ...state,
        shopData: {
          ...state?.shopData,
          errorInfo: "",
          successMsg: "",
        },
      };

    //Country API

    case "COUNTRYAPI":
      return { ...state, countryData: payload };

    //Billing API

    case "BILLING_API":
      return { ...state, billingData: payload };

    //Change Billing API

    case "CHANGE_BILLING_API":
      return { ...state, changeBillingData: payload };

    //Shipping API

    case "SHIPPING_API":
      return { ...state, shippingData: payload };

    //Change Shipping API

    case "CHANGE_SHIPPING_API":
      return { ...state, changeShippingData: payload };

    //Edit Address API

    case "EDIT_ADDRESS_API":
      return { ...state, editAddressData: payload };

    //Logout API

    case "SIGNOUTAPI":
      return {
        ...state,
        logoutData: payload,
        loginData: [],
        cart: [],
        myAddressData: [],
        menuData: {},
      };

    //Account Info API

    case "ACCOUNTINFO":
      return { ...state, accountInfoData: payload };

    //My Addresses API

    case "MYADDRESS":
      return { ...state, myAddressData: payload };

    //Customer Area API

    case "CUSTOMERAREA":
      return { ...state, customerAreaData: payload };

    //My Orders API

    case "MYORDER":
      return { ...state, myOrdersData: payload };

    //My Orders Details API

    case "MYORDERDETAILS":
      return { ...state, myOrdersDetailsData: payload };

    //MyVoice API

    case "MYINVOICE":
      return { ...state, myInVoiceData: payload };

    //MyCatalog API

    case "MYCATALOG":
      return { ...state, myCatalogData: payload };

    //Mylaunch API

    case "MYLAUNCH":
      return { ...state, launchData: payload };

    //Checkout Guest API

    case "CHECKOUT_GUEST":
      return { ...state, checkoutGuestData: payload };

    //Add to Cart

    case "ADDTOCART":
      return {
        ...state,
        cart: {
          ...state?.cart,
          [payload?.cart_data[0]?.product_sku]: payload?.cart_data[0],
        },
      };

    case "MY_CART":
      return {
        ...state,
        addCartData: payload,
      };

    //Qty Increment API

    case "QTY_INCREMENT":
      if (state?.quantity[payload?.skuId]) {
        if (payload?.b2c_qty >= 7) {
          if (payload?.b2c_qty - 3 > state?.quantity[payload?.skuId] + 1) {
            return {
              ...state,
              quantity: {
                ...state?.quantity,
                [payload?.skuId]: state?.quantity[payload?.skuId] + 1,
              },
            };
          } else {
            return {
              ...state,
            };
          }
        }
      } else {
        return {
          ...state,
          quantity: {
            ...state?.quantity,
            [payload?.skuId]: 1 + 1,
          },
        };
      }

    //Qty Increment Retailer API

    case "QTY_INCREMENT_RETAILER":
      if (state?.quantityRetailer[payload?.skuId]) {
        if (
          payload?.b2b_qty >
          state?.quantityRetailer[payload?.skuId] + payload?.minQty
        ) {
          return {
            ...state,
            quantityRetailer: {
              ...state?.quantityRetailer,
              [payload?.skuId]:
                state?.quantityRetailer[payload?.skuId] + payload?.minQty,
            },
          };
        } else if (payload?.pre_sale == "1") {
          return {
            ...state,
            quantityRetailer: {
              ...state?.quantityRetailer,
              [payload?.skuId]:
                state?.quantityRetailer[payload?.skuId] + payload?.minQty,
            },
          };
        } else {
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
          quantityRetailer: {
            ...state?.quantityRetailer,
            [payload?.skuId]: payload?.minQty + payload?.minQty,
          },
        };
      }

    //Quantity Decrement

    case "QTY_DECREMENT":
      if (state?.quantity[payload]) {
        return {
          ...state,
          quantity: {
            ...state?.quantity,
            [payload]: state?.quantity[payload] - 1,
          },
        };
      }

      return {
        ...state,
        quantity: {
          ...state?.quantity,
          [payload]: 1,
        },
      };

    //Quantity Decrement Retailer

    case "QTY_DECREMENT_RETAILER":
      if (state?.quantityRetailer[payload?.skuId]) {
        return {
          ...state,
          quantityRetailer: {
            ...state?.quantityRetailer,
            [payload?.skuId]:
              state?.quantityRetailer[payload?.skuId] - payload?.minQty,
          },
        };
      } else {
        return {
          ...state,
          quantityRetailer: {
            ...state?.quantityRetailer,
            [payload?.skuId]: payload?.minQty,
          },
        };
      }

    //Cart btn

    case "CART_BUTTON":
      return {
        ...state,
        cartBtnData: {
          [payload?.sku]: true,
        },
      };

    //Remove item

    case "REMOVE_FROM_CART":
      const deleteCart = state?.cart;

      delete deleteCart[payload];
      return {
        ...state,
        cart: { ...deleteCart },
      };
    //Clear Cart

    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
      };

    //Payment

    case "PAYMENT_API":
      return {
        ...state,
        paymentData: payload,
      };

    //Place order

    case "PLACE_ORDER":
      return {
        ...state,
        placeOrderData: payload,
        cart: [],
      };

    //Place order btn for paypal

    case "PLACE_ORDER_PAYPAL":
      return {
        ...state,
        placeOrderPaypalData: payload,
      };

    //Promo code

    case "PROMO_CODE_API_SUCCESS":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          applyCodeRes: payload,
          errorInfo: "",
          successMsg: payload,
        },
      };

    case "PROMO_CODE_API_FAILURE":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          applyCodeRes: payload,
          errorInfo: payload,
          successMsg: "",
        },
      };

    case "PROMO_CODE_API_RESET":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          errorInfo: "",
          successMsg: "",
        },
      };

    case "PROMO_CODE_API_CART":
      return {
        ...state,
        promoCodeData: {
          ...state?.promoCodeData,
          applyCodeRes: "",
          errorInfo: "",
          successMsg: "",
        },
      };

    //Change password

    case "CHANGE_PASSWORD_API_SUCCESS":
      return {
        ...state,
        changePasswordData: {
          ...state?.changePasswordData,
          errorInfo: "",
          successMsg: payload?.message,
        },
      };

    case "CHANGE_PASSWORD_API_FAILURE":
      return {
        ...state,
        changePasswordData: {
          ...state?.changePasswordData,
          errorInfo: payload?.message,
          successMsg: "",
        },
      };

    case "CHANGE_PASSWORD_API_RESET":
      return {
        ...state,
        changePasswordData: {
          ...state?.changePasswordData,
          errorInfo: "",
          successMsg: "",
        },
      };

    //Contact form

    case "CONTACT_FORM_SUCCESS":
      return {
        ...state,
        contactFormData: {
          ...state?.contactFormData,
          errorInfo: "",
          successMsg: payload?.message,
        },
      };

    case "CONTACT_FORM_FAILURE":
      return {
        ...state,
        contactFormData: {
          ...state?.contactFormData,
          errorInfo: payload?.message,
          successMsg: "",
        },
      };

    case "CONTACT_FORM_RESET":
      return {
        ...state,
        contactFormData: {
          ...state?.contactFormData,
          errorInfo: "",
          successMsg: "",
        },
      };

    //Make payment

    case "MAKE_PAYMENT_API":
      return {
        ...state,
        makePaymentData: payload,
        cart: [],
      };

    //Make payment for cancel

    case "MAKE_PAYMENT":
      return {
        ...state,
        makePaymentData: payload,
      };

    //Login as customer

    case "LOGIN_AS_CUSTOMER":
      return {
        ...state,
        loginCustomerData: payload,
      };

    //Steps

    case "STEPS":
      const stepData = {};
      payload?.length > 0 &&
        payload?.forEach((item) => {
          stepData[item?.step] = item?.stepValue;
        });
      return {
        ...state,
        stepsData: {
          ...stepData,
        },
      };

    //Edit shipping

    case "EDIT_SHIPPING_API":
      return {
        ...state,
        editShippingData: payload,
      };

    //Regsiter content

    case "LOGIN_CONTENT":
      return {
        ...state,
        loginContentData: payload,
      };

    default:
      return state;
  }
};
