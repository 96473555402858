import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { Footer } from "../../../Common/Footer/Footer";
import { Header } from "../../../Common/Header/Header";
import "../EmptyCart/EmptyCart.css";

export const EmptyCart = (props) => {
  const { loginRes } = props;

  const { langSwitcRes } = useParams();

  //Translation

  const { t } = useTranslation();

  return (
    <>
      <Header pathData="emptycart" />

      <div class="main_inner">
        <div class="empty_cart_title">
          <h1>{t("shopping_cart_is_empty")}</h1>
        </div>
        <div class="empty_cart_wrap">
          <p class="label">{t("there_are_no_products")}</p>
          <p>{t("you_can_start_shopping")}</p>
          <div class="title_divider">&nbsp;</div>
          <p className="m-5">
            <a
              href={`/${langSwitcRes}`}
              className="blackbutton text-decoration-none"
            >
              <span>{t("back_to_home")}</span>
            </a>{" "}
          </p>
        </div>
      </div>

      <Footer />
      {loginRes?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
