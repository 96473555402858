import axios from "axios";
import { baseUrl } from "../config/index";

export const languageSelector = (language) => {
  return {
    type: "LANGUAGESWITCHER",
    payload: language,
  };
};

//Logo API

export const logos = () => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/listlogo`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "LOGOAPI", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//About us

export const aboutUsAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/aboutus/${langSwitcRes}/about-us`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "ABOUT_US", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Contact us

export const contactUsAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/aboutus/${langSwitcRes}/contact`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "CONTACT_US", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Legal Warning

export const legalWarningAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/aboutus/${langSwitcRes}/legal-notice`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "LEGAL_NOTICE", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Shipping Returns

export const shippingReturnAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/aboutus/${langSwitcRes}/shipping-returns`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "SHIPPING_RETURN", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Secure payment

export const securePaymentAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/aboutus/${langSwitcRes}/secure-payment`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "SECURE_PAYMENT", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Secure payment

export const cookiesAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/aboutus/${langSwitcRes}/cookies`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "COOKIES", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Search API

export const searchAction = (values, langSwitcRes, loginRes, navigate) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200 && langSwitcRes) {
        const url = `${baseUrl}/search/${values}/${langSwitcRes}?price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "SEARCH_API", payload: res.data });
            navigate(`/${langSwitcRes}/search/value=${values}`);
          }
        });
      } else {
        const url = `${baseUrl}/search/${values}/${langSwitcRes}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "SEARCH_API", payload: res.data });
            navigate(`/${langSwitcRes}/search/value=${values}`);
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Slider API

export const sliders = () => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/listhomeslider`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "SLIDERAPI", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Language API

export const languages = () => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/listlang`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "LANGAPI", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Home Page Products API

export const homePageCategory = (loginRes, langSwitcRes) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200 && langSwitcRes) {
        const url = `${baseUrl}/homepagecategory/${langSwitcRes}?price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "HOMEPRODUCTAPI", payload: res.data });
          }
        });
      } else if (loginRes?.status === 200 && loginRes && !langSwitcRes) {
        const url = `${baseUrl}/homepagecategory/${loginRes?.data?.lang}?price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "HOMEPRODUCTAPI", payload: res.data });
          }
        });
      } else if (langSwitcRes) {
        const url = `${baseUrl}/homepagecategory/${langSwitcRes}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "HOMEPRODUCTAPI", payload: res.data });
          }
        });
      } else if (!loginRes?.status === 200) {
        const url = `${baseUrl}/homepagecategory/es`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "HOMEPRODUCTAPI", payload: res.data });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Header menu

export const menuAction = (langSwitcRes, loginRes, slug_id, slugname) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200) {
        const url = `${baseUrl}/getCategories/${langSwitcRes}?group=${loginRes?.data?.group}&id=${slug_id}&slug_name=${slugname}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({
              type: "MENU_API",
              payload: { res, slugname, langSwitcRes },
            });
          }
        });
      } else {
        const url = `${baseUrl}/getCategories/${langSwitcRes}?group=general&id=${slug_id}&slug_name=${slugname}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({
              type: "MENU_API",
              payload: { res, slugname, langSwitcRes },
            });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Category API

export const pageCategories = (langSwitcRes, slugName, loginRes) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200) {
        const url = `${baseUrl}/pagecategory/${langSwitcRes}/${slugName}?price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "CATEGORYAPI", payload: res.data });
          }
        });
      } else {
        const url = `${baseUrl}/pagecategory/${langSwitcRes}/${slugName}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "CATEGORYAPI", payload: res.data });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Brand Page

export const brandPageAction = (
  langSwitcRes,
  slugName,
  loginRes,
  groupRes,
  priceRes,
  discountRes
) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200) {
        const url = `${baseUrl}/brandPagecategory/${langSwitcRes}/${slugName}?price_list=${priceRes}&group=${groupRes}&linear_discount=${discountRes}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "BRAND_API", payload: res.data });
          }
        });
      } else {
        const url = `${baseUrl}/brandPagecategory/${langSwitcRes}/${slugName}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "BRAND_API", payload: res.data });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Product Listing API

export const productListingAction = (
  title,
  page_title,
  loginRes,
  langSwitcRes,
  pageCount,
  sortFilter,
  tempPageCount
) => {
  try {
    return async (dispatch) => {
      if (title) {
        if (loginRes?.status === 200) {
          const url = `${baseUrl}/productlistingpage/${langSwitcRes}/${page_title}/${title}?price_list=${
            loginRes?.data?.price_list
          }&group=${loginRes?.data?.group}&linear_discount=${
            loginRes?.data?.linear_discount
          }&page=${
            tempPageCount && tempPageCount !== 1 && pageCount === 1
              ? tempPageCount
              : pageCount
          }&sortOption=${sortFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCTLISTINGAPI", payload: res.data });
            }
          });
        } else {
          const url = `${baseUrl}/productlistingpage/${langSwitcRes}/${page_title}/${title}?page=${
            tempPageCount && tempPageCount !== 1 && pageCount === 1
              ? tempPageCount
              : pageCount
          }&sortOption=${sortFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCTLISTINGAPI", payload: res.data });
            }
          });
        }
      } else if (page_title) {
        if (loginRes?.status === 200) {
          const url = `${baseUrl}/productlistingpage/${langSwitcRes}/${page_title}/${page_title}?price_list=${
            loginRes?.data?.price_list
          }&group=${loginRes?.data?.group}&linear_discount=${
            loginRes?.data?.linear_discount
          }&page=${
            tempPageCount && tempPageCount !== 1 && pageCount === 1
              ? tempPageCount
              : pageCount
          }&sortOption=${sortFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCTLISTINGAPI", payload: res.data });
            }
          });
        } else {
          const url = `${baseUrl}/productlistingpage/${langSwitcRes}/${page_title}/${page_title}?page=${
            tempPageCount && tempPageCount !== 1 && pageCount === 1
              ? tempPageCount
              : pageCount
          }&sortOption=${sortFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCTLISTINGAPI", payload: res.data });
            }
          });
        }
      } else {
        const url = `${baseUrl}/productlistingpage/${langSwitcRes}/${page_title}/${page_title}?page=${
          tempPageCount && tempPageCount !== 1 && pageCount === 1
            ? tempPageCount
            : pageCount
        }&sortOption=${sortFilter}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "PRODUCTLISTINGAPI", payload: res.data });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Brand Listing API

export const brandListAction = (id, langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/brandtypelisting/${langSwitcRes}/${id}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "BRANDLISTAPI", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//ProductType Listing API

// export const productTypeListAction = (productId, langSwitcRes) => {
//   try {
//     return async (dispatch) => {
//       const url = `${baseUrl}/producttypelisting/${langSwitcRes}/${productId}`;
//       await axios.get(url).then((res) => {
//         if (res.status === 200) {
//           dispatch({ type: "PRODUCTTYPELISTAPI", payload: res.data });
//         }
//       });
//     };
//   } catch (error) {
//      alert(`Error has occurred!`);
//   }
// };

//Product Details API

export const productDetailsAction = (loginRes, slug, slugSku, langSwitcRes) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200) {
        const url = `${baseUrl}/product_details/${langSwitcRes}/${slug}/${slugSku}?price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "PRODUCT_DETAILS_API", payload: res?.data });
          }
        });
      } else {
        const url = `${baseUrl}/product_details/${langSwitcRes}/${slug}/${slugSku}`;
        await axios.get(url).then((res) => {
          if (
            res.status === 200 &&
            res?.data?.product_details[0]?.sku == slugSku
          ) {
            dispatch({ type: "PRODUCT_DETAILS_API", payload: res?.data });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Sort Dropdown Subcategories

export const productSortAction = (
  subCatId,
  page_title,
  title,
  langSwitcRes
) => {
  try {
    return async (dispatch) => {
      if (subCatId?.length > 4) {
        if (title) {
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${subCatId}&producttype=${title}&subcat=`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_SORT_API", payload: res.data });
            }
          });
        } else {
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${subCatId}&producttype=&subcat=`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_SORT_API", payload: res.data });
            }
          });
        }
      } else {
        const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=&producttype=&subcat=${subCatId}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "PRODUCT_SORT_API", payload: res.data });
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Product Filters API

export const productFiltersAction = (
  page_title,
  title,
  subCatName,
  langSwitcRes,
  loginRes,
  brandValue,
  productTypeValue,
  categoryDropdown,
  colorFilter,
  selectedValue,
  priceFilter,
  itemOffset,
  brandSelectedValue
) => {
  try {
    return async (dispatch) => {
      if (
        (brandValue?.length > 0 ||
          Object.keys(selectedValue)?.length > 0 ||
          Object.keys(subCatName)?.length > 0 ||
          Object.keys(brandSelectedValue)?.length > 0 ||
          productTypeValue?.length > 0) &&
        page_title &&
        !title
      ) {
        // megamenu [casual,brands etc]

        if (loginRes?.status === 200) {
          const productData =
            selectedValue?.name === "productType"
              ? [...productTypeValue, selectedValue?.value]
              : productTypeValue;
          const brandData =
            selectedValue?.name === "brandType"
              ? [...brandValue, selectedValue?.value]
              : brandValue;
          const subCatIdName =
            brandSelectedValue?.name === "subcat_id"
              ? [...categoryDropdown, brandSelectedValue?.value]
              : categoryDropdown;
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${brandData}&producttype=${productData}&color=${colorFilter}&subcat=${subCatIdName}&price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}&page=${itemOffset}&sortOption=${priceFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
            }
          });
        } else {
          const productData =
            selectedValue?.name === "productType"
              ? [...productTypeValue, selectedValue?.value]
              : productTypeValue;
          const brandData =
            selectedValue?.name === "brandType"
              ? [...brandValue, selectedValue?.value]
              : brandValue;

          const subCatIdName =
            brandSelectedValue?.name === "subcat_id"
              ? [...categoryDropdown, brandSelectedValue?.value]
              : categoryDropdown;

          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${brandData}&producttype=${productData}&color=${colorFilter}&subcat=${
            brandData?.length > 0 ? subCatIdName : ""
          }&page=${itemOffset}&sortOption=${priceFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
            }
          });
        }
      } else if (
        page_title &&
        title &&
        brandValue?.length == 0 &&
        (selectedValue?.name == "productType" || productTypeValue?.length > 0)
      ) {
        // brands under casual && brands under categories

        if (loginRes?.status === 200) {
          const productData =
            selectedValue?.name === "productType"
              ? [...productTypeValue, selectedValue?.value]
              : productTypeValue;
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${title}&producttype=${productData}&color=${colorFilter}&price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}&page=${itemOffset}&sortOption=${priceFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
            }
          });
        } else {
          const productData =
            selectedValue?.name === "productType"
              ? [...productTypeValue, selectedValue?.value]
              : productTypeValue;
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${title}&producttype=${productData}&color=${colorFilter}&page=${itemOffset}&sortOption=${priceFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
            }
          });
        }
      } else if (
        page_title &&
        title &&
        productTypeValue?.length == 0 &&
        (selectedValue?.name == "brandType" || brandValue?.length > 0)
      ) {
        // products under casual && products under categories

        if (loginRes?.status === 200) {
          const brandData =
            selectedValue?.name === "brandType"
              ? [...brandValue, selectedValue?.value]
              : brandValue;
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${brandData}&producttype=${title}&color=${colorFilter}&price_list=${loginRes?.data?.price_list}&group=${loginRes?.data?.group}&linear_discount=${loginRes?.data?.linear_discount}&page=${itemOffset}&sortOption=${priceFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
            }
          });
        } else {
          const brandData =
            selectedValue?.name === "brandType"
              ? [...brandValue, selectedValue?.value]
              : brandValue;
          const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=${brandData}&producttype=${title}&color=${colorFilter}&page=${itemOffset}&sortOption=${priceFilter}`;
          await axios.get(url).then((res) => {
            if (res.status === 200) {
              dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
            }
          });
        }
      } else if (
        colorFilter?.length > 0 &&
        brandValue?.length === 0 &&
        productTypeValue?.length === 0
      ) {
        const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=&producttype=&color=${colorFilter}&subcat=&page=${itemOffset}&sortOption=${priceFilter}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
          }
        });
      } else if (
        priceFilter?.length > 0 &&
        brandValue?.length === 0 &&
        productTypeValue?.length === 0
      ) {
        const url = `${baseUrl}/product_filter/${langSwitcRes}/${page_title}?brandtype=&producttype=&color=&subcat=&page=${itemOffset}&sortOption=${priceFilter}`;
        await axios.get(url).then((res) => {
          if (res.status === 200) {
            dispatch({ type: "PRODUCT_FILTERS_API", payload: res.data });
          }
        });
      }
    };
  } catch (error) {}
};

//Login API

export const login = (
  values,
  regData,
  { resetForm },
  navigate,
  langSwitcRes,
  name,
  setStep
) => {
  try {
    const rawData = {
      email: values.Lemail,
      password: values.Lpassword,
    };
    return async (dispatch) => {
      const url = `${baseUrl}/${langSwitcRes}/login`;
      await axios
        .post(url, rawData, {
          headers: {
            Authorization: `Bearer ${regData}`,
          },
        })
        .then((res) => {
          dispatch({ type: "LOGINAPI", payload: res });
          if (res?.status === 200 && name == "login") {
            navigate(`/${res?.data?.lang}/myaccount`);
            resetForm();
          } else if (res?.status === 200 && name == "loginasguest") {
            setStep("BILLING INFORMATION");
          }
        });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Login Failure

export const loginResetAction = () => {
  return {
    type: "LOGIN_API_RESET",
  };
};

//Forget Password API

export const password = (values, { resetForm }, langSwitcRes) => {
  try {
    const formData = {
      password_email: values.email,
    };
    return async (dispatch) => {
      const url = `${baseUrl}/${langSwitcRes}/forget_password`;
      await axios.post(url, formData).then((res) => {
        if (res?.status === 200) {
          dispatch(forgotPasswordSuccessAction(res?.data));
          resetForm();
        } else {
          dispatch(forgotPasswordErrorAction(res?.data));
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//forgot password success

export const forgotPasswordSuccessAction = (pData) => {
  return {
    type: "FORGOT_PASSWORD_API_SUCCESS",
    payload: pData,
  };
};

//forgot password failure

export const forgotPasswordErrorAction = (pData) => {
  return {
    type: "FORGOT_PASSWORD_API_FAILURE",
    payload: pData,
  };
};

//forgot password Reset

export const forgotResetAction = () => {
  return {
    type: "FORGOT_PASSWORD_API_RESET",
  };
};

//Reset password

export const resetPasswords = (
  values,
  { resetForm },
  forgotToken,
  navigate,
  langSwitcRes
) => {
  try {
    const formData = {
      password: values.password,
      confirm_password: values.confirm,
      token: forgotToken,
    };
    return async (dispatch) => {
      const url = `${baseUrl}/${langSwitcRes}/resetpassword`;
      await axios.post(url, formData).then((res) => {
        dispatch({ type: "RESETPASSWORD", payload: res });
        if (res?.status === 200) {
          resetForm();
          setTimeout(() => {
            navigate(`/${langSwitcRes}/login`);
          }, 5000);
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Google recaptcha

export const reCaptchaApi = (captchaToken, key) => {
  try {
    const formData = {
      secret: captchaToken,
      response: key,
    };
    return async (dispatch) => {
      const url = "https://www.google.com/recaptcha/api/siteverify";
      await axios.post(url, formData).then((res) => {
        if (res?.status === 200) {
          dispatch({ type: "GOOGLE_RECAPTCHA", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Individual register API

export const registerApi = (values, { resetForm }, langSwitcRes, navigate) => {
  try {
    const formData = {
      first_name: values.first,
      last_name: values.last,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm,
      item_name: values.itemName,
      language: langSwitcRes,
    };
    return async (dispatch) => {
      const url = `${baseUrl}/register_user`;
      await axios.post(url, formData).then((res) => {
        if (res?.status === 200) {
          dispatch(individualSuccessAction(res?.data));
          resetForm();
          setTimeout(() => {
            navigate(`/${langSwitcRes}/login`);
          }, 5000);
        } else {
          dispatch(individualErrorAction(res?.data));
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Individual register success

export const individualSuccessAction = (pData) => {
  return {
    type: "INDIVIDUAL_REGISTER_API_SUCCESS",
    payload: pData,
  };
};

//Individual register failure

export const individualErrorAction = (pData) => {
  return {
    type: "INDIVIDUAL_REGISTER_API_FAILURE",
    payload: pData,
  };
};

//Individual register Reset

export const individualResetAction = () => {
  return {
    type: "INDIVIDUAL_REGISTER_API_RESET",
  };
};

//Register Content

export const registerContentAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/profileregister/${langSwitcRes}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "REG_CONTENT_API", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//B2B Registration API

export const registerShopApi = (values, { resetForm }, langSwitcRes) => {
  try {
    const formData = {
      first_name: values.first,
      last_name: values.last,
      company: values.company,
      web: values.web,
      tax_vat_number: values.vatNo,
      country: values.countryId,
      telephone: values.phone,
      billing_address: values.billing,
      state: values.state,
      city: values.city,
      postal_code: values.zipCode,
      password: values.password,
      confirm_password: values.confirm,
      email: values.email,
      item_name: values.itemName,
      language: langSwitcRes,
    };
    return async (dispatch) => {
      const url = `${baseUrl}/register_customer`;
      await axios.post(url, formData).then((res) => {
        if (res?.status === 200) {
          dispatch(b2bRegisterSuccessAction(res?.data));
          resetForm();
        } else {
          dispatch(b2bRegisterErrorAction(res?.data));
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//b2b register success

export const b2bRegisterSuccessAction = (pData) => {
  return {
    type: "B2B_REGISTER_API_SUCCESS",
    payload: pData,
  };
};

//b2b register failure

export const b2bRegisterErrorAction = (pData) => {
  return {
    type: "B2B_REGISTER_API_FAILURE",
    payload: pData,
  };
};

//b2b register Reset

export const b2bRegisterResetAction = () => {
  return {
    type: "B2B_REGISTER_API_RESET",
  };
};

//Country List

export const countryApi = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/country_list/${langSwitcRes}`;
      await axios.get(url).then((res) => {
        if (res.status === 201) {
          dispatch({ type: "COUNTRYAPI", payload: res?.data?.country_list });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Logout

export const signOut = (loginToken, navigate, langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/logout`;
      await axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: "SIGNOUTAPI", payload: res });
            window.location.href = `/${langSwitcRes}`;
            localStorage.clear();
          }
        });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Customer Area

export const customerArea = (loginResId, langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/customerarea/${langSwitcRes}/${loginResId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "CUSTOMERAREA", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Accounts Info

export const accountInfo = (loginResId, langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/accountinfo/${langSwitcRes}/${loginResId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "ACCOUNTINFO", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Login as Customer

export const loginAsCustomerAction = (loginResId) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/loginascustomer/${loginResId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "LOGIN_AS_CUSTOMER", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//My Addresses

export const myAddress = (loginResId, langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/myaddresses/${langSwitcRes}/${loginResId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MYADDRESS", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//My Order

export const myOrderAction = (loginResId) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/myorder/${loginResId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MYORDER", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//My Order Details

export const myOrderDetailsAction = (orderID) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/myorderdetails/${orderID}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MYORDERDETAILS", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//MyInVoice

export const myInVoiceAction = (loginResId) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/myinvoice/${loginResId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MYINVOICE", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//MyCatalog

export const myCatalog = () => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/catalogcontent`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MYCATALOG", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//LAUNCH

export const launchAction = () => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/releasecontent`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MYLAUNCH", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Add to cart

export const addToCartApi = (
  prodData,
  loginRes,
  qtyValue,
  userID,
  cartId,
  priceRes,
  groupRes,
  discountRes,
  preOrderDate,
  language
) => {
  try {
    return async (dispatch) => {
      if (loginRes?.status === 200 && loginRes?.data?.group == "retailer") {
        if (prodData?.product_sku) {
          //If presale tag is present in checkout

          if (prodData?.pre_sale == 1) {
            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${loginRes?.data?.linear_discount}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.product_sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else if (
            prodData?.pre_sale == 0 &&
            prodData?.min_qty_retailer > prodData?.b2b_qty
          ) {
            //If presale tag is present another condition in checkout

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${loginRes?.data?.linear_discount}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.product_sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else {
            //If presale tag is not present in checkout

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${loginRes?.data?.linear_discount}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=b2b_qty&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.product_sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          }
        } else {
          if (prodData?.pre_sale == 1) {
            //If presale tag is present

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.retailer_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else if (
            prodData?.pre_sale == 0 &&
            prodData?.min_qty_retailer > prodData?.b2b_qty
          ) {
            //If presale tag is present another condition

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.retailer_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else {
            //If presale tag is not present

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.retailer_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=b2b_qty&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          }
        }
      } else if (
        loginRes?.status === 200 &&
        loginRes?.data?.group == "wholesaler"
      ) {
        if (prodData?.product_sku) {
          if (prodData?.pre_sale == 1) {
            //if presale tag is present in the checkout

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.product_sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else if (
            prodData?.pre_sale == 0 &&
            prodData?.min_qty_wholesaler > prodData?.b2b_qty
          ) {
            //if presale tag is present another condition in the checkout

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.product_sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else {
            //If presale tag is not present

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type= b2b_qty&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.product_sku}&b2b_qty=${prodData?.b2b_qty}&pre_order_qty=${prodData?.pre_order_qty}&pre_sale=${prodData?.pre_sale}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          }
        } else {
          if (prodData?.pre_sale == 1) {
            //if presale tag is present in the checkout

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.wholesaler_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else if (
            prodData?.pre_sale == 0 &&
            prodData?.min_qty_wholesaler > prodData?.b2b_qty
          ) {
            //if presale tag is present another condition in the checkout

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.wholesaler_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=pre_order&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          } else {
            //If presale tag is not present

            const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.wholesaler_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=b2b_qty&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
            await axios.post(url).then((res) => {
              if (res.status === 200) {
                dispatch({
                  type: "ADDTOCART",
                  payload: res?.data,
                });
              }
            });
          }
        }
      } else if (
        loginRes?.status === 200 &&
        loginRes?.data?.group == "general" &&
        !prodData
      ) {
        const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.b2c_price}&discount=${discountRes}&cart_id=${cartId}&user_id=${userID}&price_list=${loginRes?.data?.price_list}&type=b2c_or_quest_qty&linear_discount=${discountRes}&image=${prodData?.image}&discount=${prodData?.discount}&format=${prodData?.format}&sku=${prodData?.sku}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
        await axios.post(url).then((res) => {
          if (res.status === 200) {
            dispatch({
              type: "ADDTOCART",
              payload: res?.data,
            });
          }
        });
      } else {
        if (prodData?.product_sku) {
          const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.product_sku}&qty=${qtyValue}&before_price=${prodData?.price}&price=${prodData?.price}&discount=${discountRes}&cart_id=${cartId}&image=${prodData?.image}&format=${prodData?.format}&sku=${prodData?.product_sku}&type=b2c_or_quest_qty&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
          await axios.post(url).then((res) => {
            if (res.status === 200) {
              dispatch({
                type: "ADDTOCART",
                payload: res?.data,
              });
            }
          });
        } else {
          const url = `${baseUrl}/addCart?lang=${language}&sku=${prodData?.sku}&qty=${qtyValue}&before_price=${prodData?.price_before}&price=${prodData?.b2c_price}&discount=${discountRes}&cart_id=${cartId}&image=${prodData?.image}&discount=${prodData?.discount}&type=b2c_or_quest_qty&format=${prodData?.format}&sku=${prodData?.sku}&slug=${prodData?.slug}&preorder_date=${preOrderDate}`;
          await axios.post(url).then((res) => {
            if (res.status === 200) {
              dispatch({
                type: "ADDTOCART",
                payload: res?.data,
              });
            }
          });
        }
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Cart details after login

export const cartApiAction = (userID, langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/usercartdetails/${userID}/${langSwitcRes}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "MY_CART", payload: res.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Quantity Increment

export const qtyIncrement = (skuId, b2c_qty) => {
  return {
    type: "QTY_INCREMENT",
    payload: { skuId, b2c_qty },
  };
};

//Quantity Increment Retailer

export const qtyIncrementRetailer = (
  skuId,
  minQty,
  b2b_qty,
  pre_order_qty,
  pre_sale
) => {
  return {
    type: "QTY_INCREMENT_RETAILER",
    payload: { skuId, minQty, b2b_qty, pre_order_qty, pre_sale },
  };
};

//Quantity Decrement

export const qtyDecrement = (skuId) => {
  return {
    type: "QTY_DECREMENT",
    payload: skuId,
  };
};

//Quantity Decrement

export const qtyDecrementRetailer = (skuId, minQty) => {
  return {
    type: "QTY_DECREMENT_RETAILER",
    payload: { skuId, minQty },
  };
};

//Remove item

export const removeApiAction = (cartId, skuId) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/removecartdetails/${cartId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "REMOVE_FROM_CART", payload: skuId });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Clear cart

export const clearCartApi = (cartId) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/clearcart?cart_id=${cartId}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "CLEAR_CART" });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Clear Cart

export const clearCart = () => {
  return {
    type: "CLEAR_CART",
  };
};

//Checkout as guest or register

export const checkoutGuestAction = (values, setStep) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/customer_option?option=${values?.option}`;
      await axios.post(url).then((res) => {
        if (res.status === 200) {
          dispatch({ type: "CHECKOUT_GUEST", payload: res.data });
          setStep("BILLING INFORMATION");
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Billing Information

export const billingAction = (values, loginResId, setStep) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/updatecustomerbillingaddress?first_name=${values?.first}&last_name=${values?.last}&email=${values?.email}&billing_address=${values?.billing1}&address2=${values?.billing2}&telephone=${values?.phone}&country=${values?.countryId}&state=${values?.state}&city=${values?.city}&postal_code=${values?.zipCode}&option=${values?.option}&addressBook=${values?.addressBook}&user_id=${loginResId}`;
      await axios.post(url).then((res) => {
        dispatch({ type: "BILLING_API", payload: res });
        if (
          res?.status === 200 &&
          res?.data?.details?.default_shipping_address == 1
        ) {
          setStep("ORDER REVIEW");
        } else {
          setStep("SHIPPING INFORMATION");
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

export const billingAction2 = (values, loginResId, setStep) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/updatecustomerbillingaddress?first_name=${values.first}&last_name=${values.last}&email=${values.email}&billing_address=${values.billing1}&address2=${values.billing2}&telephone=${values.phone}&country=${values.countryId}&state=${values.state}&city=${values.city}&postal_code=${values.zipCode}&option=${values?.option}&addressBook=${values?.addressBook}&user_id=${loginResId}`;
      await axios.post(url).then((res) => {
        dispatch({ type: "BILLING_API", payload: res });
        if (res?.status === 200) {
          setStep("ORDER REVIEW");
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Change Billing Information

export const changeBillingAction = (langSwitcRes, addressId, setStep) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/editCustomerAddress/${langSwitcRes}/${addressId}`;
      await axios.get(url).then((res) => {
        if (res?.status === 200) {
          dispatch({ type: "CHANGE_BILLING_API", payload: res?.data });
          setStep("BILLING INFORMATION");
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Edit Address Information

export const editAddressAction = (
  values,
  navigate,
  langSwitcRes,
  userID,
  shippingCheck,
  billingCheck
) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/changecustomeraddress?first_name=${values?.first}&last_name=${values?.last}&billing_address=${values?.billing1}&address2=${values?.billing2}&telephone=${values?.phone}&country=${values?.countryId}&state=${values?.state}&city=${values?.city}&postal_code=${values?.zipCode}&addressBook=${billingCheck}&shippingAddress=${shippingCheck}&user_id=${userID}&addressId=${values?.addressId}`;
      await axios.get(url).then((res) => {
        dispatch({ type: "EDIT_ADDRESS_API", payload: res });
        if (res?.status === 200) {
          navigate(`/${langSwitcRes}/myaccount`);
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Shipping Information

export const shippingAction = (values, loginResId, setStep) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/updatecustomershippingaddress?first_name=${values.first}&last_name=${values.last}&billing_address=${values.billing1}&address2=${values.billing2}&telephone=${values.phone}&country=${values.countryId}&state=${values.state}&city=${values.city}&postal_code=${values.zipCode}&addressBook=${values?.addressBook}&option=${values.BillingAddress}&user_id=${loginResId}`;
      await axios.post(url).then((res) => {
        if (res?.status === 200) {
          dispatch({ type: "SHIPPING_API", payload: res });
          setStep("ORDER REVIEW");
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Change shipping Information

export const changeShippingAction = (langSwitcRes, addressId, setStep) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/editCustomerAddress/${langSwitcRes}/${addressId}`;
      await axios.get(url).then((res) => {
        dispatch({ type: "CHANGE_SHIPPING_API", payload: res?.data });
        if (res?.status === 200) {
          setStep("SHIPPING INFORMATION");
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Payment

export const paymentApiAction = (paymentMethod, totalAmount) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/fetchpaymentdetails/${paymentMethod}?price=${totalAmount}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          // window.open(res?.data?.payment_method[0]?.host_url, "_blank");
          dispatch({ type: "PAYMENT_API", payload: res?.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

export const placeAction = (paymentMethod, totalAmount) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/fetchpaymentdetails/${paymentMethod}?price=${totalAmount}`;
      await axios.get(url).then((res) => {
        if (res.status === 200) {
          // window.open(res?.data?.payment_method[0]?.host_url, "_blank");
          dispatch({ type: "PLACE_ORDER", payload: res?.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Payment

export const placeOrderAction = (
  langSwitcRes,
  cartId,
  totalPrice,
  sub_total,
  discount,
  apply_code,
  userId,
  resGeneral,
  paymentMethod,
  status,
  billingAddressId,
  shippingAddressId,
  navigate
) => {
  try {
    return async (dispatch) => {
      if (resGeneral == "retailer") {
        const url = `${baseUrl}/place_order?lang=${langSwitcRes}&cartid=${cartId}&user_id=${userId}&price=${totalPrice}&sub_total=${sub_total}&discount=${discount}&apply_code=${apply_code}&payment_method=${paymentMethod}&user_type=${resGeneral}&status=${status}`;
        await axios.get(url).then((res) => {
          if (res?.status === 200) {
            dispatch({ type: "PLACE_ORDER", payload: res });
            navigate(`/${langSwitcRes}/thankyou`);
          }
        });
      } else if (resGeneral == "wholesaler") {
        const url = `${baseUrl}/place_order?lang=${langSwitcRes}&cartid=${cartId}&user_id=${userId}&price=${totalPrice}&sub_total=${sub_total}&discount=${discount}&apply_code=${apply_code}&payment_method=${paymentMethod}&user_type=${resGeneral}&status=${status}`;
        await axios.get(url).then((res) => {
          if (res?.status === 200) {
            dispatch({ type: "PLACE_ORDER", payload: res });
            navigate(`/${langSwitcRes}/thankyou`);
          }
        });
      } else if (paymentMethod == "paypal") {
        const url = `${baseUrl}/place_order?lang=${langSwitcRes}&cartid=${cartId}&user_id=${userId}&price=${totalPrice}&sub_total=${sub_total}&discount=${discount}&apply_code=${apply_code}&payment_method=${paymentMethod}&user_type=${resGeneral}&status=${status}&billingaddress_id=${billingAddressId}&shippingaddress_id=${shippingAddressId}`;
        await axios.get(url).then((res) => {
          if (res?.status === 200) {
            dispatch({ type: "PLACE_ORDER_PAYPAL", payload: res });
          }
        });
      } else {
        const url = `${baseUrl}/place_order?lang=${langSwitcRes}&cartid=${cartId}&user_id=${userId}&price=${totalPrice}&sub_total=${sub_total}&discount=${discount}&apply_code=${apply_code}&payment_method=${paymentMethod}&user_type=${resGeneral}&status=${status}&billingaddress_id=${billingAddressId}&shippingaddress_id=${shippingAddressId}`;
        await axios.get(url).then((res) => {
          if (res?.status === 200) {
            dispatch({ type: "PLACE_ORDER", payload: res });
            navigate(`/${langSwitcRes}/thankyou`);
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Promo code

export const promoCodeAction = (
  values,
  totalPrice,
  langSwitcRes,
  { resetForm }
) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/apply_promocode/${langSwitcRes}?coupon_code=${values?.promoValue}&sub_total=${totalPrice}`;
      await axios.get(url).then((res) => {
        if (res?.data?.success == true) {
          dispatch(promocodeSuccessAction(res));
          resetForm();
        } else {
          dispatch(promocodeErrorAction(res));
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Promo code success

export const promocodeSuccessAction = (pData) => {
  return {
    type: "PROMO_CODE_API_SUCCESS",
    payload: pData,
  };
};

//Promo code failure

export const promocodeErrorAction = (pData) => {
  return {
    type: "PROMO_CODE_API_FAILURE",
    payload: pData,
  };
};

//Promo code Reset

export const promocodeResetAction = () => {
  return {
    type: "PROMO_CODE_API_RESET",
  };
};

//Promo code if there is any change in the cart

export const promocodeCartAction = () => {
  return {
    type: "PROMO_CODE_API_CART",
  };
};

//ChangePassword

export const changePasswordAction = (values, userId, { resetForm }) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/changePassword?id=${userId}&old_password=${values?.currentPassword}&new_password=${values?.newPassword}&confirm_password=${values?.confirmNewPassword}`;
      await axios.post(url).then((res) => {
        if (res?.status === 200) {
          dispatch(changePasswordSuccessAction(res?.data));
          resetForm();
        } else {
          dispatch(changePasswordErrorAction(res?.data));
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Change password success

export const changePasswordSuccessAction = (pData) => {
  return {
    type: "CHANGE_PASSWORD_API_SUCCESS",
    payload: pData,
  };
};

//Change password failure

export const changePasswordErrorAction = (pData) => {
  return {
    type: "CHANGE_PASSWORD_API_FAILURE",
    payload: pData,
  };
};

//Change password Reset

export const changePasswordResetAction = () => {
  return {
    type: "CHANGE_PASSWORD_API_RESET",
  };
};

//Cart button

export const cartBtn = (pData) => {
  return {
    type: "CART_BUTTON",
    payload: pData,
  };
};

//Make payment

export const makePaymentAction = (
  payment_method,
  resGeneral,
  cartId,
  orderId,
  paymentStatus,
  totalPrice,
  paymentResponse,
  navigate,
  langSwitcRes
) => {
  try {
    return async (dispatch) => {
      if (paymentStatus == "CANCELLED") {
        const url = `${baseUrl}/make_payment?payment_method=${payment_method}&user_role=${resGeneral}&cart=${cartId}&order_id=${orderId}&payment_status=${paymentStatus}&total_order=${totalPrice}&payment_response=${paymentResponse}`;
        await axios.get(url).then((res) => {
          if (res?.status === 200) {
            dispatch({ type: "MAKE_PAYMENT", payload: res });
          }
        });
      } else {
        const url = `${baseUrl}/make_payment?payment_method=${payment_method}&user_role=${resGeneral}&cart=${cartId}&order_id=${orderId}&payment_status=${paymentStatus}&total_order=${totalPrice}&payment_response=${paymentResponse}`;
        await axios.get(url).then((res) => {
          if (res?.status === 200 && res?.data?.payment_status == "COMPLETED") {
            dispatch({ type: "MAKE_PAYMENT_API", payload: res });
            navigate(`/${langSwitcRes}/thankyou`);
          }
        });
      }
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//steps

export const stepsAction = (payload) => {
  return {
    type: "STEPS",
    payload,
  };
};

//Edit addresses

export const editAddress = (langSwitcRes, addressId, navigate) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/editCustomerAddress/${langSwitcRes}/${addressId}`;
      await axios.get(url).then((res) => {
        if (res?.status == 200) {
          dispatch({ type: "EDITADDRESS_API", payload: res });
          navigate(`/${langSwitcRes}/editaddress`);
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Delete Address

export const deleteAddress = (addressId) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/removeaddress/${addressId}`;
      await axios.get(url).then((res) => {
        if (res?.status == 200) {
          dispatch({ type: "DELETE_ADDRESS_API", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Login content

export const loginPageContent = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/keyword/${langSwitcRes}/login`;
      await axios.get(url).then((res) => {
        if (res?.status == 200) {
          dispatch({ type: "LOGIN_CONTENT", payload: res });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//contact form

export const contactFormAction = (values, langSwitcRes, { resetForm }) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/contactinfo/${langSwitcRes}?name=${values?.name}&email=${values?.email}&telephone=${values?.phone}&comment=${values?.comment}`;
      await axios.post(url).then((res) => {
        if (res?.status == 200) {
          dispatch(contactSuccessAction(res?.data));
          resetForm();
        } else {
          dispatch(contactErrorAction(res?.data));
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};

//Contact form success

export const contactSuccessAction = (pData) => {
  return {
    type: "CONTACT_FORM_SUCCESS",
    payload: pData,
  };
};

//Contact form failure

export const contactErrorAction = (pData) => {
  return {
    type: "CONTACT_FORM_FAILURE",
    payload: pData,
  };
};

//Contact form Reset

export const contactResetAction = () => {
  return {
    type: "CONTACT_FORM_RESET",
  };
};

//Login content

export const pageNotFoundAction = (langSwitcRes) => {
  try {
    return async (dispatch) => {
      const url = `${baseUrl}/notfoundurl/${langSwitcRes}`;
      await axios.get(url).then((res) => {
        if (res?.status == 200) {
          dispatch({ type: "PAGE_NOT_FOUND", payload: res?.data });
        }
      });
    };
  } catch (error) {
    alert(`Error has occurred!`);
  }
};
