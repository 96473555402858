import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { Footer } from "../../../Common/Footer/Footer";
import { ShoppingCart } from "../Step 1 Checkout/ShoppingCart";
import { CheckoutRegister } from "../Step 2 Registration/CheckoutRegister";
import { CheckoutBilling } from "../Step 3 Billing address/CheckoutBilling";
import { CheckoutShipping } from "../Step 4 Shipping address/CheckoutShipping";
import { CheckoutOrderReview } from "../Step 5 Order Preview/CheckoutOrderReview";
import "./StepProcess.css";
import { Header } from "../../../Common/Header/Header";
import { AiOutlineCheck } from "react-icons/ai";

export const StepProcess = () => {
  const step1 = "SHOPPING CART";
  const step2 = "REGISTRATION METHOD";
  const step3 = "BILLING INFORMATION";
  const step4 = "SHIPPING INFORMATION";
  const step5 = "ORDER REVIEW";

  const { t } = useTranslation();

  const [step, setStep] = useState();

  useEffect(() => {
    const currentSteps = window.localStorage.getItem("currentStep") || step1;

    currentSteps && setStep(currentSteps);
  }, []);

  const setCurrentStep = (step) => {
    window.localStorage.setItem("currentStep", step);
    setStep(step);
  };

  const res = useSelector((state) => state?.loginData);

  //Billing Address

  const billingRes = useSelector((state) => state?.changeBillingData);

  //Cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  //steps Res
  const stepRes = useSelector((state) => state?.stepsData);

  return (
    <>
      <Header pathData="checkout" />
      <div>
        <div class="main">
          <ul className="stepUl">
            <li onClick={() => setCurrentStep(step1)}>
              <p class="text">{t("shopping_cart")?.toUpperCase()}</p>
              <div class="progress one">
                {stepRes?.["step1"] === true ? (
                  <AiOutlineCheck />
                ) : (
                  <>
                    <p>1</p>
                  </>
                )}
              </div>
            </li>

            {/* Registration */}

            {res?.status === 200 ? (
              <></>
            ) : Object.keys(addToCartRes)?.length > 0 ? (
              <>
                <li onClick={() => setCurrentStep(step2)}>
                  <p class="text">{t("registration")?.toUpperCase()}</p>
                  <div class="progress two">
                    {stepRes?.["step2"] === true ? (
                      <AiOutlineCheck />
                    ) : (
                      <>
                        <p>2</p>
                      </>
                    )}
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <p class="text">{t("registration")?.toUpperCase()}</p>
                  <div class="progress two">
                    {stepRes?.["step2"] === true ? (
                      <AiOutlineCheck />
                    ) : (
                      <>
                        <p>2</p>
                      </>
                    )}
                  </div>
                </li>
              </>
            )}

            {/* Billing Info */}

            {Object.keys(addToCartRes)?.length > 0 ? (
              <>
                <li onClick={() => setCurrentStep(step3)}>
                  <p class="text">{t("billing_info")?.toUpperCase()}</p>
                  <div class="progress three">
                    {res?.status == 200 ? (
                      <>
                        {stepRes?.["step3"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>2</p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {stepRes?.["step3"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>3</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <p class="text">{t("billing_info")?.toUpperCase()}</p>
                  <div class="progress three">
                    {res?.status == 200 ? (
                      <>
                        <p>2</p>
                      </>
                    ) : (
                      <>
                        <p>3</p>
                      </>
                    )}
                  </div>
                </li>
              </>
            )}

            {/* Shipping info */}

            {Object.keys(addToCartRes)?.length > 0 ? (
              <>
                <li onClick={() => setCurrentStep(step4)}>
                  <p class="text">{t("shipping_info")?.toUpperCase()}</p>
                  <div class="progress four">
                    {res?.status == 200 ? (
                      <>
                        {stepRes?.["step4"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>3</p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {stepRes?.["step4"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>4</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <p class="text">{t("shipping_info")?.toUpperCase()}</p>
                  <div class="progress four">
                    {res?.status == 200 ? (
                      <>
                        <p>3</p>
                      </>
                    ) : (
                      <>
                        <p>4</p>
                      </>
                    )}
                  </div>
                </li>
              </>
            )}

            {/* Order review */}

            {Object.keys(addToCartRes)?.length > 0 ? (
              <>
                {billingRes?.status === 200 && !res?.status == 200 ? (
                  <>
                    <li onClick={() => setCurrentStep(step5)}>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        {stepRes?.["step5"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>5</p>
                          </>
                        )}
                      </div>
                    </li>
                  </>
                ) : billingRes?.status === 200 && res?.status == 200 ? (
                  <>
                    <li onClick={() => setCurrentStep(step5)}>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        {stepRes?.["step5"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>4</p>
                          </>
                        )}
                      </div>
                    </li>
                  </>
                ) : res?.status == 200 ? (
                  <>
                    <li onClick={() => setCurrentStep(step3)}>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        {stepRes?.["step5"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>4</p>
                          </>
                        )}
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li onClick={() => setCurrentStep(step3)}>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        {stepRes?.["step5"] === true ? (
                          <AiOutlineCheck />
                        ) : (
                          <>
                            <p>5</p>
                          </>
                        )}
                      </div>
                    </li>
                  </>
                )}
              </>
            ) : (
              <>
                {billingRes?.status === 200 && !res?.status == 200 ? (
                  <>
                    <li>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        <p>5</p>
                      </div>
                    </li>
                  </>
                ) : billingRes?.status === 200 && res?.status == 200 ? (
                  <>
                    <li>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        <p>4</p>
                      </div>
                    </li>
                  </>
                ) : res?.status == 200 ? (
                  <>
                    <li>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        <p>4</p>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <p class="text">{t("order_review")?.toUpperCase()}</p>
                      <div class="progress five">
                        <p>5</p>
                      </div>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>

        {/* Shopping cart */}

        {step === step1 && <ShoppingCart setStep={setCurrentStep} />}

        {/* Registration */}

        {step === step2 && Object.keys(addToCartRes)?.length > 0 && (
          <CheckoutRegister setStep={setCurrentStep} />
        )}

        {/* Billing info */}

        {step === step3 && Object.keys(addToCartRes)?.length > 0 && (
          <CheckoutBilling setStep={setCurrentStep} />
        )}

        {/* Shipping info */}

        {step === step4 && Object.keys(addToCartRes)?.length > 0 && (
          <CheckoutShipping setStep={setCurrentStep} />
        )}

        {/* Order review */}

        {step === step5 && <CheckoutOrderReview setStep={setCurrentStep} />}

        <Footer />
        {res?.status === 200 ? "" : <BatmanPin />}
      </div>
    </>
  );
};
