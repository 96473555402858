import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  contactFormAction,
  contactResetAction,
  contactUsAction,
  reCaptchaApi,
} from "../../../Redux/Action";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { Footer } from "../../Common/Footer/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import { Header } from "../../Common/Header/Header";

export const ContactUs = () => {
  const dispatch = useDispatch();

  //LangSwitcher

  const { langSwitcRes } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(contactUsAction(langSwitcRes));
  }, [langSwitcRes, dispatch]);

  const contactusRes = useSelector(
    (state) => state?.contactusData?.data?.details
  );

  //Login Response

  const res = useSelector((state) => state?.loginData);

  //Form

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      comment: "",
      phone: "",
    },

    validate: (values) => {
      const errors = {};

      //Name

      if (!values.name) {
        errors.name = "Name cannot be blank";
      } else if (!/^[a-zA-Z ]*$/.test(values.name)) {
        errors.name = "Name should contain only characters.";
      }

      //Phone

      if (!values.phone) {
        errors.phone = t("phone-validation");
      } else if (!/^[0-9]*$/.test(values.phone)) {
        errors.phone = t("telephone_validation");
      }

      //Email Address

      if (!values.email) {
        errors.email = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("email_format_validation");
      }

      //Comment

      if (!values.comment) {
        errors.comment = "Comment cannot be blank";
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(contactFormAction(values, langSwitcRes, { resetForm }));
    },
  });

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.contactFormData
  );

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setDisplayError(true);

      setTimeout(() => {
        dispatch(contactResetAction());
        setDisplayError(false);
      }, 10000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <div
        className="alert alert-success d-flex align-items-center p-3 mt-4"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
        ></i>
        <div className="register-error-success">{successMsg}</div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div
        className="alert alert-danger d-flex align-items-center p-3 mt-4"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
        ></i>
        <div className="register-error-message">{errorInfo}</div>
      </div>
    );
  };

  //Recaptcha

  const captchaKey = "6LfkTwYlAAAAAM3qvFGXAnHO5Iwdf7QAvNOWD_iX";

  function onChange(value) {
    dispatch(reCaptchaApi(value, captchaKey));
  }

  return (
    <>
      <Header pathData="contactus" />
      {contactusRes?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>;
          </React.Fragment>
        );
      })}

      {/* Form */}

      <div className="myaccount-page-body">
        <div className="container ">
          <div className="extramargin">
            <div className="row">
              <div className="col-6 backbutton">
                <a href={`/${langSwitcRes}`}>&#171; Back to home</a>
              </div>
              <div className="col-6">
                <div className="required-field"> {t("required-fields")}</div>
              </div>
            </div>

            <Formik>
              <form onSubmit={formik.handleSubmit}>
                <div className="fieldset mb-3">
                  <h2 class="legend large">CONTACT INFORMATION</h2>
                  <div className="row">
                    {/* Success & error msgs */}

                    {successMsg && displayError && <SuccessAlert />}
                    {errorInfo && displayError && <FailureAlert />}

                    {/* First name */}

                    <div class="col-6 mb-3 mt-3">
                      <label htmlFor="name" class="form-label">
                        Name <span className="rf">*</span>
                      </label>
                      <input
                        className="form-control"
                        title="Name"
                        type="text"
                        name="name"
                        placeholder="Name *"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        autoComplete="off"
                        id="name"
                      />

                      {/* Name validation */}

                      <p className="register-error">
                        {formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name}
                      </p>
                    </div>

                    {/* Email Address */}

                    <div class="col-6 mb-3 mt-3">
                      <label htmlFor="last" class="form-label">
                        {t("email")} <span className="rf">*</span>
                      </label>
                      <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        title={t("email")}
                        placeholder={t("email")}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />

                      {/* email validation */}

                      <p className="register-error">
                        {formik.errors.email &&
                          formik.touched.email &&
                          formik.errors.email}
                      </p>
                    </div>

                    {/* Telephone */}

                    <div class="col-6 mb-3">
                      <label htmlFor="phone" class="form-label">
                        {t("phone-number")} <span className="rf">*</span>
                      </label>
                      <input
                        name="phone"
                        id="phone"
                        type="text"
                        className="form-control"
                        title={t("phone-number")}
                        placeholder={t("phone-number")}
                        autoComplete="off"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                      />

                      {/* Phone validation */}

                      <p className="register-error">
                        {formik.errors.phone &&
                          formik.touched.phone &&
                          formik.errors.phone}
                      </p>
                    </div>

                    <div class="field left">
                      <label htmlFor="phone" class="form-label">
                        Comment <span className="rf">*</span>
                      </label>
                      <div class="input_box">
                        <div class="form-group field-contactus-comment required has-error">
                          <textarea
                            id="comment"
                            class="form-control"
                            name="comment"
                            placeholder="Comment *"
                            rows="3"
                            cols="5"
                            aria-required="true"
                            aria-invalid="true"
                            onChange={formik.handleChange}
                            value={formik.values.comment}
                          ></textarea>

                          {/* Comment validation */}

                          <p className="register-error">
                            {formik.errors.comment &&
                              formik.touched.comment &&
                              formik.errors.comment}
                          </p>
                        </div>
                      </div>
                      <ReCAPTCHA
                        sitekey="6LfkTwYlAAAAAIQkFzMJbDHPPwW5lfi904elwdPL"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="blackbutton" title={t("submit")}>
                    {t("submit")}
                  </button>
                </div>
              </form>
            </Formik>
          </div>
        </div>
      </div>

      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
