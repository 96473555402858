import React, { useEffect, useState } from "react";
import { Login } from "./Login";
import { Footer } from "../../../Common/Footer/Footer";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { Helmet } from "react-helmet";
import { loginPageContent } from "../../../../Redux/Action";
import { Header } from "../../../Common/Header/Header";
import { useTranslation } from "react-i18next";

export const Register = () => {
  const { langSwitcRes } = useParams();

  const dispatch = useDispatch();

  const res = useSelector((state) => state?.loginData);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(loginPageContent(langSwitcRes));
  }, [langSwitcRes]);

  return (
    <>
      <Helmet>
        <title>KARACTERMANIA - {t("Login")}</title>
      </Helmet>
      <Header pathData="login" />

      <Login langSwitcRes={langSwitcRes} />
      {res?.status === 200 ? "" : <BatmanPin />}
      <Footer />
    </>
  );
};
