import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "../../../../../index.css";

export const MajorBrands = () => {
  // //Language Switcher

  const { langSwitcRes } = useParams();

  //Product details Response

  // const productDetRes = useSelector((state) => state?.productDetailsData);

  return (
    <>
      <div className="major-brands-cont">
        <div className="col-md-12 col-sm-12">
          <ul className="brands">
            <Link
              to={`/${langSwitcRes}/category/disney`}
              onClick={() => `/${langSwitcRes}/category/disney`}
              className="text-dark text-decoration-none"
            >
              <li>
                <div className="swapimg-container">
                  <img src="/images/detailed/01.png" alt="Disney" />
                  <img
                    className="top-img"
                    src="/images/detailed/01-mo.png"
                    alt="Disney"
                  />
                </div>
                Disney
              </li>
            </Link>
            <li>
              <Link
                to={`/${langSwitcRes}/category/disney`}
                onClick={() => `/${langSwitcRes}/category/disney`}
                className="text-dark text-decoration-none"
              >
                <div className="swapimg-container">
                  <img src="/images/detailed/02.png" alt="DC" />
                  <img
                    className="top-img"
                    src="/images/detailed/02-mo.png"
                    alt="DC"
                  />
                </div>
                DC
              </Link>
            </li>
            <li>
              {/* <a href="#"> */}
              <div className="swapimg-container">
                <img src="/images/detailed/03.png" alt="Wizarding World" />
                <img
                  className="top-img"
                  src="/images/detailed/03-mo.png"
                  alt="Wizarding World"
                />
              </div>
              Wizarding World
              {/* </a> */}
            </li>
            <li>
              {/* <a href="#"> */}
              <div className="swapimg-container">
                <img src="/images/detailed/04.png" alt="Marvel" />
                <img
                  className="top-img"
                  src="/images/detailed/04-mo.png"
                  alt="Marvel"
                />
              </div>
              Marvel
              {/* </a> */}
            </li>
            <li>
              {/* <a href="#"> */}
              <div className="swapimg-container">
                <img src="/images/detailed/05.png" alt="WB" />
                <img
                  className="top-img"
                  src="/images/detailed/05-mo.png"
                  alt="Marvel"
                />
              </div>
              WB
              {/* </a> */}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

// import React from "react";
// import { useSelector } from "react-redux";
// import { Link, useParams } from "react-router-dom";
// import { baseUrlCategory } from "../../../../../config";
// import "../../../../../index.css";

// export const MajorBrands = () => {
//   //Language Switcher

//   const langSwitcRes = useSelector((state) => state.langSwitchData);

//   //Product details Response

//   const productDetRes = useSelector((state) => state?.productDetailsData);

//   return (
//     <>
//       <div className="major-brands-cont">
//         <div className="col-md-12 col-sm-12">
//           <ul className="brands">
//             {productDetRes?.category_type_logo?.map((e, i) => {
//               return (
//                 <React.Fragment key={i}>
//                   <li>
//                     <Link to={`/${langSwitcRes}/productos/${e?.name}`}>
//                       <div className="swapimg-container">
//                         <img src="/images/detailed/01.png" alt="Disney" />
//                         <img
//                           className="top-img"
//                           src={`${baseUrlCategory}/${e?.category_logo}`}
//                           alt="Disney"
//                         />
//                       </div>
//                       {e?.name}
//                     </Link>
//                   </li>
//                 </React.Fragment>
//               );
//             })}
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// };
