import React from "react";
import { useSelector } from "react-redux";
import "../../../../index.css";
import { baseUrlCategory } from "../../../../config/index";

export const DesignerManufacturer = () => {
  //Homepage Response

  const homepageRes = useSelector(
    (state) => state?.HomePageData?.main_category
  );

  //Homepage content

  const homepageConRes = useSelector(
    (state) => state?.HomePageData?.main_category_content
  );

  //Language Switcher

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  return (
    <>
      <div className="licensedproducts-cont">
        <div className="container-fluid">
          {langSwitcRes &&
            homepageConRes
              ?.filter((e) => e?.language === langSwitcRes)
              ?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="section-heading mt-lg-4">{e?.tittle}</div>
                    <p className="section-paragraph">
                      <span
                        dangerouslySetInnerHTML={{ __html: e?.content }}
                      ></span>
                    </p>
                  </React.Fragment>
                );
              })}{" "}
          {homepageConRes?.find((e) => {
            return e?.status === "enable";
          }) ? (
            <>
              <div className="row align-items-start mt-4">
                <div className="col-md-6">
                  <div className="row">
                    {homepageRes?.slice(0, 1)?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="col-md-12 col-sm-6 p-0 m-0">
                            <div className="graybg-sm">
                              <img
                                src={`${baseUrlCategory}/${e?.category_image}`}
                                alt={e?.page_title}
                              />
                              <div className="text-white p-3 lptcont">
                                <h2>{e?.page_title}</h2>
                                <p className="lptbg">
                                  <a
                                    href={`/${langSwitcRes}/${e?.slug_name}`}
                                    target="_parent"
                                    title={e?.description}
                                  >
                                    {e?.description}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}

                    {homepageRes?.slice(1, 2)?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="col-md-12 col-sm-6 p-0 m-0">
                            <div className="graybg-sm">
                              <img
                                src={`${baseUrlCategory}/${e?.category_image}`}
                                alt={e?.page_title}
                              />
                              <div className="text-white p-3 lptcont">
                                <h2>{e?.page_title}</h2>
                                <p className="lptbg">
                                  <a
                                    href={`/${langSwitcRes}/${e?.slug_name}`}
                                    target="_parent"
                                    title={e?.description}
                                  >
                                    {e?.description}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>

                {homepageRes?.slice(2, 3)?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-md-6 col-sm-12 p-0">
                        <div className="graybg-bg">
                          <img
                            src={`${baseUrlCategory}/${e?.category_image}`}
                            alt={e?.page_title}
                          />
                          <div className="text-white p-3 lptcont">
                            <h2>{e?.page_title}</h2>
                            <p className="lptbg">
                              <a
                                href={`/${langSwitcRes}/${e?.slug_name}`}
                                target="_parent"
                                title={e?.description}
                              >
                                {e?.description}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="row align-items-start">
                {homepageRes?.slice(3, 4)?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-md-6 col-sm-12 p-0">
                        <div className="graybg-bg">
                          <img
                            src={`${baseUrlCategory}/${e?.category_image}`}
                            alt={e?.page_title}
                          />
                          <div className="text-white p-3 lptcont">
                            <h2>{e?.page_title}</h2>
                            <p className="lptbg">
                              <a
                                href={`/${langSwitcRes}/${e?.slug_name}`}
                                target="_parent"
                                title={e?.description}
                              >
                                {e?.description}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}

                <div className="col-md-6">
                  <div className="row">
                    {homepageRes?.slice(4, 5)?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="col-md-12 col-sm-6 p-0 m-0">
                            <div className="graybg-sm">
                              <img
                                src={`${baseUrlCategory}/${e?.category_image}`}
                                alt={e?.page_title}
                              />
                              <div className="text-white p-3 lptcont">
                                <h2>{e?.page_title}</h2>
                                <p className="lptbg">
                                  <a
                                    href={`/${langSwitcRes}/${e?.slug_name}`}
                                    target="_parent"
                                    title={e?.description}
                                  >
                                    {e?.description}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}

                    {homepageRes?.slice(5, 6)?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="col-md-12 col-sm-6 p-0 m-0">
                            <div className="graybg-sm">
                              <img
                                src={`${baseUrlCategory}/${e?.category_image}`}
                                alt={e?.page_title}
                              />
                              <div className="text-white p-3 lptcont">
                                <h2>{e?.page_title}</h2>
                                <p className="lptbg">
                                  <a
                                    href={`/${langSwitcRes}/${e?.slug_name}`}
                                    target="_parent"
                                    title={e?.description}
                                  >
                                    {e?.description}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
