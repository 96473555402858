import React, { useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { clearCartApi, stepsAction } from "../../../../Redux/Action";
import { Link, useParams } from "react-router-dom";
import { CartData } from "./CartData";
import "./ShoppingCart.css";
import "../../../../index.css";
import { useTranslation } from "react-i18next";

export const ShoppingCart = ({ setStep }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  //Cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  const clearAllRes = Object.keys(addToCartRes)?.map(
    (e) => addToCartRes[e]?.cart_id
  );

  //Total Price

  const totalPrice = Object?.keys(addToCartRes)?.reduce(
    (acc, item) => acc + addToCartRes[item]?.qty * addToCartRes[item]?.price,
    0
  );
  //Date

  // const d = new Date();
  // const month = d.getMonth() + 1;
  // const showTodayDate = d.getDate() + "/" + month + "/" + d.getFullYear();

  //Language Switcher

  const { langSwitcRes } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customerAreaRes = useSelector((state) => state?.customerAreaData);

  useEffect(() => {
    dispatch(
      stepsAction([
        { step: "step1", stepValue: true },
        { step: "step2", stepValue: false },
        { step: "step3", stepValue: false },
        { step: "step4", stepValue: false },
        { step: "step5", stepValue: false },
      ])
    );
  }, []);

  return (
    <>
      <div className="myaccount-page-body">
        {res?.status === 200 && customerAreaRes.length === 0 ? (
          <>
            <div className="card text-dark bg-light basicinfo">
              <div class="card-body">
                <div class="row rowheight">
                  <div class="col">{t("customer")} :</div>
                  <div class="col highlight">-</div>
                  <div class="col">{t("account-type")}</div>
                  <div class="col highlight">
                    {res?.data?.group?.toUpperCase()}
                  </div>
                </div>
                <div class="row rowheight">
                  <div class="col">{t("customer-id")}:</div>
                  <div class="col highlight">-</div>
                  <div class="col">{t("default-address")}:</div>
                  <div class="col highlight">-</div>
                </div>
                <div class="row rowheight">
                  <div class="col">{t("customer-discount")}:</div>
                  <div class="col highlight">-</div>
                  <div class="col">{t("payment-method")}:</div>
                  <div class="col highlight">-</div>
                </div>
                <div class="row rowheight">
                  <div class="col">{t("equal-charge")}:</div>
                  <div class="col highlight">-</div>
                  <div class="col"></div>
                  <div class="col highlight"></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {res?.status === 200
          ? customerAreaRes?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="card text-dark bg-light basicinfo">
                    <div class="card-body">
                      <div class="row rowheight">
                        <div class="col">{t("customer")}:</div>
                        <div class="col highlight">{e?.company}</div>
                        <div class="col">{t("account-type")}:</div>
                        <div class="col highlight">
                          {res?.data?.group?.toUpperCase()}
                        </div>
                      </div>
                      <div class="row rowheight">
                        <div class="col">{t("customer-id")}:</div>
                        <div class="col highlight">
                          {e?.erp_customer_id ? (
                            <>{e?.erp_customer_id}</>
                          ) : (
                            <>{e?.customer_id}</>
                          )}
                        </div>
                        <div class="col">{t("default-address")}:</div>
                        <div class="col highlight">
                          {e?.address_1}, {e?.state},
                          <br />
                          {e?.city}, {e?.postal_code}
                        </div>
                      </div>
                      <div class="row rowheight">
                        <div class="col">{t("customer-discount")}:</div>
                        <div class="col highlight">
                          {e?.linear_discount ? (
                            <>{e?.linear_discount}%</>
                          ) : (
                            <>-</>
                          )}
                        </div>
                        <div class="col">{t("payment-method")}:</div>
                        <div class="col highlight">
                          {e?.payment_method ? (
                            <>{e?.payment_method}%</>
                          ) : (
                            <>-</>
                          )}
                        </div>
                      </div>
                      <div class="row rowheight">
                        <div class="col">{t("equal-charge")}:</div>
                        <div class="col highlight">
                          {e?.recarge_of_equivalen ? (
                            <>{e?.recarge_of_equivalen}</>
                          ) : (
                            <>-</>
                          )}
                        </div>
                        <div class="col"></div>
                        <div class="col highlight"></div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          : ""}

        <div className="container">
          <div className="page-header">
            <h3>{t("shopping_cart")}</h3>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 mb-5">
              <Table className="checkouttable">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>{t("product_name")}</Th>
                    <Th>{t("code")}</Th>
                    <Th>{t("price")}</Th>
                    <Th>{t("disc1")}</Th>
                    <Th>{t("client_price")}</Th>
                    <Th>{t("quantity")}</Th>
                    <Th>{t("sub_total")}</Th>
                    <Th>{t("action")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* {res?.status === 200 ? <CartDataAfterLogin /> : <CartData />} */}

                  <CartData langSwitcRes={langSwitcRes} />

                  <Tr>
                    <Td colspan="9" className="text-end p-3">
                      {Object.keys(addToCartRes)?.length > 0 ? (
                        <>
                          <button
                            type="button"
                            title="Clear Shopping Cart"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="checkoutbutton"
                          >
                            <i class="fa-solid fa-x mx-2"></i>
                            {t("clear_shopping_cart")}
                          </button>

                          <div
                            class="modal fade"
                            id="exampleModal"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div className="clearAllClose">
                                  <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="clearAllBody">
                                  {t("are_you_want_to_clear_products")}
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="checkoutbutton"
                                    data-bs-dismiss="modal"
                                  >
                                    <i class="fa-solid fa-x mx-2"></i>
                                    {t("no")}
                                  </button>
                                  <button
                                    type="button"
                                    class="checkoutbutton"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      dispatch(clearCartApi(clearAllRes));
                                    }}
                                    data-bs-dismiss="modal"
                                  >
                                    <i
                                      class="fa fa-check mx-2"
                                      aria-hidden="true"
                                    ></i>
                                    {t("yes")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            title="Clear Shopping Cart"
                            className="checkoutbutton"
                            disabled
                          >
                            <i class="fa-solid fa-x mx-2"></i>
                            {t("clear_shopping_cart")}
                          </button>
                        </>
                      )}

                      <Link to={`/${langSwitcRes}/licenses`}>
                        <button
                          type="button"
                          title="Continue Shopping"
                          className="checkoutbutton"
                        >
                          <i class="fa fa-check mx-2" aria-hidden="true"></i>
                          {t("continue_shopping")}
                        </button>
                      </Link>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </div>

            {/* {addToCartRes?.length > 0 ? (
              <> */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
              <div className="shipping-total">
                <div className="inyour-cart">{t("in_your_cart")}</div>
                <ul>
                  <li className="subtotal">
                    <span>{t("sub_total")}</span>
                    <span className="price" id="checkout-subtotal">
                      {langSwitcRes === "es" ? (
                        <>
                          {Number(totalPrice).toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })}
                        </>
                      ) : langSwitcRes === "fr" ? (
                        <>
                          {Number(totalPrice).toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                          })}
                        </>
                      ) : (
                        <>{Number(totalPrice).toFixed(2)}</>
                      )}
                      <span> €</span>
                    </span>
                  </li>

                  <li className="total">
                    <strong>{t("total")}</strong>
                    <span className="price" id="checkout-total">
                      {langSwitcRes === "es" ? (
                        <>
                          {Number(totalPrice).toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })}
                        </>
                      ) : langSwitcRes === "fr" ? (
                        <>
                          {Number(totalPrice).toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                          })}
                        </>
                      ) : (
                        <>{Number(totalPrice).toFixed(2)}</>
                      )}
                      <span> €</span>
                    </span>
                  </li>
                </ul>
              </div>

              {Object.keys(addToCartRes)?.length > 0 ? (
                <>
                  {res?.status === 200 ? (
                    <button
                      className="checkoutbutton w-100"
                      title="Make an Order"
                      onClick={() => setStep("BILLING INFORMATION")}
                    >
                      {t("make_an_order")}
                    </button>
                  ) : (
                    <button
                      className="checkoutbutton w-100"
                      title="Make an Order"
                      onClick={() => {
                        setStep("REGISTRATION METHOD");
                        dispatch(
                          stepsAction([{ step: "step1", stepValue: true }])
                        );
                      }}
                    >
                      {t("make_an_order")}
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button
                    className="checkoutbutton w-100"
                    title="Make an Order"
                    disabled
                  >
                    {t("make_an_order")}
                  </button>
                </>
              )}
            </div>
            {/* </>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
