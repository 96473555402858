import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  checkoutGuestAction,
  forgotResetAction,
  login,
  password,
  stepsAction,
} from "../../../../Redux/Action";
import { Header } from "../../../Common/Header/Header";
import { LoginResponse } from "../../Registration/Register/LoginResponse";

export const CheckoutRegister = ({ setStep }) => {
  const [checked, setChecked] = useState(false);
  function toggle(value) {
    return !value;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  //Individual Register Response

  const regData = useSelector((state) => state?.individualData?.data?.token);

  //Language Switcher

  const { langSwitcRes } = useParams();

  //Login Validations

  const formik = useFormik({
    initialValues: {
      Lemail: "",
      Lpassword: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.Lemail) {
        errors.Lemail = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Lemail)
      ) {
        errors.Lemail = t("email_format_validation");
      }

      if (!values.Lpassword) {
        errors.Lpassword = t("password-validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(
        login(
          values,
          regData,
          { resetForm },
          navigate,
          langSwitcRes,
          "loginasguest",
          setStep
        )
      );
      dispatch(stepsAction({ step: "step2", stepValue: true }));
    },
  });

  //Forget Password

  const formiks = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("email_format_validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(password(values, { resetForm }, langSwitcRes));
    },
  });

  const { successMsg, errorInfo } = useSelector(
    (state) => state?.forgetPassData
  );

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setDisplayError(true);

      setTimeout(() => {
        dispatch(forgotResetAction());
        setDisplayError(false);
      }, 5000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <div
        className="alert alert-success mt-3 d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>{successMsg}</p>
        </div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div
        className="alert alert-danger mt-3 d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>{errorInfo}</p>
        </div>
      </div>
    );
  };

  //Checkout as Guest

  const formikk = useFormik({
    initialValues: {
      option: "",
    },

    onSubmit: (values) => {
      dispatch(checkoutGuestAction(values, setStep));
    },
  });

  useEffect(() => {
    dispatch(
      stepsAction([
        { step: "step1", stepValue: true },
        { step: "step2", stepValue: true },
        { step: "step3", stepValue: false },
        { step: "step4", stepValue: false },
        { step: "step5", stepValue: false },
      ])
    );
  }, []);
  return (
    <>
      <div
        className="container"
        style={{ marginTop: "2rem", marginBottom: "5rem" }}
      >
        <div className="billing-cont">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-4">
              <div className="returning-customer">
                <h5 className="text-center mb-3">Already Registered</h5>

                {/* success or error msg */}

                <LoginResponse />

                <Formik>
                  <form onSubmit={formik.handleSubmit}>
                    {/* Username */}

                    <div className="form-group mb-2">
                      <label htmlFor="Lemail" className="visually-hidden">
                        Username
                      </label>
                      <input
                        autoComplete="off"
                        className="form-control"
                        placeholder={t("email-address")}
                        type="text"
                        name="Lemail"
                        id="Lemail"
                        title={t("email-address")}
                        onChange={formik.handleChange}
                        value={formik.values.Lemail}
                      />
                    </div>

                    {/* Username Validation */}

                    <p className="fw-bold mt-2 text-start shadow-none errorm">
                      {formik.errors.Lemail &&
                        formik.touched.Lemail &&
                        formik.errors.Lemail}
                    </p>

                    {/* Password */}

                    <div className="form-group mb-2">
                      <label htmlFor="Lpassword" className="visually-hidden">
                        Password
                      </label>
                      <input
                        className="form-control"
                        placeholder={t("password")}
                        autoComplete="off"
                        type="password"
                        name="Lpassword"
                        onChange={formik.handleChange}
                        value={formik.values.Lpassword}
                        id="Lpassword"
                        title={t("password")}
                      />
                    </div>

                    {/* Password Validation */}

                    <p className="fw-bold mt-2 text-start shadow-none errorm">
                      {formik.errors.Lpassword &&
                        formik.touched.Lpassword &&
                        formik.errors.Lpassword}
                    </p>

                    {/* Login Button */}

                    <div className="form-group mt-3">
                      <button
                        type="submit"
                        className="blackbutton"
                        title={t("sign-in")}
                      >
                        {t("sign-in")}
                      </button>
                    </div>
                  </form>
                </Formik>
                <br />
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    checked={checked}
                    onChange={() => setChecked(toggle)}
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Forgot Your Password?
                  </label>
                </div>
                {checked && (
                  <div>
                    {/* Forgot Password */}

                    <Formik>
                      <form onSubmit={formiks.handleSubmit}>
                        {/* Email */}
                        <p className="reset-password-cont">
                          Please enter your email address below. You will
                          receive a link to reset your password.
                        </p>

                        {/* success & error msgs */}

                        {successMsg && displayError && <SuccessAlert />}
                        {errorInfo && displayError && <FailureAlert />}

                        <div className="form-group">
                          <label htmlFor="email" className="visually-hidden">
                            Username
                          </label>
                          <input
                            autoComplete="off"
                            className="form-control"
                            placeholder={t("email-address")}
                            type="text"
                            name="email"
                            onChange={formiks.handleChange}
                            onBlur={formiks.handleBlur}
                            value={formiks.values.email}
                            id="email"
                            title={t("email-address")}
                          />
                        </div>

                        {/* Forgot Validation */}

                        <p className="fw-bold mt-2 text-start shadow-none errorm">
                          {formiks.errors.email &&
                            formiks.touched.email &&
                            formiks.errors.email}
                        </p>
                        <button
                          className="blackbutton"
                          type="submit"
                          title={t("submit")}
                        >
                          {t("submit")}
                        </button>
                      </form>
                    </Formik>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 mb-4">
              <div className="new-customer">
                <h5 class="text-center mb-3">
                  Not Registered? Continue as guest or create an account.
                </h5>
                <div class="d-flex justify-content-between">
                  <div class="col">
                    <div class="row">
                      <div class="col p-3">
                        <div class="p-3 border bg-light">
                          <Formik>
                            <form onSubmit={formikk.handleSubmit}>
                              <div className="text-center">
                                <strong>Checkout as Guest</strong>
                              </div>
                              <div className="form-check mt-5">
                                <input
                                  onChange={formikk.handleChange}
                                  onBlur={formikk.handleBlur}
                                  value="Checkout as Guest"
                                  type="radio"
                                  className="form-check-input"
                                  id="option1"
                                  name="option"
                                />
                                Checkout as Guest
                                <label
                                  className="form-check-label"
                                  htmlFor="option"
                                ></label>
                              </div>

                              <div className="form-group">
                                <button
                                  type="submit"
                                  title="Continue"
                                  className="blackbutton-continue"
                                >
                                  Continue
                                </button>
                              </div>
                            </form>
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col p-3">
                    <div class="p-3 border bg-light">
                      <div className="register-sub-heading text-center mb-3">
                        <strong>Register as a Person</strong>
                      </div>
                      <div className="register-bottom-block">
                        <button
                          className="btn bg-light w-100 mt-2"
                          onClick={() =>
                            navigate(`/${langSwitcRes}/individualRegister`)
                          }
                        >
                          <i
                            className="fa-solid fa-person"
                            style={{ fontSize: "55px" }}
                          ></i>
                          <div className="card-body">
                            <div className="card-text reg-text-small">
                              I AM AN INDIVIDUAL
                              <br /> PERSON
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col pt-3">
                    <div class="p-3 border bg-light">
                      <div className="register-sub-heading text-center mb-3">
                        <strong>Select your profile and Register</strong>
                      </div>
                      <div className="row">
                        <div className="col">
                          <button
                            className="btn bg-light container w-100"
                            onClick={() =>
                              navigate(`/${langSwitcRes}/shopregister`)
                            }
                          >
                            <img
                              style={{ width: "4rem" }}
                              src="https://karactermania.com/kmania/images/banners/icon_shop_80.png"
                              alt="..."
                            />
                            <div className="card-body">
                              <div className="card-text reg-text-small">
                                I HAVE A SHOP
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="col">
                          <button
                            className="btn bg-light container w-100"
                            onClick={() =>
                              navigate(`/${langSwitcRes}/wholeregister`)
                            }
                          >
                            <img
                              style={{ width: "4rem" }}
                              src="/../truck1.png"
                              alt="..."
                            />
                            <div className="card-body">
                              <div className="card-text reg-text-small">
                                I AM A WHOLESALER
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer />
      {res?.status === 200 ? "" : <BatmanPin />} */}
    </>
  );
};
