import React from "react";
import "../../../../../index.css";

export const CasualGaming = () => {
  return (
    <>
      <div className="casual-gaming-cont">
        {/* <a href="#"> */}
        <img
          className="img-fluid"
          src="/images/detailed/casual-gaming.png"
          alt="Casual Gaming"
        />
        {/* </a> */}
      </div>
    </>
  );
};
