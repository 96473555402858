import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../index.css";
import { baseUrls } from "../../../../config/index";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const ProductCategories = () => {
  //Homepage products Response

  const homepageRes = useSelector(
    (state) => state?.HomePageData?.product_type_block
  );

  //Homepage Content Response

  const homepageConRes = useSelector(
    (state) => state?.HomePageData?.product_type_block_content
  );

  //Language Switcher

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,

    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="product-categories-top"></div>
      <div className="product-categories-cont">
        <div className="container">
          {langSwitcRes &&
            homepageConRes
              ?.filter((e) => e?.language === langSwitcRes)
              ?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="section-heading mt-4">{e?.tittle}</div>
                    <p className="section-paragraph">
                      <span
                        dangerouslySetInnerHTML={{ __html: e?.content }}
                      ></span>
                    </p>
                  </React.Fragment>
                );
              })}{" "}
          {homepageConRes?.find((e) => {
            return e?.status === "enable";
          }) ? (
            <>
              <Slider {...settings} className="text-center marvelProducts">
                {homepageRes?.map((e, i) => {
                  const slugData =
                    langSwitcRes === "en"
                      ? e?.slugname_en
                      : langSwitcRes === "es"
                      ? e?.slugname_es
                      : langSwitcRes === "fr"
                      ? e?.slugname_fr
                      : e?.slugname_it;

                  const titleData =
                    langSwitcRes === "en"
                      ? e?.title_en
                      : langSwitcRes === "es"
                      ? e?.title_es
                      : langSwitcRes === "fr"
                      ? e?.title_fr
                      : e?.title_it;
                  return (
                    <React.Fragment key={i}>
                      <div className="card cardmargins border-0 mt-1 pb-3">
                        <Link to={`/${langSwitcRes}/productos/${slugData}`}>
                          <div className="swapimgtwo-container-nb">
                            <img
                              className="card-img-top paacenter"
                              src={`${baseUrls}/${e?.image}`}
                              alt={titleData}
                            />
                          </div>
                        </Link>
                        <Link
                          to={`/${langSwitcRes}/productos/${slugData}`}
                          title={titleData}
                        >
                          {titleData}
                        </Link>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Slider>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="product-categories-bottom"></div>
    </>
  );
};
