import React from "react";
import "../../../../index.css";

export const BrandSupplier = () => {
  return (
    <>
      <div className="product-supplier-cont">
        <div className="product-supplier-cont-bg">
          <div className="row">
            <div className="heading">PRODUCT SUPPLIER</div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <a href="#" alt="text">
                    Frozen products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Disney products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Mickey products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    PJ Masks products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    LOL Surpise products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Marvel products distribution
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <a href="#" alt="text">
                    Star Wars products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Avengers products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Spiderman products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Peppa Pig products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Patrul la Canina products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Lady Bug products distribution
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <a href="#" alt="text">
                    Dragon Ball products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Trolls products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Pokemon products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Minnie Mouse products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Mi pequei\o pony products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Juego de Tronos products distribution
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <ul className="ps-list">
                <li>
                  <a href="#" alt="text">
                    Juego de Tronos products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Rick and Morty products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Bebes Llorones products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Rolling Stones products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Emoji products distribution
                  </a>
                </li>
                <li>
                  <a href="#" alt="text">
                    Harry Potter products distribution
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
