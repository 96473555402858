import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BasicBreadcrumbs() {
  const productDetRes = useSelector((state) => state?.productDetailsData);

  //Language Switcher

  const langSwitcRes = useSelector((state) => state.langSwitchData);

  const navigate = useNavigate();

  return (
    <div className="breadcrumb-cont ">
      <div role="presentation" onClick={handleClick}>
        {productDetRes?.product_details?.map((e, i) => {
          return (
            <React.Fragment key={i}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="/"
                  onClick={() => navigate("/en")}
                >
                  Karactermania
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  href={`/${langSwitcRes}/productos/${e?.brand_slug}`}
                  onClick={() =>
                    navigate(`/${langSwitcRes}/productos/${e?.brand_slug}`)
                  }
                >
                  {e?.brand}
                </Link>
                <Typography color="text.primary">{e?.name}</Typography>
              </Breadcrumbs>
            </React.Fragment>
          );
        })}
      </div>

      {/* <div className="col-6 backbutton">
        <a href={`/${langSwitcRes}/myaccount`}>&#171; Back</a>
      </div> */}
    </div>
  );
}
