import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  accountInfo,
  deleteAddress,
  editAddress,
  loginAsCustomerAction,
  myAddress,
} from "../../../Redux/Action";
import { ChangePassword } from "./ChangePassword";

export const AccountsInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const loginResId = res?.data?.userid;

  const { langSwitcRes } = useParams();

  const { t } = useTranslation();

  //Language Switcher
  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  useEffect(() => {
    dispatch(myAddress(loginResId, langSwitcRes));
    dispatch(accountInfo(loginResId, langSwitcRes));
    dispatch(loginAsCustomerAction(loginResId));
  }, [loginResId, langSwitcRes, dispatch]);

  //AccounntInfo Response

  const accountInfoRes = useSelector((state) => state?.accountInfoData);

  //My Address Response

  const myAddressRes = useSelector((state) => state?.myAddressData);

  return (
    <>
      <div className="row m-1">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 card p-2 mb-3">
          {langSwitcRes &&
            accountInfoRes?.my_account_content
              ?.filter((e) => e?.language === langSwitcRes)
              ?.map((e, i) => {
                return <React.Fragment key={i}>{e?.tittle}</React.Fragment>;
              })}
        </div>
      </div>
      <div className="row">
        {accountInfoRes?.length === 0 ? (
          <div className="col">
            <div class="card text-dark bg-light mb-3">
              <div class="card-header">{t("account-info")}</div>
              <div class="card-body">
                <div class="table-responsive profile-table">
                  <table class="table table-responsive">
                    <tbody>
                      <tr>
                        <td>{t("company-name")}:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>{t("company-id")}:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{t("contact-person")}</strong>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{t("name")}:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>{t("surname")}:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>{t("email")}:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>{t("phone")}:</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>{t("password")}:</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          accountInfoRes?.myaccount?.map((e, i) => {
            return (
              <React.Fragment key={i}>
                <div className="col">
                  <div class="card text-dark bg-light mb-3">
                    <div class="card-header">{t("account-info")}</div>
                    <div class="card-body">
                      <div class="table-responsive profile-table">
                        <table class="table table-responsive">
                          <tbody>
                            <tr>
                              <td>{t("company-name")}:</td>
                              <td>{e?.company}</td>
                            </tr>
                            <tr>
                              <td>{t("company-id")}:</td>
                              <td>{e?.erp_customer_id}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>{t("contact-person")}</strong>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>{t("name")}:</td>
                              <td>{e?.first_name}</td>
                            </tr>
                            <tr>
                              <td>{t("surname")}:</td>
                              <td>{e?.last_name}</td>
                            </tr>
                            <tr>
                              <td>{t("email")}:</td>
                              <td>{e?.email}</td>
                            </tr>
                            <tr>
                              <td>{t("phone")}:</td>
                              <td>{e?.telephone}</td>
                            </tr>
                            <tr>
                              <td>{t("password")}:</td>
                              <td>**********</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })
        )}

        <div className="col">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">{t("my-address")}</div>
            <div class="card-body">
              <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2">
                {myAddressRes?.my_billing_addresses?.length === 0 ? (
                  <>
                    <div class="group relative cursor-pointer rounded border mb-2 p-2 bg-white">
                      <p className="mb-0">
                        <strong>{t("billing-address")}</strong>
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {myAddressRes?.my_shipping_addresses?.length === 0 ? (
                  <>
                    <div class="group relative cursor-pointer rounded border p-2 bg-white">
                      <p className="mb-0">
                        <strong>{t("shipping-address")}</strong>
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {myAddressRes?.my_billing_addresses?.map((e, i) => {
                  const slugData =
                    langSwitcRes === "en"
                      ? e?.name_en
                      : langSwitcRes === "es"
                      ? e?.name_es
                      : langSwitcRes === "fr"
                      ? e?.name_fr
                      : e?.name_it;
                  return (
                    <React.Fragment key={i}>
                      <div class="group relative cursor-pointer rounded border mb-2 p-2 bg-white">
                        <p className="mb-0">
                          <strong>{t("billing-address")}</strong>
                          <br />
                          {e?.address_1}
                          <br />
                          {e?.city}
                          <br />
                          {e?.state}
                          <br />
                          {slugData} - {e?.postal_code}
                          <br />
                          Tel no - {e?.telephone}
                        </p>
                        <div className="mode">
                          <Link
                            to=""
                            className="bi-icon"
                            onClick={() =>
                              dispatch(deleteAddress(e?.address_id))
                            }
                          >
                            <BiTrash size={24} />
                          </Link>
                          <Link
                            to=""
                            className="bi-icon"
                            onClick={() =>
                              dispatch(
                                editAddress(
                                  langSwitcRes,
                                  e?.address_id,
                                  navigate
                                )
                              )
                            }
                          >
                            <BiEdit size={24} />
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}

                {myAddressRes?.my_shipping_addresses?.map((e, i) => {
                  const slugData =
                    langSwitcRes === "en"
                      ? e?.name_en
                      : langSwitcRes === "es"
                      ? e?.name_es
                      : langSwitcRes === "fr"
                      ? e?.name_fr
                      : e?.name_it;
                  return (
                    <React.Fragment key={i}>
                      <div class="group relative cursor-pointer rounded border p-2 bg-white">
                        <p className="mb-0">
                          <strong>{t("shipping-address")}</strong>
                          <br />
                          {e?.address_1}
                          <br />
                          {e?.city}
                          <br />
                          {e?.state}
                          <br />
                          {slugData} - {e?.postal_code}
                          <br />
                          Tel no - {e?.telephone}
                        </p>

                        <div className="mode">
                          <Link
                            to=""
                            className="bi-icon"
                            onClick={() =>
                              dispatch(deleteAddress(e?.address_id))
                            }
                          >
                            <BiTrash size={24} />
                          </Link>
                          <Link
                            to=""
                            className="bi-icon"
                            onClick={() =>
                              dispatch(
                                editAddress(
                                  langSwitcRes,
                                  e?.address_id,
                                  navigate
                                )
                              )
                            }
                          >
                            <BiEdit size={24} />
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">{t("change-password")}</div>
            <div class="card-body">
              <ChangePassword />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
