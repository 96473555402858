import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Reducer } from "./Reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "persist-key",
  storage,
  blacklist: [
    "individualData",
    "shopData",
    "logoutData",
    // "loginData",
    "searchData",
    "forgetPassData",
    "resetPassData",
    "productFiltersData",
    "productListingData",
    "productSortData",
    "placeOrderPaypalData",
    "shippingData",
    "productDetailsData",
    "paymentData",
    "placeOrderData",
    "myOrdersDetailsData",
    "addCartData",
    "promoCodeData",
    "cartBtnData",
    "stepsData",
    "changePasswordData",
    "menuData",
    "changeBillingData",
    "changeShippingData",
    // "myAddressData",
    "reCaptchaData",
  ],
};

const persistedReducer = persistReducer(persistConfig, Reducer);

export const Store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(Store);

export { persistor };
