import React from "react";
import { useSelector } from "react-redux";
import { baseUrls } from "../../../../config";

export const BrandConditions2 = () => {
  const categoryConRes = useSelector(
    (state) => state?.brandPageData?.content_block
  );

  return (
    <>
      <div className="col-md-12 col-sm-12 p-0">
        <div className="banner-v-center-text-cont">
          <div className="banner-v-center-text-bg">
            {categoryConRes?.slice(0, 1)?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <img src={`${baseUrls}/${e?.image}`} alt={e?.tittle} />
                  <div className="left">
                    <div className="hs-cont">
                      <h2
                        style={{ color: "#0da6e9" }}
                        className="text-uppercase"
                      >
                        {e?.tittle}
                      </h2>
                      <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
