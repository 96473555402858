import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { baseUrls } from "../../../../config/index";

export const CategoryFullWidth3 = () => {
  const categoryPageRes = useSelector((state) => state?.categoryData?.banner_4);

  const { slugName } = useParams();

  //Language Switcher

  // const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const { langSwitcRes } = useParams();

  return (
    <>
      <div className="licensedproducts-cont">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 p-0 mr-0">
              {categoryPageRes?.slice(0, 1)?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="graybg-sm">
                      <img
                        src={`${baseUrls}/${e?.image}`}
                        alt={e?.tittle}
                        className="w-100"
                      />
                      <div className="text-white p-3 lptcont">
                        <h2>{e?.tittle}</h2>
                        <span
                          dangerouslySetInnerHTML={{ __html: e?.content }}
                        ></span>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
