import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../../Common/Footer/Footer";
import {
  b2bRegisterResetAction,
  countryApi,
  reCaptchaApi,
  registerContentAction,
  registerShopApi,
} from "../../../../Redux/Action";
import { useFormik } from "formik";
import { Formik } from "formik";
import { useParams } from "react-router";
import { BatmanPin } from "../../../Common/Footer/BatmanPin";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Header } from "../../../Common/Header/Header";

export const WholeRegister = () => {
  const { langSwitcRes } = useParams();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const formik = useFormik({
    initialValues: {
      first: "",
      last: "",
      company: "",
      web: "",
      vatNo: "",
      countryId: "",
      phone: "",
      billing: "",
      state: "",
      city: "",
      zipCode: "",
      email: "",
      password: "",
      confirm: "",
      itemName: "wholesaler",
      acceptTerms: false,
    },
    validate: (values) => {
      const errors = {};

      //First

      if (!values.first) {
        errors.first = t("firstname-validation");
      } else if (!/^[a-zA-ZÀ-ÿ-. ]*$/.test(values.first)) {
        errors.first = t("first_name_validation");
      }

      //last

      if (!values.last) {
        errors.last = t("lastname-validation");
      } else if (!/^[a-zA-ZÀ-ÿ-. ]*$/.test(values.last)) {
        errors.last = t("last_name_validation");
      }

      //Company

      if (!values.company) {
        errors.company = t("company-validation");
      }

      //Vat no

      if (!values.vatNo) {
        errors.vatNo = t("tax-validation");
      }

      //Phone

      if (!values.phone) {
        errors.phone = t("phone-validation");
      } else if (!/^[0-9]*$/.test(values.phone)) {
        errors.phone = t("telephone_validation");
      }

      //Billing Address

      if (!values.billing) {
        errors.billing = t("billing-validation");
      }

      //Country

      if (!values.countryId) {
        errors.countryId = t("country-validation");
      }

      //State

      if (!values.state) {
        errors.state = t("state-validation");
      }

      //City

      if (!values.city) {
        errors.city = t("city-validation");
      }

      //Zipcode

      if (!values.zipCode) {
        errors.zipCode = t("zipcode-validation");
      }

      //Password

      if (!values.password) {
        errors.password = t("password-validation");
      } else if (values.password.length < 6) {
        errors.password = t("password_format_validation");
      }

      //confirm password

      if (!values.confirm) {
        errors.confirm = t("confirm-password-validation");
      } else if (values.confirm.length < 6) {
        errors.confirm = t("confirm_password_validation");
      } else if (values.password !== values.confirm) {
        errors.confirm = t("password_validation");
      }

      //Email

      if (!values.email) {
        errors.email = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("email_format_validation");
      }

      //Terms and conditions

      if (!values.acceptTerms) {
        errors.acceptTerms = t("terms-validation");
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      dispatch(registerShopApi(values, { resetForm }, langSwitcRes));
    },
  });

  useEffect(() => {
    dispatch(countryApi(langSwitcRes));
    dispatch(registerContentAction(langSwitcRes));
  }, [dispatch, langSwitcRes]);

  const { successMsg, errorInfo } = useSelector((state) => state?.shopData);

  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (errorInfo || successMsg) {
      setDisplayError(true);

      setTimeout(() => {
        dispatch(b2bRegisterResetAction());
        setDisplayError(false);
      }, 8000);
    }
  }, [successMsg, errorInfo]);

  const SuccessAlert = () => {
    return (
      <div
        className="alert alert-success d-flex align-items-center p-0"
        role="alert"
      >
        <i
          className="fa fa-check text-success fw-bold me-2 ms-3"
          aria-hidden="true"
          style={{ fontSize: "17px" }}
        ></i>
        <div className="mt-3">
          <p style={{ fontSize: "13px" }}>{successMsg}</p>
        </div>
      </div>
    );
  };

  const FailureAlert = () => {
    return (
      <div
        className="alert alert-danger d-flex align-items-center p-0 mt-4"
        role="alert"
      >
        <i
          className="fa fa-exclamation-circle me-2 ms-3 text-danger"
          aria-hidden="true"
        ></i>
        <div className="register-error-message">{errorInfo}</div>
      </div>
    );
  };

  //Register Content Response

  const regConRes = useSelector((state) => state?.regContentData);

  //Country Response in Register

  const counData = useSelector((state) => state?.countryData);

  //Recaptcha

  const captchaKey = "6LfkTwYlAAAAAM3qvFGXAnHO5Iwdf7QAvNOWD_iX";

  function onChange(value) {
    dispatch(reCaptchaApi(value, captchaKey));
  }

  return (
    <>
      <Header pathData="wholeRegister" />
      <div className="btwob container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-5">
            <div className="card bg-light">
              <div className="card-body">
                {langSwitcRes &&
                  regConRes?.wholesaler_registerblock?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="product-main-title text-center mb-3">
                          {e?.tittle}
                        </div>

                        <p className="card-text text-center text-justify">
                          {e?.content}
                        </p>
                      </React.Fragment>
                    );
                  })}

                <div className="card-body">
                  {/* Success & error msgs */}

                  {successMsg && displayError && <SuccessAlert />}
                  {errorInfo && displayError && <FailureAlert />}

                  {/* Form */}

                  <Formik>
                    <form onSubmit={formik.handleSubmit}>
                      {/* Required Field */}
                      <div class="required-field">{t("required-fields")}</div>

                      {/*first */}
                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          title={t("first-name")}
                          name="first"
                          placeholder={t("first-name")}
                          onChange={formik.handleChange}
                          value={formik.values.first}
                          autoComplete="off"
                          id="first"
                        />
                        <label htmlFor="first" class="visually-hidden">
                          First Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.first &&
                          formik.touched.first &&
                          formik.errors.first}
                      </p>

                      {/* last */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="last"
                          title={t("last-name")}
                          placeholder={t("last-name")}
                          onChange={formik.handleChange}
                          value={formik.values.last}
                          autoComplete="off"
                          id="last"
                        />
                        <label htmlFor="last" class="visually-hidden">
                          Last Name <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.last &&
                          formik.touched.last &&
                          formik.errors.last}
                      </p>

                      {/* company */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="company"
                          title={t("company")}
                          placeholder={t("company")}
                          onChange={formik.handleChange}
                          value={formik.values.company}
                          autoComplete="off"
                          id="company"
                        />
                        <label htmlFor="company" class="visually-hidden">
                          Company <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.company &&
                          formik.touched.company &&
                          formik.errors.company}
                      </p>

                      {/* web */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="web"
                          title={t("website")}
                          placeholder={t("website")}
                          onChange={formik.handleChange}
                          value={formik.values.web}
                          autoComplete="off"
                          id="web"
                        />
                        <label htmlFor="web" class="visually-hidden">
                          Web
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.web &&
                          formik.touched.web &&
                          formik.errors.web}
                      </p>

                      {/* Vat no */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="vatNo"
                          title={t("vat-number")}
                          placeholder={t("vat-number")}
                          onChange={formik.handleChange}
                          value={formik.values.vatNo}
                          autoComplete="off"
                          id="vatNo"
                        />
                        <label htmlFor="vatNo" class="visually-hidden">
                          Tax/VAT number <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.vatNo &&
                          formik.touched.vatNo &&
                          formik.errors.vatNo}
                      </p>

                      {/* phone */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="phone"
                          title={t("phone-number")}
                          placeholder={t("phone-number")}
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          autoComplete="off"
                          id="phone"
                        />
                        <label htmlFor="phone" class="visually-hidden">
                          Telephone <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.phone &&
                          formik.touched.phone &&
                          formik.errors.phone}
                      </p>

                      {/* Billing address */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="billing"
                          title={t("billing-address")}
                          placeholder={t("billing-address")}
                          onChange={formik.handleChange}
                          value={formik.values.billing}
                          autoComplete="off"
                          id="billing"
                        />
                        <label htmlFor="billing" class="visually-hidden">
                          Billing Address <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.billing &&
                          formik.touched.billing &&
                          formik.errors.billing}
                      </p>

                      {/* country */}

                      <div className="mb-3 mb-3">
                        <select
                          className="form-select"
                          id="countryId"
                          name="countryId"
                          title="Country"
                          placeholder={t("select-country")}
                          aria-label="Floating label select example"
                          value={formik.values.countryId}
                          onChange={formik.handleChange}
                        >
                          <option>
                            {t("select-country")}
                            {/* <label style={{ color: "red" }}>*</label> */}
                          </option>
                          {counData?.map((e, i) => {
                            const slugData =
                              langSwitcRes === "en"
                                ? e?.name_en
                                : langSwitcRes === "es"
                                ? e?.name_es
                                : langSwitcRes === "fr"
                                ? e?.name_fr
                                : e?.name_it;
                            return (
                              <React.Fragment key={i}>
                                <option value={e.country_id}>{slugData}</option>
                              </React.Fragment>
                            );
                          })}
                        </select>
                      </div>
                      <p className="register-error">
                        {formik.errors.countryId &&
                          formik.touched.countryId &&
                          formik.errors.countryId}
                      </p>

                      {/* State/Province */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          title={t("state")}
                          name="state"
                          placeholder={t("state")}
                          onChange={formik.handleChange}
                          value={formik.values.state}
                          autoComplete="off"
                          id="state"
                        />
                        <label htmlFor="state" class="visually-hidden">
                          State/Province <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.state &&
                          formik.touched.state &&
                          formik.errors.state}
                      </p>

                      {/* City */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          title={t("city")}
                          placeholder={t("city")}
                          onChange={formik.handleChange}
                          value={formik.values.city}
                          autoComplete="off"
                          id="city"
                        />
                        <label htmlFor="city" class="visually-hidden">
                          City <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.city &&
                          formik.touched.city &&
                          formik.errors.city}
                      </p>

                      {/* ZIP code */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="zipCode"
                          title={t("zipcode")}
                          placeholder={t("zipcode")}
                          onChange={formik.handleChange}
                          value={formik.values.zipCode}
                          autoComplete="off"
                          id="zipCode"
                        />
                        <label htmlFor="zipCode" class="visually-hidden">
                          ZIP Code <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.zipCode &&
                          formik.touched.zipCode &&
                          formik.errors.zipCode}
                      </p>

                      {/* Email */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          title={t("email-address")}
                          placeholder={t("email-address")}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          autoComplete="off"
                          id="email"
                        />
                        <label htmlFor="email" class="visually-hidden">
                          Email Address <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.email &&
                          formik.touched.email &&
                          formik.errors.email}
                      </p>

                      {/* Password */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          title={t("password")}
                          placeholder={t("password")}
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          autoComplete="off"
                          id="password"
                        />
                        <label htmlFor="password" class="visually-hidden">
                          Password <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.password &&
                          formik.touched.password &&
                          formik.errors.password}
                      </p>

                      {/*Confirm Password */}

                      <div className="mt-3">
                        <input
                          className="form-control"
                          type="password"
                          name="confirm"
                          title={t("confirm-password")}
                          placeholder={t("confirm-password")}
                          onChange={formik.handleChange}
                          value={formik.values.confirm}
                          autoComplete="off"
                          id="confirm"
                        />
                        <label htmlFor="confirm" class="visually-hidden">
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <p className="register-error">
                        {formik.errors.confirm &&
                          formik.touched.confirm &&
                          formik.errors.confirm}
                      </p>

                      {/* Check */}

                      <input
                        className="fs-3"
                        type="checkbox"
                        name="acceptTerms"
                        onChange={formik.handleChange}
                        value={formik.values.acceptTerms}
                        checked={formik.values.acceptTerms}
                        id="acceptTerms"
                      />

                      <label class="form-check-label" htmlFor="gridCheck">
                        <a
                          href={`/${langSwitcRes}/legal-notice`}
                          target="_blank"
                          className="terms-and-condition"
                        >
                          {t("terms-validation")}
                          <span className="text-danger">*</span>
                        </a>
                      </label>

                      <p className="register-error">
                        {formik.errors.acceptTerms &&
                          formik.touched.acceptTerms &&
                          formik.errors.acceptTerms}
                      </p>

                      <ReCAPTCHA
                        sitekey="6LfkTwYlAAAAAIQkFzMJbDHPPwW5lfi904elwdPL"
                        onChange={onChange}
                      />

                      {/* Create an account */}
                      <button
                        type="submit"
                        className="blackbutton mb-5"
                        title={t("create-account")}
                      >
                        {t("create-account")}
                      </button>
                    </form>
                  </Formik>

                  {/* Success & error msgs */}

                  {successMsg && displayError && <SuccessAlert />}
                  {errorInfo && displayError && <FailureAlert />}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
            {langSwitcRes &&
              regConRes?.wholesaler_validationblock?.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="product-main-title">{e?.tittle}</div>
                    <div dangerouslySetInnerHTML={{ __html: e?.content }}></div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>

      {res?.status === 200 ? "" : <BatmanPin />}

      <Footer />
    </>
  );
};
