import React from "react";
import { CustomerArea } from "./CustomerArea";
import { Footer } from "../../Common/Footer/Footer";
import { Header } from "../../Common/Header/Header";

export const MyAccounts = () => {
  return (
    <>
      <Header pathData="myaccount" />
      <CustomerArea />
      <Footer />
    </>
  );
};
