import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  billingAction,
  countryApi,
  myAddress,
  stepsAction,
} from "../../../../Redux/Action";

export const CheckoutBilling = ({ setStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { langSwitcRes } = useParams();

  const { t } = useTranslation();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const loginResId = res?.data?.userid;

  useEffect(() => {
    dispatch(countryApi(langSwitcRes));
    dispatch(myAddress(loginResId, langSwitcRes));
  }, [loginResId, langSwitcRes, dispatch]);

  //Country Response in Register

  const counData = useSelector((state) => state?.countryData);

  //My Address Response

  const myAddressRes = useSelector(
    (state) => state?.myAddressData?.my_billing_addresses
  );

  //change billing Res

  const changeBillingRes = useSelector(
    (state) => state?.changeBillingData?.my_addresses
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first:
        changeBillingRes !== undefined
          ? changeBillingRes[0]?.first_name
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.first_name
          : "",
      last:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.last_name
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.last_name
          : "",
      countryId:
        langSwitcRes === "en"
          ? changeBillingRes?.length > 0
            ? counData?.filter(
                (e) => e?.name_en === changeBillingRes[0]?.name_en
              )[0]?.country_id
            : myAddressRes?.length > 0
            ? counData?.filter(
                (e) => e?.name_en === myAddressRes[0]?.name_en
              )[0]?.country_id
            : ""
          : langSwitcRes === "es"
          ? changeBillingRes?.length > 0
            ? counData?.filter(
                (e) => e?.name_es === changeBillingRes[0]?.name_es
              )[0]?.country_id
            : myAddressRes?.length > 0
            ? counData?.filter(
                (e) => e?.name_es === myAddressRes[0]?.name_es
              )[0]?.country_id
            : ""
          : langSwitcRes === "fr"
          ? changeBillingRes?.length > 0
            ? counData?.filter(
                (e) => e?.name_fr === changeBillingRes[0]?.name_fr
              )[0]?.country_id
            : myAddressRes?.length > 0
            ? counData?.filter(
                (e) => e?.name_fr === myAddressRes[0]?.name_fr
              )[0]?.country_id
            : ""
          : changeBillingRes?.length > 0
          ? counData?.filter(
              (e) => e?.name_it === changeBillingRes[0]?.name_it
            )[0]?.country_id
          : myAddressRes?.length > 0
          ? counData?.filter((e) => e?.name_it === myAddressRes[0]?.name_it)[0]
              ?.country_id
          : "",

      phone:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.telephone
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.telephone
          : "",
      email: changeBillingRes?.length > 0 ? res?.data?.email : res?.data?.email,
      billing1:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.address_1
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.address_1
          : "",
      billing2:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.address_2
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.address_2
          : "",
      state:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.state
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.state
          : "",
      city:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.city
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.city
          : "",
      zipCode:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.postal_code
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.postal_code
          : "",
      addressBook: "",
      option: "",
      addressId:
        changeBillingRes?.length > 0
          ? changeBillingRes[0]?.address_id
          : myAddressRes?.length > 0
          ? myAddressRes[0]?.address_id
          : "",
    },

    validate: (values) => {
      const errors = {};

      //First

      if (!values.first) {
        errors.first = t("firstname-validation");
      } else if (!/^[a-zA-Z ]*$/.test(values.first)) {
        errors.first = t("first_name_validation");
      }

      //last

      if (!values.last) {
        errors.last = t("lastname-validation");
      } else if (!/^[a-zA-Z ]*$/.test(values.last)) {
        errors.last = t("last_name_validation");
      }

      //Phone

      if (!values.phone) {
        errors.phone = t("phone-validation");
      } else if (!/^[0-9]*$/.test(values.phone)) {
        errors.phone = t("telephone_validation");
      }

      //Email

      if (!values.email) {
        errors.email = t("email-validation");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t("email_format_validation");
      }

      //Billing Address 1

      if (!values.billing1) {
        errors.billing1 = t("billing-validation");
      }

      //Country

      if (!values.countryId) {
        errors.countryId = t("country-validation");
      }

      //State

      if (!values.state) {
        errors.state = t("state-validation");
      }

      //City

      if (!values.city) {
        errors.city = t("city-validation");
      }

      //Zipcode

      if (!values.zipCode) {
        errors.zipCode = t("zipcode-validation");
      }

      //Option

      if (!values.option) {
        errors.option = t("choose_one_address");
      }

      return errors;
    },
    onSubmit: (values) => {
      dispatch(billingAction(values, loginResId, setStep));
      // dispatch(stepsAction({ step: "step3", stepValue: true }));
    },
  });

  useEffect(() => {
    dispatch(
      stepsAction([
        { step: "step1", stepValue: true },
        { step: "step2", stepValue: true },
        { step: "step3", stepValue: true },
        { step: "step4", stepValue: false },
        { step: "step5", stepValue: false },
      ])
    );
  }, []);

  const newInputValue = (newName, newValue) => {
    formik.setFieldValue(newName, newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "2rem", marginBottom: "5rem" }}
      >
        <div className="billing-cont">
          <div className="required-field">* Required Fields</div>

          <Formik>
            <form className="row g-3" onSubmit={formik.handleSubmit}>
              {/* First name */}

              <div className="col-md-6">
                <label htmlFor="first" className="form-label visually-hidden">
                  First Name
                </label>
                <input
                  name="first"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik?.values?.first}
                  id="first"
                  type="text"
                  className="form-control"
                  placeholder={t("first-name")}
                  autoComplete="off"
                />

                {/* First name validations */}

                <p className="register-error">
                  {formik.errors.first &&
                    formik.touched.first &&
                    formik.errors.first}
                </p>
              </div>

              <div className="col-md-6">
                <label htmlFor="last" className="form-label visually-hidden">
                  Last Name
                </label>
                <input
                  name="last"
                  autoComplete="off"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.last}
                  id="last"
                  type="text"
                  className="form-control"
                  placeholder={t("last-name")}
                />

                {/* Last name validations */}

                <p className="register-error">
                  {formik.errors.last &&
                    formik.touched.last &&
                    formik.errors.last}
                </p>
              </div>
              <div className="col-12">
                <label htmlFor="email" className="form-label">
                  {t("email_information")}
                </label>
                {/* Email Address  */}
                <input
                  name="email"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.email}
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder={t("email-address")}
                  autoComplete="off"
                />
                {/* email Validations */}
                <p className="register-error">
                  {formik.errors.email &&
                    formik.touched.email &&
                    formik.errors.email}
                </p>
              </div>

              {/* Billing Address 1 */}

              <div className="col-12">
                <input
                  name="billing1"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.billing1}
                  id="billing1"
                  type="text"
                  className="form-control"
                  placeholder={t("address1")}
                  autoComplete="off"
                />

                {/* Billing 1 Validations */}

                <p className="register-error">
                  {formik.errors.billing1 &&
                    formik.touched.billing1 &&
                    formik.errors.billing1}
                </p>
              </div>

              {/* Billing Address 2 */}

              <div className="col-12">
                <input
                  name="billing2"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.billing2}
                  id="billing2"
                  type="text"
                  className="form-control"
                  placeholder={t("address2")}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="countryId"
                  className="form-label visually-hidden"
                >
                  Country
                </label>
                <select
                  id="countryId"
                  name="countryId"
                  aria-label="Floating label select example"
                  value={formik.values.countryId}
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  className="form-select"
                  aria-required="true"
                  aria-invalid="true"
                >
                  <option value="" selected>
                    {t("select-country")}
                  </option>

                  {counData?.map((e) => {
                    const slugData =
                      langSwitcRes === "en"
                        ? e?.name_en
                        : langSwitcRes === "es"
                        ? e?.name_es
                        : langSwitcRes === "fr"
                        ? e?.name_fr
                        : e?.name_it;
                    return (
                      <>
                        <option value={e.country_id}>{slugData}</option>
                      </>
                    );
                  })}
                </select>

                {/* select country Validation */}

                <p className="register-error">
                  {formik.errors.countryId &&
                    formik.touched.countryId &&
                    formik.errors.countryId}
                </p>
              </div>
              <div className="col-md-6">
                <label htmlFor="state" className="form-label visually-hidden">
                  State / Province
                </label>
                <input
                  name="state"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.state}
                  id="state"
                  type="text"
                  className="form-control"
                  placeholder={t("state")}
                  autoComplete="off"
                />

                {/* state Validation */}

                <p className="register-error">
                  {formik.errors.state &&
                    formik.touched.state &&
                    formik.errors.state}
                </p>
              </div>
              <div className="col-md-6">
                <label htmlFor="city" className="form-label visually-hidden">
                  City
                </label>
                <input
                  name="city"
                  type="text"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.city}
                  id="city"
                  className="form-control"
                  placeholder={t("city")}
                  autoComplete="off"
                />

                {/* City Validation */}

                <p className="register-error">
                  {formik.errors.city &&
                    formik.touched.city &&
                    formik.errors.city}
                </p>
              </div>
              <div className="col-md-6">
                <label htmlFor="zipCode" className="form-label visually-hidden">
                  Zip/Postal Code
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="zipCode"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.zipCode}
                  id="zipCode"
                  placeholder={t("zipcode")}
                  autoComplete="off"
                />

                {/* Postal code Validation */}

                <p className="register-error">
                  {formik.errors.zipCode &&
                    formik.touched.zipCode &&
                    formik.errors.zipCode}
                </p>
              </div>
              <div className="col-12">
                <label for="#" className="form-label">
                  {t("contact_information")}
                </label>
                <input
                  name="phone"
                  onChange={(e) => newInputValue(e.target.name, e.target.value)}
                  value={formik.values.phone}
                  id="phone"
                  type="text"
                  className="form-control"
                  placeholder={t("phone-number")}
                  autoComplete="off"
                />

                {/* Phone Validation */}

                <p className="register-error">
                  {formik.errors.phone &&
                    formik.touched.phone &&
                    formik.errors.phone}
                </p>
              </div>
              <div className="col-12">
                {res?.status == 200 ? (
                  <>
                    <div className="form-check disabled">
                      <input
                        onChange={formik.handleChange}
                        value="Save to the address book"
                        id="addressBook"
                        name="addressBook"
                        className="form-check-input"
                        type="checkbox"
                      />
                      <label className="form-check-label" for="gridRadios3">
                        {t("save_to_address_book")}
                      </label>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="form-check">
                  <input
                    onChange={formik.handleChange}
                    value="Ship to this address"
                    id="option1"
                    name="option"
                    className="form-check-input"
                    type="radio"
                  />
                  <label className="form-check-label" htmlFor="option">
                    {t("ship_to_address")}
                    <span className="required-field">*</span>
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={formik.handleChange}
                    value="Ship to different address"
                    id="option2"
                    name="option"
                    className="form-check-input"
                    type="radio"
                  />
                  <label className="form-check-label" htmlFor="option">
                    {t("ship_to_different_address")}
                    <span className="required-field">*</span>
                  </label>
                </div>
              </div>
              {/* address Validation */}

              <p className="register-error">
                {formik.errors.option &&
                  formik.touched.option &&
                  formik.errors.option}
              </p>
              <div className="col-12 mt-0">
                <button
                  type="submit"
                  title="Continue"
                  className="blackbutton mb-5"
                >
                  Continue
                </button>
              </div>
            </form>
          </Formik>
        </div>
      </div>
    </>
  );
};
