import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import BasicBreadcrumbs from "./BasicBreadcrubms";
import { useDispatch, useSelector } from "react-redux";
import "./Products.css";
import ProductGrid from "./ProductGrid";
import ProductList from "./ProductList";
import {
  productFiltersAction,
  productListingAction,
  productSortAction,
  // productTypeListAction,
} from "../../../../../Redux/Action";
import { baseUrlColor } from "../../../../../config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ProductBanner } from "./ProductBanner";
import PropagateLoader from "react-spinners/PropagateLoader";
import DoneIcon from "@mui/icons-material/Done";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const Products = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [showList, setShowList] = useState(false);
  const [selectAcc, setSelectAcc] = useState("");
  const [loading, setLoading] = useState(true);
  const [secLoader, setSecLoader] = useState(false);
  const [brandTypeValue, setBrandTypeValue] = useState([]);
  const [productTypeValue, setProductTypeValue] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [colorFilter, setColorFilter] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [priceFilter, setPriceFilter] = useState("ztoa");
  const [selectedValue, setSelectedValue] = useState([]);
  const [brandSelectedValue, setBrandSelectedValue] = useState([]);
  const [subCatName, setSubCatName] = useState([]);
  const [resetProduct, setResetProduct] = useState("");
  const [brandLicense, setBrandLicense] = useState("");
  const [productSelect, setProductSelect] = useState("");

  //Filterby checked

  const dispatch = useDispatch();

  const { title, page_title } = useParams();

  const { langSwitcRes } = useParams();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  const [currentHeight, setcurrentHeight] = useState();

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 700) {
        setcurrentHeight(window.pageYOffset);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const [tempPageCount, setTempPageCount] = useState(1);

  //ProductListing API Response
  const [apiStatus, setApiStatus] = useState(false);

  useEffect(() => {
    if (tempPageCount === undefined) return;
    setApiStatus(true);
    dispatch(
      productListingAction(
        title,
        page_title,
        res,
        langSwitcRes,
        itemOffset,
        priceFilter,
        tempPageCount
      )
    );
  }, [
    title,
    page_title,
    res,
    langSwitcRes,
    itemOffset,
    priceFilter,
    tempPageCount,
  ]);

  const [pagenumber, setpagenumber] = useState([]);

  const contentTitle = useSelector((state) => state?.productListingData);

  useEffect(() => {
    if (
      brandLicense?.length > 0 ||
      productTypeValue?.length > 0 ||
      brandTypeValue?.length > 0 ||
      itemOffset !== 1 ||
      tempPageCount === contentTitle?.no_pages ||
      window.innerWidth < 1024
    )
      return;
    let pagecount = contentTitle?.no_pages;
    const triggerHeight = 1000;
    if (itemOffset !== 1) return;

    // let result = pagenumber?.find(pn => pagenumber?.length + 1 === pn)
    if (
      pagenumber?.length > 0 &&
      currentHeight > triggerHeight * pagenumber?.length + 1
    ) {
      setTempPageCount(pagenumber?.length + 1);
      setpagenumber((prevState) => [...prevState, pagenumber?.length + 1]);
    } else if (pagenumber?.length === 0) {
      if (currentHeight > triggerHeight && pagecount >= 1) {
        setTempPageCount(2);
        setpagenumber((prevState) => [...prevState, 2]);
      }
    }
  }, [currentHeight]);

  const productListingRes = useSelector(
    (state) => state?.productListingData?.Product_list
  );

  //Product listing Response

  const [data, setData] = useState([]);

  const catagoryBlockRes = useSelector(
    (state) => state?.productListingData?.Category_block
  );

  const productTypeData = useSelector(
    (state) => state?.productListingData?.product_type_list
  );

  const brandTypeData = useSelector(
    (state) => state?.productListingData?.brand_type_list
  );

  //Products filter API Response

  const productFiltersResponse = useSelector(
    (state) => state?.productFiltersData?.Sub_Category_Details
  );

  const productFiltersProductType = useSelector(
    (state) => state?.productFiltersData?.product_unique
  );

  const totalFiltersCount = useSelector(
    (state) => state?.productFiltersData?.product_count
  );

  const productFilterRes = useSelector(
    (state) => state?.productFiltersData?.product_list
  );

  const productSortRes = useSelector(
    (state) => state?.productSortData?.product_list
  );

  const totalSortCount = useSelector(
    (state) => state?.productSortData?.product_count
  );

  const showGridHandler = () => {
    setShowGrid(true);
    setShowList(false);
  };

  const showListHandler = () => {
    setShowGrid(false);
    setShowList(true);
  };

  // Dropdown Icon
  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  //Category Filter
  const handleLicense = (e, names, id, values) => {
    setSecLoader(true);
    const { value, name } = e.target;

    if (name === "productType") {
      if (e.target.checked) {
        setProductTypeValue((pState) => [...pState, value]);
        setProductSelect(value);
      } else {
        const productFilter = productTypeValue?.filter((e) => e !== value);
        setProductTypeValue([...productFilter]);
        setProductSelect(productFilter[productFilter?.length - 1]);
      }
    }

    if (name === "color") {
      if (e.target.checked) {
        setColorFilter((pState) => [...pState, value]);
      } else {
        setColorFilter(colorFilter?.filter((e) => e !== value));
      }
    }
  };

  // Product Type Selection
  const handleLicenses = (e) => {
    e.preventDefault();
    setSecLoader(true);
    const { value, name } = e.target;
    setResetProduct(value === "X" ? "" : value);
    setProductSelect(value);
    if (name == "productTypeDefault") {
      setSelectedValue({ name });
    }
    setSelectedValue({ name, value });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Scroll to Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [chosenFilter, setChoseFilter] = useState([]);
  const [filterInfo, setFilterInfo] = useState([]);
  // Saving the Filter API Response in State
  useEffect(() => {
    setFilterInfo(contentTitle?.Sub_Category_Details?.[0]?.subcat);
  }, [contentTitle?.Sub_Category_Details?.[0]]);

  // Horizontal License Dropdown - Select ALL
  const handleSortBy = (id, index) => {
    setFilterInfo(
      filterInfo?.map((FI) => {
        if (FI?.subcatid === id) {
          const data = FI?.brandtype?.map((FIBT) => {
            setChoseFilter((prevState) => [...prevState, FIBT?.slug_name]);
            return { ...FIBT, selected: true };
          });
          return { ...FI, selected: true, brandtype: data };
        } else {
          return FI;
        }
      })
    );
    setExpanded(`panel${index}`);
  };

  // Horizontal License Dropdown - 2nd Level Category Selection
  const handleHorLicense = (id, pid, selected, e, i, textValue) => {
    e.stopPropagation();
    setFilterInfo(
      filterInfo?.map((FI) => {
        const data = FI?.brandtype?.find((FIBT) => FIBT?.selected === true);

        if (FI?.subcatid === pid) {
          const data = FI?.brandtype?.map((FIBT) => {
            if (FIBT?.id !== id) {
              return FIBT;
            } else if (selected) {
              setChoseFilter(
                chosenFilter?.filter((CF, i) => {
                  if (CF === FIBT?.slug_name) {
                    chosenFilter?.slice(i - 1, i);
                  }
                })
              );
              return { ...FIBT, selected: false };
            } else {
              setChoseFilter((prevState) => [...prevState, FIBT?.slug_name]);
              return { ...FIBT, selected: true };
            }
          });
          return { ...FI, brandtype: data };
        } else if (data == undefined) {
          return { ...FI, ...(FI.selected = false) };
        } else {
          return { ...FI };
        }
      })
    );
    // debugger;
    if (textValue === "leftFilter") {
      setExpanded(`panel${i}`);
    }
  };

  const [currentSort, setCurrentSort] = useState();

  // Horizontal License DropDown - Cluster Selection (For Sorting)
  const SortClusterID = (clusterid, btype, i) => {
    let subFilter = [];
    filterInfo?.filter((FI) => {
      if (FI?.subcatid === clusterid) {
        setCurrentSort(FI?.subcatid);
        setBrandLicense(FI?.subcatid);
        FI?.brandtype?.filter((FIBT) => {
          btype?.filter((BT) => {
            if (BT?.selected === true && BT?.slug_name === FIBT?.slug_name) {
              subFilter?.push(FIBT?.slug_name);
            }
          });
        });
      }
    });
    if (clusterid === "LICENSES") {
      setSortCluster("LICENSES");
      setBrandTypeValue(chosenFilter);
      if (chosenFilter?.length === 0) {
        return dispatch(
          productListingAction(
            title,
            page_title,
            res,
            langSwitcRes,
            itemOffset,
            priceFilter,
            tempPageCount
          )
        );
      }
    } else {
      setBrandTypeValue(subFilter);
    }

    setExpanded(`panel${i}`);
  };

  // Save the selected Child ID's in brandtypevalue, after the selection in the filter
  useEffect(() => {
    let data = [];
    filterInfo?.filter((FI) => {
      FI?.brandtype?.filter((FIBT) => {
        if (FIBT?.selected === true) {
          // Code to Add the ID from the State, while Selecting
          data.push(FIBT?.slug_name);
        }
        data?.length > 0 &&
          data?.filter((BTV, i) => {
            if (BTV === FIBT?.slug_name && FIBT?.selected !== true) {
              data?.slice(0, i);
            }
          });
      });
    });
    setBrandTypeValue(data);
  }, [filterInfo]);

  // TO Get the Current Selected Sorting Name
  const [sortCluster, setSortCluster] = useState();
  useEffect(() => {
    filterInfo?.map((FI) => {
      if (FI?.subcatid === currentSort) {
        setSortCluster(FI?.subcatname);
      }
    });
  }, [currentSort]);

  //brand count
  const brandCount = contentTitle?.brand_count;

  // SetState to Open the current Child against the Mouser Hover on Parent.
  const [clusID, setClusID] = useState(0);

  // Horizontal License DropDown JSX
  const productTypeHorizontal = useMemo(() => {
    return (
      <div class="dropdownLicense">
        <a
          class="btn btn-# dropdown-toggle plp_licence"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {sortCluster ? sortCluster : "LICENSES"}
        </a>
        <ul className="plp__producttype dropdown-menu">
          {sortCluster && (
            <li
              className={`dropdown-item plp__cluster`}
              onClick={() => SortClusterID("LICENSES", "LICENSES", "LICENSES")}
            >
              LICENSES
            </li>
          )}
          {filterInfo?.map((cluster, i) => (
            <li
              className={`dropdown-item plp__cluster ${
                cluster?.brandtype?.find((bt) => bt?.selected) && "selected"
              }`}
              onMouseEnter={() => setClusID(cluster?.subcatid)}
              onMouseLeave={() => setClusID(0)}
              onClick={() =>
                SortClusterID(cluster?.subcatid, cluster.brandtype, i)
              }
            >
              {cluster?.subcatname}
              {cluster?.brandtype?.find((bt) => bt?.selected) && <DoneIcon />}
              {clusID === cluster?.subcatid && (
                <ul className="plp__clusterBrands">
                  <li
                    className="plp__selectall"
                    onClick={() => handleSortBy(cluster?.subcatid, i)}
                  >
                    Select All
                  </li>
                  {cluster?.brandtype?.map((cb) => (
                    <>
                      {Object.keys(brandCount)?.length > 0 &&
                      Object.keys(brandCount)
                        ?.map((key) => key)
                        ?.includes(cb?.slug_name) ? (
                        <li
                          className={`${
                            cb?.selected ? "selected" : "notselected"
                          }`}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignContent: "center",
                            gap: "10px",
                          }}
                          onClick={(e) =>
                            handleHorLicense(
                              cb?.id,
                              cluster?.subcatid,
                              cb?.selected,
                              e,
                              i,
                              "leftFilter"
                            )
                          }
                        >
                          <span>{cb?.brandname.toUpperCase()}</span>
                          {cb?.selected && <DoneIcon />}
                        </li>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }, [filterInfo, clusID, brandTypeValue]);

  //Price filter
  const handlePriceFilter = (e) => {
    const { value } = e.target;
    setPriceFilter(value);
  };

  // For Pagination
  useEffect(() => {
    if (
      productListingRes &&
      tempPageCount !== undefined &&
      tempPageCount !== 1 &&
      itemOffset == 1
    ) {
      return setData((prevState) => [...prevState, ...productListingRes]);
    }
  }, [tempPageCount]);

  useEffect(() => {
    // if (brandTypeValue?.length == 0 && productTypeValue?.length == 0) return;
    if (productFilterRes) {
      setData(productFilterRes);
    } else if (productSortRes) {
      setData(productSortRes);
    } else {
      setData(productListingRes);
    }
    setTempPageCount();
  }, [productListingRes, productFilterRes, productSortRes]);

  useEffect(() => {
    if (contentTitle?.ParentName?.slug_name == page_title) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [page_title, contentTitle]);

  useEffect(() => {
    setBrandTypeValue([]);
    setProductTypeValue([]);
    setCategoryDropdown([]);
    setSelectedValue([]);
    setExpanded(false);
  }, [page_title]);

  //Filters API

  useEffect(() => {
    // debugger;
    if (
      sortCluster === "LICENSES" ||
      priceFilter?.length > 0 ||
      brandTypeValue?.length > 0 ||
      productTypeValue?.length > 0 ||
      colorFilter?.length > 0 ||
      categoryDropdown?.length > 0 ||
      Object.keys(selectedValue)?.length > 0 ||
      Object.keys(subCatName)?.length > 0 ||
      Object.keys(brandSelectedValue)?.length > 0
    ) {
      dispatch(
        productFiltersAction(
          page_title,
          title,
          subCatName,
          langSwitcRes,
          res,
          brandTypeValue,
          productTypeValue,
          categoryDropdown,
          colorFilter,
          selectedValue,
          priceFilter,
          itemOffset,
          brandSelectedValue,
          tempPageCount
        )
      );
    } else {
      setData(productListingRes);
    }
  }, [
    page_title,
    title,
    subCatName,
    langSwitcRes,
    res,
    brandTypeValue,
    productTypeValue,
    categoryDropdown,
    colorFilter,
    selectedValue,
    priceFilter,
    itemOffset,
    brandSelectedValue,
  ]);

  //pagination

  useEffect(() => {
    setItemOffset(1);
  }, [
    selectedValue,
    productTypeValue,
    brandTypeValue,
    priceFilter,
    colorFilter,
  ]);

  //ProductType

  const filteredProducts = data?.map((e) => e?.product_type);
  const filtered = data
    ?.filter(
      ({ product_type }, index) =>
        !filteredProducts?.includes(product_type, index + 1)
    )
    ?.sort((a, b) => a?.product_type.localeCompare(b?.product_type));

  const getCount = (value) => {
    let keydata = Object.keys(brandCount);
    let valuedata = Object.values(brandCount);
    return keydata?.map((kd, index) => {
      if (value.slug_name === kd) {
        return valuedata[index];
      }
    });
  };

  //Product count
  const productCount = contentTitle?.product_count;

  return (
    <>
      {loading ? (
        <>
          <center className="page-body-container">
            <h4>Please Wait...</h4>

            <PropagateLoader color={"#0da5e8 "} loading={loading} size={20} />
          </center>
        </>
      ) : (
        <>
          <HelmetProvider>
            <div className="page-body-container">
              <Helmet>
                <title>{contentTitle?.Product_listing_block_metaTitle}</title>
                <meta
                  name="description"
                  content={contentTitle?.Product_listing_block_metaDesc}
                />
                <meta
                  name="keyword"
                  content={contentTitle?.Product_listing_block_metaKey}
                />
              </Helmet>
              <BasicBreadcrumbs />
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                    <div className="filter-accordion-cont">
                      {/* Page Title Above Left Side Filter */}
                      {catagoryBlockRes &&
                      brandTypeData?.length === 0 &&
                      productTypeData?.length === 0 ? (
                        <Accordion disabled>
                          <AccordionSummary
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                          >
                            <Typography>
                              {catagoryBlockRes?.page_title}
                            </Typography>
                          </AccordionSummary>
                        </Accordion>
                      ) : (
                        ""
                      )}

                      {/* Left Side Filter */}
                      {filterInfo?.map((FI, i) => {
                        return (
                          <Accordion
                            expanded={expanded === `panel${i}`}
                            onChange={handleChange(`panel${i}`)}
                          >
                            <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls={FI?.subcatid}
                              id={FI?.subcatid}
                            >
                              <Typography>{FI?.subcatname}</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                              {FI?.brandtype?.map((FIBT) => {
                                return Object.keys(brandCount)?.length > 0 &&
                                  Object.keys(brandCount)
                                    ?.map((key) => key)
                                    ?.includes(FIBT?.slug_name) ? (
                                  <Typography
                                    onClick={(e) =>
                                      handleHorLicense(
                                        FIBT?.id,
                                        FI?.subcatid,
                                        FI?.selected,
                                        e
                                      )
                                    }
                                  >
                                    <input
                                      value={FIBT?.brandname}
                                      type="checkbox"
                                      defaultChecked={FIBT?.selected}
                                    />
                                    <span style={{ marginLeft: "10px" }}>
                                      {FIBT?.brandname}
                                    </span>
                                    <span style={{ marginLeft: "10px" }}>
                                      ({getCount(FIBT)})
                                    </span>
                                  </Typography>
                                ) : (
                                  ""
                                );
                              })}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}

                      {/* Product type */}
                      {/* Read Only Title */}
                      <Accordion disabled className="mt-4">
                        <AccordionSummary
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography>
                            <span className="disabledlabel">PRODUCT TYPES</span>
                          </Typography>
                        </AccordionSummary>
                      </Accordion>

                      {/* Dropdown - Product Type */}
                      <Accordion
                        expanded={expanded === "panel100"}
                        onChange={handleChange("panel100")}
                      >
                        <AccordionSummary
                          expandIcon={<CustomExpandIcon />}
                          aria-controls="panel5bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className="faqheading">
                            Products
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography onChange={handleLicense}>
                            {contentTitle?.product_unique?.map((e, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {Object.keys(productCount)?.length > 0 &&
                                  Object.keys(productCount)
                                    ?.map((key) => key)
                                    ?.includes(e?.productslugname) ? (
                                    <>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={e?.productslugname}
                                          name="productType"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={e?.productname}
                                        >
                                          {/* product name */}
                                          {Object.keys(productCount)?.length >
                                            0 &&
                                          Object.keys(productCount)
                                            ?.map((key) => key)
                                            ?.includes(e?.productslugname)
                                            ? e?.productname
                                            : ""}
                                          &nbsp;
                                          {/* product count */}
                                          {Object.keys(productCount)?.length >
                                            0 &&
                                          Object.keys(productCount)
                                            ?.map((key) => key)
                                            ?.includes(e?.productslugname) ? (
                                            <>
                                              (
                                              {
                                                contentTitle?.product_count[
                                                  e?.productslugname
                                                ]
                                              }
                                              )
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      {/* color */}
                      {/* Color Title */}
                      <Accordion disabled className="mt-4">
                        <AccordionSummary
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography>COLOR</Typography>
                        </AccordionSummary>
                      </Accordion>

                      {/* DropDown - Color */}
                      <Accordion
                        expanded={expanded === "panel20"}
                        onChange={handleChange("panel20")}
                      >
                        <AccordionSummary
                          expandIcon={<CustomExpandIcon />}
                          aria-controls="panel6bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className="faqheading">Color</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography onChange={handleLicense}>
                            <div className="colorpalette-cont">
                              <ul className="colorpalette-colors">
                                {contentTitle?.Color_block?.map((e, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <li>
                                        <input
                                          type="checkbox"
                                          value={e?.color}
                                          id={`cb${i}`}
                                          name="color"
                                        />
                                        <label for={`cb${i}`}>
                                          <img
                                            alt={e?.color}
                                            title={e?.color}
                                            src={`${baseUrlColor}/${e?.color_image}`}
                                          />
                                        </label>
                                      </li>
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                    {/* Title Above Product Banner */}
                    {catagoryBlockRes &&
                    brandTypeData?.length === 0 &&
                    productTypeData?.length === 0 ? (
                      <div className="product-main-title">
                        {catagoryBlockRes?.page_title}
                      </div>
                    ) : brandTypeData?.length > 0 &&
                      productTypeData?.length === 0 ? (
                      brandTypeData?.map((e, i) => {
                        return (
                          <div className="product-main-title">
                            {e?.productname}
                          </div>
                        );
                      })
                    ) : productTypeData?.length > 0 &&
                      brandTypeData?.length === 0 ? (
                      productTypeData?.map((e, i) => {
                        return (
                          <div className="product-main-title">
                            {e?.brandname}
                          </div>
                        );
                      })
                    ) : (
                      ""
                    )}

                    {/* Prodyct Banner */}
                    <ProductBanner />

                    {/* Page Title */}
                    <div className="row">
                      {langSwitcRes && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: contentTitle?.Product_listing_block_content,
                          }}
                        ></p>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="row g-3 align-items-center sortby">
                          <div className="col-auto">
                            <label className="col-form-label">Filter By</label>
                          </div>
                          {/* Horizontal DropDown */}
                          <div className="col-auto">
                            {productTypeHorizontal}
                          </div>

                          {/* Product Type Dropdown */}
                          <div className="col-auto d-flex ">
                            {contentTitle?.Sub_Category_Details?.length === 0 &&
                            contentTitle?.product_type_list?.length === 0 ? (
                              <></>
                            ) : (
                              <>
                                <form className="d-flex">
                                  <select
                                    className="form-select form-select-lg"
                                    aria-label=".form-select-lg example"
                                    name="productType"
                                    onChange={handleLicenses}
                                  >
                                    <option
                                      selected={resetProduct === ""}
                                      value=""
                                    >
                                      PRODUCT TYPE
                                    </option>

                                    {filtered?.map((e, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <option
                                            selected={
                                              e?.product_type_slug ===
                                              productSelect
                                            }
                                            value={e?.product_type_slug}
                                          >
                                            {e?.product_type?.toUpperCase()}
                                          </option>
                                        </React.Fragment>
                                      );
                                    })}
                                  </select>

                                  <input
                                    type="reset"
                                    onClick={handleLicenses}
                                    className="mx-2 btn btn-dark text-light"
                                    name="productTypeDefault"
                                    value="X"
                                  />
                                </form>
                              </>
                            )}
                          </div>

                          {/* Sort By - Dropdown */}
                          <div className="col-auto d-flex">
                            <select
                              className="form-select form-select-lg"
                              aria-label=".form-select-lg example"
                              onChange={handlePriceFilter}
                            >
                              <option selected value="ztoa">
                                SORT BY BRANDS (Z - A)
                              </option>
                              <option value="atoz">
                                SORT BY BRANDS (A - Z)
                              </option>
                              <option value="low-high">
                                PRICE-LOW TO HIGH
                              </option>
                              <option value="high-low">
                                PRICE-HIGH TO LOW
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Product Count */}
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2">
                        <div className="total-product">
                          <div className="col-auto">
                            <label className="col-form-label">
                              Total Items:{" "}
                              {(productFilterRes &&
                                brandTypeValue?.length > 0) ||
                              productTypeValue?.length > 0 ||
                              colorFilter?.length > 0 ||
                              Object.keys(selectedValue)?.length > 0 ||
                              Object.keys(subCatName)?.length > 0 ? (
                                <span id="total-count">
                                  {totalFiltersCount}
                                </span>
                              ) : contentTitle && !productSortRes ? (
                                <span id="total-count">
                                  {contentTitle?.Total_Product_Count}
                                </span>
                              ) : (
                                <>
                                  {" "}
                                  <span id="total-count">{totalSortCount}</span>
                                </>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                        <div className="row g-3 align-items-center float-end">
                          <div className="col-auto">
                            <div className="view-mode float-end">
                              {showGrid ? (
                                <>
                                  <Link to="#">
                                    <span className="grid active" title="Grid">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-grid"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                      </svg>
                                    </span>
                                  </Link>
                                  <Link to="#" onClick={showListHandler}>
                                    <span className="list" title="List">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-list-ul"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />
                                      </svg>
                                    </span>
                                  </Link>
                                </>
                              ) : (
                                <>
                                  <Link to="#" onClick={showGridHandler}>
                                    <span className="grid" title="Grid">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-grid"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                      </svg>
                                    </span>
                                  </Link>
                                  <Link to="#">
                                    <span className="list active" title="List">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-list-ul"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                        />
                                      </svg>
                                    </span>
                                  </Link>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Product Grid and List View */}
                    <div className="row">
                      {showGrid ? (
                        <ProductGrid
                          data={data}
                          loader={secLoader}
                          setSecLoader={setSecLoader}
                          contentTitle={contentTitle}
                          setItemOffset={setItemOffset}
                          itemOffset={itemOffset}
                          setApiStatus={setApiStatus}
                          apiStatus={apiStatus}
                        />
                      ) : (
                        <ProductList
                          data={data}
                          loader={secLoader}
                          setSecLoader={setSecLoader}
                          contentTitle={contentTitle}
                          setItemOffset={setItemOffset}
                          itemOffset={itemOffset}
                          setApiStatus={setApiStatus}
                          apiStatus={apiStatus}
                        />
                      )}
                    </div>

                    {/* <p
                      dangerouslySetInnerHTML={{
                        __html:
                          contentTitle?.product_listing_block_page_description_footer,
                      }}
                    ></p> */}
                  </div>
                </div>
              </div>
            </div>
          </HelmetProvider>
        </>
      )}
    </>
  );
};
