import "./App.css";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Suspense } from "react";

//Homepage

import { HomepageBeforeLogin } from "./Components/Pages/Homepage/HomepageBeforeLogin";

//Registration

import { Register } from "./Components/Pages/Registration/Register/Register";
import { IndividualRegister } from "./Components/Pages/Registration/Individual Register/IndividualRegister";
import { ShopRegister } from "./Components/Pages/Registration/Shop Register/ShopRegister";
import { WholeRegister } from "./Components/Pages/Registration/Wholesale Register/WholeRegister";
import { ResetPassword } from "./Components/Pages/Registration/Register/ResetPassword";

// Category page

import { CategoryPage } from "./Components/Pages/Category Pages/CategoryPage";

//Brand page

import { BrandPages } from "./Components/Pages/Brand Pages/BrandPages";

//CMS Pages

import { AboutUs } from "./Components/CMS Pages/About us/AboutUs";
import { LegalWarning } from "./Components/CMS Pages/Legal Warning/LegalWarning";
import { ShippingReturns } from "./Components/CMS Pages/Shipping Returns/ShippingReturns";
import { SecurePayment } from "./Components/CMS Pages/Secure payment/SecurePayment";
import { Cookies } from "./Components/CMS Pages/Cookies/Cookies";
import { ContactUs } from "./Components/CMS Pages/Contact us/ContactUs";

//PLP Page

import { ProductsPage } from "./Components/Pages/PLP & PDP Pages/PLP Page/ProductsPage";

//PDP Page

import { ProductDetails } from "./Components/Pages/PLP & PDP Pages/PDP Page/ProductDetails";

//Search

import { Search } from "./Components/Pages/Search/Search";

//My accounts

import { EditAddress } from "./Components/Pages/My Accounts/EditAddress";

//payment success

import { Success } from "./Components/Pages/PaymentSuccess/Success";

//My account

import { MyAccounts } from "./Components/Pages/My Accounts/MyAccounts";

//Stepper

import { StepProcess } from "./Components/Pages/Checkout/Step 1 Checkout/StepProcess";
import { EmptyCart } from "./Components/Pages/Checkout/EmptyCart/EmptyCart";
import { PageNotFound } from "./Components/CMS Pages/PageNotFound/PageNotFound";

function App() {
  //Language Switcher

  const langSwitcRes = useSelector((state) => state?.langSwitchData);

  const navigate = useNavigate();

  //Login Response

  const res = useSelector((state) => state?.loginData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      localStorage?.clear();
      navigate(`/es`);
    }, 3600000);
  }, []);

  return (
    <>
      <Suspense fallback={null}>
        <Routes>
          {/* Homepage */}

          {res?.status === 200 ? (
            <>
              <Route
                path="/"
                element={<Navigate to={`/${res?.data?.lang}`} />}
              />
            </>
          ) : (
            <>
              <Route path="/" element={<Navigate to="/es" />} />
            </>
          )}

          <Route
            path="/:langSwitcRes"
            element={<HomepageBeforeLogin />}
            exact
          />

          {/* Search page */}

          <Route
            path={`/:langSwitcRes/search/value=:srhInputValue`}
            element={<Search />}
            exact
          />

          {/* CMS Pages */}

          <Route path={`/:langSwitcRes/aboutus`} element={<AboutUs />} exact />
          <Route
            path={`/:langSwitcRes/legal-notice`}
            element={<LegalWarning />}
            exact
          />
          <Route
            path={`/:langSwitcRes/shipping-returns`}
            element={<ShippingReturns />}
            exact
          />
          <Route
            path={`/:langSwitcRes/secure-payment`}
            element={<SecurePayment />}
            exact
          />
          <Route
            path={`/:langSwitcRes/contactus`}
            element={<ContactUs />}
            exact
          />

          <Route path={`/:langSwitcRes/cookies`} element={<Cookies />} exact />

          {/* Registration pages */}

          <Route path={`/:langSwitcRes/login`} element={<Register />} exact />

          <Route
            path={`/:langSwitcRes/individualregister`}
            element={<IndividualRegister />}
            exact
          />
          <Route
            path={`/:langSwitcRes/shopregister`}
            element={<ShopRegister />}
            exact
          />
          <Route
            path={`/:langSwitcRes/wholeregister`}
            element={<WholeRegister />}
            exact
          />

          {/* Reset password */}

          <Route
            path={`/:langSwitcRes/resetpassword`}
            element={<ResetPassword />}
            exact
          />

          {/* Category */}

          <Route
            path={`/:langSwitcRes/category/:slugName`}
            element={<CategoryPage />}
            exact
          />

          {/* Brand page */}

          <Route
            path={`/:langSwitcRes/brand/:slugData`}
            element={<BrandPages />}
            exact
          />

          {/* PDP Page */}

          <Route
            path={`/:langSwitcRes/ProductDetails/:slug`}
            element={<ProductDetails />}
            exact
          />

          {/* PLP Pages */}

          <Route
            path={`/:langSwitcRes/:page_title`}
            element={<ProductsPage />}
            exact
          />

          <Route
            path={`/:langSwitcRes/:page_title/:title`}
            element={<ProductsPage />}
            exact
          />

          {/* My Account */}

          {res?.status === 200 ? (
            <>
              <Route
                path={`/:langSwitcRes/myaccount`}
                element={<MyAccounts />}
                exact
              />
            </>
          ) : (
            <>
              <Route
                path="/:langSwitcRes/myaccount"
                element={<Navigate to={`/${langSwitcRes}/login`} />}
                exact
              />
            </>
          )}

          {/* Edit address */}

          {res?.status === 200 ? (
            <>
              <Route
                path={`/:langSwitcRes/editaddress`}
                element={<EditAddress />}
                exact
              />
            </>
          ) : (
            <>
              <Route
                path="/editaddress"
                element={<Navigate to="/en" />}
                exact
              />
            </>
          )}

          {/* Stepper */}

          <Route
            path={`/:langSwitcRes/checkout`}
            element={<StepProcess />}
            exact
          />

          {/* Success page */}

          <Route path={`/:langSwitcRes/thankyou`} element={<Success />} exact />

          {/* Empty Cart page */}

          <Route
            path={`/:langSwitcRes/emptycart`}
            element={<EmptyCart loginRes={res} />}
            exact
          />

          {/* page not found */}

          <Route
            path={`/:langSwitcRes/search`}
            element={<PageNotFound loginRes={res} />}
            exact
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
