import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { pageNotFoundAction } from "../../../Redux/Action";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { Footer } from "../../Common/Footer/Footer";
import { Header } from "../../Common/Header/Header";

export const PageNotFound = ({ loginRes }) => {
  const dispatch = useDispatch();

  //LangSwitcher

  const { langSwitcRes } = useParams();

  useEffect(() => {
    dispatch(pageNotFoundAction(langSwitcRes));
  }, [langSwitcRes]);

  const pageNotFoundRes = useSelector((state) => state?.pageNotFoundData);

  return (
    <>
      <Header pathData="search" />
      {pageNotFoundRes?.pagenotfound?.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <p dangerouslySetInnerHTML={{ __html: e?.content }}></p>;
          </React.Fragment>
        );
      })}
      <Footer />
      {loginRes?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
