import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { baseUrls } from "../../../../config";
import "../../../../index.css";

export const BrandRegister = () => {
  //Language Switcher

  const { langSwitcRes, slugData } = useParams();

  const categoryRegisterRes = useSelector(
    (state) => state?.brandPageData?.b2b_identifier_block
  );

  const brandBanner1 = useSelector((state) => state?.brandPageData?.banner_1);

  const eventBrand = useSelector(
    (state) => state?.brandPageData?.event_block_1
  );

  const eventBannerBrand = useSelector(
    (state) => state?.brandPageData?.event_block_2
  );

  return (
    <>
      <div className="licensedproducts-cont">
        <div className="container">
          {eventBrand
            ?.filter((e) => e?.language === langSwitcRes)
            ?.map((e, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="section-heading text-uppercase">
                    {e?.tittle}
                  </div>
                  <p className="section-paragraph">
                    <span
                      dangerouslySetInnerHTML={{ __html: e?.content }}
                    ></span>
                  </p>
                </React.Fragment>
              );
            })}

          <div className="row align-items-start mt-4">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 col-sm-12 p-0 mr-0">
                  <div className="graybg-sm">
                    {eventBannerBrand?.map((e, i) => {
                      return (
                        <React.Fragment key={i}>
                          <img
                            src={`${baseUrls}/${e?.image}`}
                            alt={e?.tittle}
                          />
                          <div className="text-white p-3 lptcont">
                            <h2>{e?.tittle}</h2>
                            <p className="lptbg">
                              <Link
                                to={`/${langSwitcRes}/${slugData}`}
                                title={e?.content}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: e?.content,
                                  }}
                                ></span>
                              </Link>
                            </p>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row align-items-start mt-4 eh-cont"
            style={{ columnCount: "2" }}
          >
            <div className="col-xs-12 col-sm-12 col-md-8  p-0 mr-2 eh-col">
              <div className="banner-vh-center-text-bg cheight img-container">
                {brandBanner1?.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <img
                        src={`${baseUrls}/${e?.image}`}
                        alt="ProDG Freestyle"
                      />
                      <div className="center">
                        <div className="hs-cont">
                          <h2>{e?.tittle}</h2>
                          <p>LOREM IPSUM</p>
                        </div>
                        <Link
                          to={`/${langSwitcRes}/${slugData}`}
                          className="text-decoration-none text-light"
                        >
                          <div className="anchorbg">
                            Discover our products more....
                          </div>
                        </Link>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 p-0 m-0 eh-col">
              {langSwitcRes &&
                categoryRegisterRes
                  ?.filter((e) => e?.language === langSwitcRes)
                  ?.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div className="dos-cont mt-4">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.content,
                            }}
                          ></p>
                        </div>
                      </React.Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
