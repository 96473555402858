import React, { useEffect } from "react";
import { Footer } from "../../Common/Footer/Footer";
import { BatmanPin } from "../../Common/Footer/BatmanPin";
import { CategoryBanner } from "./Category Components/CategoryBanner";
import { CategoryLicense } from "./Category Components/CategoryLicense";
import { CategoryRegister } from "./Category Components/CategoryRegister";
import { CategoryFullWidth1 } from "./Category Components/CategoryFullWidth1";
import { CategoryProducts } from "./Category Components/CategoryProducts";
import { CategoryFullWidth2 } from "./Category Components/CategoryFullWidth2";
import { CategoryLists } from "./Category Components/CategoryLists";
import { Conditions2 } from "./Category Components/Conditions2";
import { CategoryFullWidth3 } from "./Category Components/CategoryFullWidth3";
import { CategoryConditions } from "./Category Components/CategoryConditions";
import { CategoryContact } from "./Category Components/CategoryContact";
import { CategoryFAQ } from "./Category Components/CategoryFAQ";
import { BrandSupplier } from "./Category Components/BrandSupplier";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Header } from "../../Common/Header/Header";

export const CategoryPage = () => {
  //Login Response

  const res = useSelector((state) => state?.loginData);

  const { slugName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //category Product Response

  const categoryPageRes = useSelector(
    (state) => state?.categoryData?.best_selling_product_block
  );

  return (
    <>
      <Header pathData={`category/${slugName}`} />
      <CategoryBanner />
      <CategoryLicense />
      <CategoryRegister />
      <CategoryFullWidth1 />

      {categoryPageRes?.length > 0 ? (
        <>
          <CategoryProducts />
        </>
      ) : (
        <></>
      )}

      <CategoryFullWidth2 />
      <CategoryLists />
      <Conditions2 />
      <CategoryFullWidth3 />
      <CategoryConditions />
      <CategoryContact />
      <CategoryFAQ />
      <BrandSupplier />
      <Footer />
      {res?.status === 200 ? "" : <BatmanPin />}
    </>
  );
};
