import React from "react";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";
import { IoBagHandleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrlProduct } from "../../../config";
import { removeApiAction } from "../../../Redux/Action";

export const MiniCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  //LangSwitcher

  const { langSwitcRes } = useParams();

  //Add to cart Response

  const addToCartRes = useSelector((state) => state?.cart);

  const totalPrice = Object?.keys(addToCartRes)?.reduce(
    (acc, item) => acc + addToCartRes[item]?.qty * addToCartRes[item]?.price,
    0
  );

  const totalQuantity = Object?.keys(addToCartRes)?.reduce(
    (acc, item) => +acc + +addToCartRes[item]?.qty,
    0
  );

  return (
    <>
      <li className="cartContainers">
        {/* Cart */}
        <Link
          to={`/${langSwitcRes}/checkout`}
          onClick={() => navigate(`/${langSwitcRes}/checkout`)}
          className="d-flex cartcontainer align-items-center justify-content-center"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          role="button"
        >
          <span class="pro-count blue">
            {Object.keys(addToCartRes).length === 0 ? (
              0
            ) : (
              <strong> {totalQuantity}</strong>
            )}
          </span>
          <IoBagHandleSharp />
        </Link>
        <ul class="icon-dropdown">
          <li>
            <div className="action-form user-sign-two">
              <div className="minicart-cont">
                <div className="minicart-header">
                  <div className="row">
                    <div className="col">
                      <strong>
                        {Object.keys(addToCartRes).length === 0 ? (
                          <strong>{t("items")} (0)</strong>
                        ) : (
                          <strong>
                            {t("items")} ({totalQuantity})
                          </strong>
                        )}
                      </strong>
                    </div>
                    {/* <div className="col text-end">
                      <Link to="">x</Link>
                    </div> */}
                  </div>
                </div>

                {Object.keys(addToCartRes).length === 0 && (
                  <>
                    <div
                      className="mini-cartempty text-center"
                      title={t("your_cart_is_empty")}
                    >
                      {t("your_cart_is_empty")}
                    </div>
                  </>
                )}
                <div className="minicart-body">
                  {Object.keys(addToCartRes)?.map((e, i) => {
                    const formatData = addToCartRes[e]?.productnames
                      ?.filter((e) => e?.lang == langSwitcRes)
                      ?.map((Pname, i) => {
                        return (
                          <React.Fragment key={i}>{Pname?.name}</React.Fragment>
                        );
                      });

                    return (
                      <React.Fragment key={i}>
                        <div className="cart-body">
                          <ul class="product-list">
                            <li>
                              <div class="thumb">
                                <Link
                                  to={`/${langSwitcRes}/ProductDetails/${addToCartRes[e]?.slug}`}
                                >
                                  <img
                                    src={`${baseUrlProduct}/${addToCartRes[e]?.image}`}
                                    alt={addToCartRes[e]?.brand}
                                  />
                                </Link>
                              </div>
                              <div class="content">
                                <div class="inner">
                                  <Link
                                    to={`/${langSwitcRes}/ProductDetails/${addToCartRes[e]?.slug}`}
                                  >
                                    {formatData}
                                  </Link>
                                  <div class="quatity">
                                    <span>Qty: {addToCartRes[e]?.qty}</span>
                                  </div>
                                </div>
                                <div className="price-delete">
                                  <div className="price">
                                    {langSwitcRes === "es" ? (
                                      <>
                                        {Number(
                                          addToCartRes[e]?.qty *
                                            addToCartRes[e]?.price
                                        ).toLocaleString("es-ES", {
                                          minimumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : langSwitcRes === "fr" ? (
                                      <>
                                        {Number(
                                          addToCartRes[e]?.qty *
                                            addToCartRes[e]?.price
                                        ).toLocaleString("fr-FR", {
                                          minimumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {Number(
                                          addToCartRes[e]?.qty *
                                            addToCartRes[e]?.price
                                        ).toFixed(2)}{" "}
                                      </>
                                    )}
                                    <span> €</span>
                                  </div>
                                  <br />
                                  <Link to="" className="text-black">
                                    <BiTrash
                                      size={24}
                                      onClick={() => {
                                        dispatch(
                                          removeApiAction(
                                            addToCartRes[e]?.cart_id,
                                            addToCartRes[e]?.product_sku
                                          )
                                        );
                                      }}
                                    />
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="cart-footer">
                  <div className="row minicart-footer">
                    <div className="col">{t("total")}:</div>
                    <div className="col text-end">
                      {Object.keys(addToCartRes).length === 0 ? (
                        <strong>
                          0 <span> €</span>
                        </strong>
                      ) : (
                        <strong>
                          {langSwitcRes === "es" ? (
                            <>
                              {Number(totalPrice).toLocaleString("es-ES", {
                                minimumFractionDigits: 2,
                              })}
                            </>
                          ) : langSwitcRes === "fr" ? (
                            <>
                              {Number(totalPrice).toLocaleString("fr-FR", {
                                minimumFractionDigits: 2,
                              })}
                            </>
                          ) : (
                            <>{Number(totalPrice).toFixed(2)}</>
                          )}
                          <span> €</span>
                        </strong>
                      )}
                    </div>
                  </div>
                  <Link
                    to={`/${langSwitcRes}/checkout`}
                    style={{ padding: "0px" }}
                  >
                    <button
                      className="blackbutton checkout w-100"
                      title={t("checkout")}
                    >
                      {t("checkout")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </>
  );
};
